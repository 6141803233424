//-------React/Redux
import React from 'react';

//-------Components
import { Icon } from 'semantic-ui-react';

export const AmountItem = (props) => {
  return (
    <div className="amount-item-container">
      <label className="amount-item-title">
        {props.currency} {props.label}
        <Icon
          name="remove circle"
          size="large"
          onClick={() => props.onDelete(props.index)}
        />
      </label>
    </div>
  );
};
