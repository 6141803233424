import React from "react";

import CurrencyFormatter from "../../components/common/grid/cells/currencyFormatter";

// IMAGES
import fundraising_icon from "../../images/coloured_mark_fundraising_icon.svg";
import pages_icon from "../../images/coloured_mark_total_pages_icon.svg";

export enum Flow {
  CLAIMED_AS_FINAL_STATUS = "claimedAsFinalStatus",
  ACCEPTED_AS_FINAL_STATUS = "acceptedAsFinalStatus",
}

export const eventAmbassadorStatus = {
  ACCEPTED: "accepted",
  CLAIMED: "claimed",
  RESCINDED: "rescinded",
  RECEIVED: "received",
  REJECTED: "rejected",
  WAITLISTED: "waitlisted",
};

export const defaultAmbassadorStatus  = {
  RECEIVED: eventAmbassadorStatus.RECEIVED,
  WAITLISTED: eventAmbassadorStatus.WAITLISTED,
}

export const pageCreationStatus  = {
  RECEIVED: "receivedStatus",
  WAITLISTED: "receivedAndWaitlistedStatus",
}

export const programStatuses = {
  APPLICATIONS_OPEN: {
    description: "Applications Open",
    value: 1
  },
  APPLICATIONS_CLOSED: {
    description: "Applications Closed",
    value: 2
  },
};

export const i18nKeys = {
  PROGRAMS_TAB_TITLE: "tabName",
  APPLICATIONS_TAB_TITLE: "tabName",
  CAMPAIGN_WAITLIST_APPLICATIONS_TAB_TITLE: "tabName",
  PENDING_APPROVAL_NIL_RAISED_TAB_TITLE: "tabName",
  PENDING_APPROVAL_TAB_TITLE: "tabName",
  VIP_PROGRAMS_TAB_TITLE: "tabName",
  EDIT_PROGRAM_EVENT_VIEW_BUTTON:
    "eventPrograms.button.edit-program-event-view",
  EDIT_PROGRAM_CAMPAIGN_VIEW_BUTTON:
    "eventPrograms.button.edit-program-campaign-view",
  VIEW_APPLICATION_FORM: "eventPrograms.button.view-application-form",
  ADD_ORGANIZATION_BUTTON: "eventPrograms.button.add-organization",
  ADD_VIP_PROGRAM_BUTTON: "programs.button.add-program",
  VIEW_LABEL: "eventPrograms.view.label"
};

export const gridTabs = {
  PROGRAMS: "programs",
  APPLICATIONS: "applications",
  PENDING_APPROVAL_PROGRAMS: "pendingApprovalPrograms",
  VIPPROGRAMS: "vips",
  EVENT_PROGRAMS: "eventPrograms",
  EVENT_APPLICATIONS: "eventApplications",
  EVENT_PENDING_APPROVAL_PROGRAMS: "eventPendingApprovalPrograms",
  EVENT_VIP_PROGRAMS: "eventVipPrograms",
  CAMPAIGN_PROGRAMS: "campaignPrograms",
  CAMPAIGN_APPLICATIONS: "campaignApplications",
  CAMPAIGN_WAITLIST_APPLICATIONS: "campaignWaitlistApplications",
  CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED: "campaignPendingProgramsNilRaised",
  CAMPAIGN_PENDING_PROGRAMS: "campaignPendingPrograms",
  CAMPAIGN_VIP_PROGRAMS: "campaignVipPrograms"
};

export const gridKeys = {
  PROGRAMS: "programs",
  APPLICATIONS: "applications",
  WAITLIST: "waitlist",
  PENDING_NO_FUNDS_RAISED: "pendingNoFundsRaised",
  PENDING_APPROVAL: "pendingApproval",
  VIPS: "vips"
};

export const colouredMarksColors = {
  GREEN: "#53b9a8",
  YELLOW: "#bdd002",
  PINK: "#cdafd0",
  BLUE: "#70cadb"
};

export const colouredMarksTranslations = {
  AVERAGE_FUNDRAISING_AMOUNT:
    "programs.coloured-marks.average-fundraising-amount",
  FUNDRAISING_PAGES: "programs.coloured-marks.fundraising-pages",
  TOTAL_AMOUNT_RAISED: "programs.coloured-marks.total-amount-raised",
  SUPERSTARS: "programs.coloured-marks.superstars",
  STANDARD: "programs.coloured-marks.standard"
};

export const eventAmbassadorStatusStyles = {
  [eventAmbassadorStatus.ACCEPTED]: {
    backgroundColor: "#56b68b"
  },
  [eventAmbassadorStatus.CLAIMED]: {
    backgroundColor: "#56b68b"
  },
  [eventAmbassadorStatus.REJECTED]: {
    backgroundColor: "#db3030"
  },
  [eventAmbassadorStatus.RECEIVED]: {
    backgroundColor: "#70cadb"
  },
  [eventAmbassadorStatus.RESCINDED]: {
    backgroundColor: "#afb0b3"
  },
  [eventAmbassadorStatus.WAITLISTED]: {
    backgroundColor: "#afb0b3"
  }
};

export const viewModes = {
  VIEW: "view",
  EDIT: "edit"
};

export const colouredMarks = [
  {
    color: colouredMarksColors.GREEN,
    title: colouredMarksTranslations.AVERAGE_FUNDRAISING_AMOUNT,
    subTitle: colouredMarksTranslations.SUPERSTARS,
    icon: fundraising_icon,
    marginBottom: false,
    getValue: (metrics: any) => {
      return metrics ? (
        <CurrencyFormatter
          value={metrics.averageAmbassadorFundraisedAmount}
          fixed={0}
          truncate
        />
      ) : (
        "-"
      );
    }
  },
  {
    color: colouredMarksColors.YELLOW,
    title: colouredMarksTranslations.AVERAGE_FUNDRAISING_AMOUNT,
    subTitle: colouredMarksTranslations.STANDARD,
    icon: fundraising_icon,
    marginBottom: false,
    getValue: (metrics: any) => {
      return metrics ? (
        <CurrencyFormatter
          value={metrics.averageFundraisedAmount}
          fixed={0}
          truncate
        />
      ) : (
        "-"
      );
    }
  },
  {
    color: colouredMarksColors.PINK,
    title: colouredMarksTranslations.FUNDRAISING_PAGES,
    subTitle: colouredMarksTranslations.SUPERSTARS,
    icon: pages_icon,
    marginBottom: true,
    getValue: (metrics: any) => {
      return metrics ? metrics.ambassadorPageCount : "-";
    }
  },
  {
    color: colouredMarksColors.BLUE,
    title: colouredMarksTranslations.TOTAL_AMOUNT_RAISED,
    subTitle: colouredMarksTranslations.SUPERSTARS,
    icon: pages_icon,
    marginBottom: true,
    getValue: (metrics: any) => {
      return metrics ? (
        <CurrencyFormatter
          value={metrics.ambassadorFundraisedAmount}
          fixed={0}
          truncate
        />
      ) : (
        "-"
      );
    }
  }
];

export const programsAvailableFiltersKeys = [
  "eventId",
  "isActive",
  "startsAt",
  "endsAt",
  "newEntriesEndAt"
];

export const applicationsAvailableFiltersKeys = ["createdAtLocal", "status"];
export const pendingApprovalProgramsAvailableFiltersKeys = ["createdAtLocal"];
export const vipProgramsAvailableFiltersKeys = ["createdAtLocal"];

export const eventProgramsAvailableFiltersKeys = ["campaignId"];
export const eventApplicationsAvailableFilterKeys = [
  "createdAtLocal",
  "status"
];
export const eventPendingApprovalProgramsAvailableFilterKeys = [
  "createdAtLocal"
];
export const eventVipProgramsAvailableFilterKeys = ["createdAtLocal"];

export const campaignProgramsAvailableFiltersKeys = ["campaignId"];
export const campaignApplicationsAvailableFilterKeys = [
  "createdAtLocal",
  "status"
];
export const campaignWaitlistApplicationsAvailableFilterKeys = [
  "createdAtLocal"
];
export const campaignPendingProgramsNilRaisedAvailableFilterKeys = [
  "createdAtLocal"
];
export const campaignPendingProgramsAvailableFilterKeys = [
  "createdAtLocal"
];
export const campaignVIPProgramsAvailableFilterKeys = ["createdAtLocal"];

export const archivedFilters = ["event.status", "campaign.status"];
