import get from 'lodash.get';

import {
  getCustomFieldsWithAnswers,
  getCustomFieldsWithReferenceAnswers,
  getQuestionsFromModel
} from '../../../helpers/formBuilderHelper';
import update from '../../../helpers/update';

import * as entityHandlers from '../../../modules/bin/entityHandlers';
import { Record } from '../../../modules/bin/utility';
import { CLEAR } from '../../../modules/general';

import { gridTabs } from './../constants';

import { gridKeys } from '../../../constants/gridKeys';
import {
  getApplicationsGrid,
  getCampaignApplicationsGrid,
  getCampaignWaitlistApplicationsGrid,
  getCampaignPendingProgramsNilRaisedGrid,
  getCampaignPendingProgramsGrid,
  getCampaignProgramsGrid,
  getCampaignVipProgramsGrid,
  getEventApplicationsGrid,
  getEventPendingApprovalProgramsGrid,
  getEventProgramsGrid,
  getEventVipProgramsGrid,
  getPendingApprovalProgramsGrid,
  getProgramsGrid,
  getVIPProgramGrid
} from './../gridConfiguration/index';

import { fieldCategoryKey } from '../../../constants/formBuilder';
import {
  ACTIVE_TAB_SET,
  CREATE_FAILURE,
  CREATE_REQUESTED,
  CREATE_SUCCESS,
  ERROR_CLEAR,
  GET_AUTOCOMPLETE_RECORD_FAILURE,
  GET_AUTOCOMPLETE_RECORD_REQUESTED,
  GET_AUTOCOMPLETE_RECORD_SUCCESS,
  GET_CAMPAIGN_AMBASSADOR_PROGRAM_FAILURE,
  GET_CAMPAIGN_AMBASSADOR_PROGRAM_REQUESTED,
  GET_CAMPAIGN_AMBASSADOR_PROGRAM_SUCCESS,
  GET_EVENT_AMBASSADOR_FAILURE,
  GET_EVENT_AMBASSADOR_PROGRAM_FAILURE,
  GET_EVENT_AMBASSADOR_PROGRAM_REQUESTED,
  GET_EVENT_AMBASSADOR_PROGRAM_SUCCESS,
  GET_EVENT_AMBASSADOR_REQUESTED,
  GET_EVENT_AMBASSADOR_SIGNATURE_FAILURE,
  GET_EVENT_AMBASSADOR_SIGNATURE_REQUESTED,
  GET_EVENT_AMBASSADOR_SIGNATURE_SUCCESS,
  GET_EVENT_AMBASSADOR_SUCCESS,
  GET_EVENT_ORGANIZER_FAILURE,
  GET_EVENT_ORGANIZER_REQUESTED,
  GET_EVENT_ORGANIZER_SUCCESS,
  GET_EXPORT_FILE_FAILURE,
  GET_EXPORT_FILE_REQUESTED,
  GET_EXPORT_FILE_SUCCESS,
  GET_TOTAL_COUNT_APPLICATIONS_FAILURE,
  GET_TOTAL_COUNT_APPLICATIONS_REQUESTED,
  GET_TOTAL_COUNT_APPLICATIONS_SUCCESS,
  GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_FAILURE,
  GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_REQUESTED,
  GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_SUCCESS,
  GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED_BY_RECEIVED_STATUS_FAILURE,
  GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED_BY_RECEIVED_STATUS_REQUESTED,
  GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED_BY_RECEIVED_STATUS_SUCCESS,
  GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_BY_RECEIVED_STATUS_FAILURE,
  GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_BY_RECEIVED_STATUS_REQUESTED,
  GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_BY_RECEIVED_STATUS_SUCCESS,
  GET_TOTAL_COUNT_PROGRAMS_FAILURE,
  GET_TOTAL_COUNT_PROGRAMS_REQUESTED,
  GET_TOTAL_COUNT_PROGRAMS_SUCCESS,
  GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_FAILURE,
  GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_REQUESTED,
  GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_SUCCESS,
  GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_FAILURE,
  GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_REQUESTED,
  GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_SUCCESS,
  HANDLE_VALUE_CHANGE,
  LIST_DATA_FAILURE,
  LIST_DATA_REQUESTED,
  LIST_DATA_SUCCESS,
  LIST_ORDERING_CHANGE,
  METADATA_FAILURE,
  METADATA_REQUESTED,
  METADATA_SUCCESS,
  RECORD_CLEAR,
  TOGGLE_COLUMNS_CHANGE,
  UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_FAILURE,
  UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_REQUESTED,
  UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUESTED,
  UPDATE_SUCCESS,
  TOGGLE_CHANGE_AVAILABILITY_MODAL,
  TOGGLE_CONFIRM_REGISTRATION_MODAL,
  GET_TOTAL_COUNT_CAMPAIGN_WAITLIST_APPLICATIONS_REQUESTED,
  GET_TOTAL_COUNT_CAMPAIGN_WAITLIST_APPLICATIONS_SUCCESS,
  GET_TOTAL_COUNT_CAMPAIGN_WAITLIST_APPLICATIONS_FAILURE,
} from '../store/vipProgramTypes';
import { paths } from './../application/constants/template';

const i18nProgramsListKey = 'programs.list.header';
const i18nPendingApprovalProgramsListKey =
  'pendingApprovalPrograms.list.header';
const i18nApplicationsListKey = 'applications.list.header';
const i18nVipProgramsListKey = 'vips.list.header';

const i18nEventProgramsListKey = 'eventPrograms.list.header';
const i18nEventApplicationsListKey = 'eventApplications.list.header';
const i18nEventPendingApprovalProgramsListKey =
  'eventPendingApprovalPrograms.list.header';
const i18nEventVipProgramsListKey = 'eventVipPrograms.list.header';

const i18nCampaignProgramsListKey = 'campaignPrograms.list.header';
const i18nCampaignApplicationsListKey = 'campaignApplications.list.header';
const i18nCampaignWaitlistApplicationsListKey =
  'campaignWaitlistApplications.list.header';
const i18nCampaignPendingProgramsNilRaisedListKey =
  'campaignPendingProgramsNilRaised.list.header';
const i18nCampaignPendingProgramsListKey =
  'campaignPendingPrograms.list.header';
const i18nCampaignVipProgramsListKey = 'campaignVipPrograms.list.header';

const initialState = {
  isResetStatusModalOpen: false,
  isPromoteToReceivedModalOpen: false,
  applicationConfirmationModal: {
    isAcceptModalOpen: false,
    isRejectModalOpen: false,
    disableAutomaticEmail: false
  },
  confirmRegistrationModal: {
    isModalOpen: false,
    itemId: null,
    refreshList: false,
  },
  changeAvailabilityModal: {
    isModalOpen: false,
    type: "OpenApplications",
    itemId: null,
  },
  isDigitalSignatureModalOpen: false,
  selectedView: gridTabs.PROGRAMS,
  timestamp: new Date().getTime(),
  exportFileRecord: Record.getDefaultState(),
  record: Record.getDefaultState(),
  campaignAmbassadorProgramRecord: Record.getDefaultState(),
  eventAmbassadorProgramRecord: Record.getDefaultState(),
  eventAmbassadorProgramRequestRecord: Record.getDefaultState(),
  editEventAmbassadorStatusRecord: Record.getDefaultState(),
  editApplicationRecord: Record.getDefaultState(),
  eventAmbassadorRecord: Record.getDefaultState(),
  eventOrganizerRecord: Record.getDefaultState(),
  recordCreate: Record.getDefaultState(),
  createProgramRecord: Record.getDefaultState(),
  editProgramRecord: Record.getDefaultState(),
  editProgramSeats: Record.getDefaultState(),
  recordEdit: Record.getDefaultState(),
  totalCountProgramsRecord: Record.getDefaultState(),
  totalCountApplicationsRecord: Record.getDefaultState(),
  totalCountCampaignWaitlistApplicationsRecord: Record.getDefaultState(),
  totalCountPendingApprovalProgramsByReceivedStatusRecord:
    Record.getDefaultState(),
  totalCountCampaignPendingProgramsNilRaisedByReceivedStatusRecord:
    Record.getDefaultState(),
  totalCountCampaignPendingProgramsByReceivedStatusRecord:
    Record.getDefaultState(),
  totalCountVIPProgramsByClaimedStatusRecord: Record.getDefaultState(),
  totalCountVIPProgramsByAcceptedStatusRecord: Record.getDefaultState(),
  secureSignatureRecord: Record.getDefaultState(),
  [gridKeys.PROGRAMS]: getProgramsGrid(i18nProgramsListKey),
  [gridKeys.APPLICATIONS]: getApplicationsGrid(i18nApplicationsListKey),
  [gridKeys.PENDING_APPROVAL_PROGRAMS]: getPendingApprovalProgramsGrid(
    i18nPendingApprovalProgramsListKey
  ),
  [gridKeys.VIPPROGRAM]: getVIPProgramGrid(i18nVipProgramsListKey),
  [gridKeys.EVENT_PROGRAMS]: getEventProgramsGrid(i18nEventProgramsListKey),
  [gridKeys.EVENT_APPLICATIONS]: getEventApplicationsGrid(
    i18nEventApplicationsListKey
  ),
  [gridKeys.EVENT_PENDING_APPROVAL_PROGRAMS]:
    getEventPendingApprovalProgramsGrid(
      i18nEventPendingApprovalProgramsListKey
    ),
  [gridKeys.EVENT_VIP_PROGRAMS]: getEventVipProgramsGrid(
    i18nEventVipProgramsListKey
  ),
  [gridKeys.CAMPAIGN_PROGRAMS]: getCampaignProgramsGrid(
    i18nCampaignProgramsListKey
  ),
  [gridKeys.CAMPAIGN_APPLICATIONS]: getCampaignApplicationsGrid(
    i18nCampaignApplicationsListKey
  ),
  [gridKeys.CAMPAIGN_WAITLIST_APPLICATIONS]:
    getCampaignWaitlistApplicationsGrid(
      i18nCampaignWaitlistApplicationsListKey
    ),
  [gridKeys.CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED]:
    getCampaignPendingProgramsNilRaisedGrid(
      i18nCampaignPendingProgramsNilRaisedListKey
    ),
  [gridKeys.CAMPAIGN_PENDING_PROGRAMS]:
    getCampaignPendingProgramsGrid(
      i18nCampaignPendingProgramsListKey
    ),
  [gridKeys.CAMPAIGN_VIP_PROGRAMS]: getCampaignVipProgramsGrid(
    i18nCampaignVipProgramsListKey
  )
};
// State Reducer
export default (
  state = initialState,
  action: {
    type: any;
    payload: { key?: any; listKey?: any; value: any; path?: any };
  }
) => {
  let newState = state;

  switch (action.type) {
    case TOGGLE_CONFIRM_REGISTRATION_MODAL:
      newState = update(newState, {
        confirmRegistrationModal: {
          isModalOpen: {
            $set: !state.confirmRegistrationModal.isModalOpen,
          },
          itemId: { $set: get(action.payload, "itemId") },
          refreshList: { $set: get(action.payload, "refreshList") },
        },
      });
    break;

    case TOGGLE_CHANGE_AVAILABILITY_MODAL:
      newState = update(newState, {
        changeAvailabilityModal: {
          isModalOpen: {
            $set: !state.changeAvailabilityModal.isModalOpen,
          },
          type: {
            $set: action.payload.key,
          },
          itemId: {
            $set: action.payload.value,
          },
        },
      });
    break;

    case CLEAR: {
      newState = initialState;
      break;
    }

    case RECORD_CLEAR: {
      newState = update.set(
        newState,
        action.payload.key,
        Record.getDefaultState()
      );
      break;
    }

    case METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case CREATE_REQUESTED:
    case UPDATE_REQUESTED:
    case UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_REQUESTED:
    case GET_AUTOCOMPLETE_RECORD_REQUESTED:
    case GET_EXPORT_FILE_REQUESTED:
    case GET_EVENT_ORGANIZER_REQUESTED:
    case GET_EVENT_AMBASSADOR_PROGRAM_REQUESTED:
    case GET_EVENT_AMBASSADOR_REQUESTED:
    case GET_CAMPAIGN_AMBASSADOR_PROGRAM_REQUESTED:
    case GET_TOTAL_COUNT_PROGRAMS_REQUESTED:
    case GET_TOTAL_COUNT_APPLICATIONS_REQUESTED:
    case GET_TOTAL_COUNT_CAMPAIGN_WAITLIST_APPLICATIONS_REQUESTED:
    case GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_REQUESTED:
    case GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED_BY_RECEIVED_STATUS_REQUESTED:
    case GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_BY_RECEIVED_STATUS_REQUESTED:
    case GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_REQUESTED:
    case GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_REQUESTED:
    case GET_EVENT_AMBASSADOR_SIGNATURE_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
    case GET_AUTOCOMPLETE_RECORD_SUCCESS:
    case GET_EXPORT_FILE_SUCCESS:
    case GET_EVENT_ORGANIZER_SUCCESS:
    case GET_EVENT_AMBASSADOR_PROGRAM_SUCCESS:
    case GET_EVENT_AMBASSADOR_SUCCESS:
    case GET_TOTAL_COUNT_PROGRAMS_SUCCESS:
    case GET_TOTAL_COUNT_APPLICATIONS_SUCCESS:
    case GET_TOTAL_COUNT_CAMPAIGN_WAITLIST_APPLICATIONS_SUCCESS:
    case GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_SUCCESS:
    case GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED_BY_RECEIVED_STATUS_SUCCESS:
    case GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_BY_RECEIVED_STATUS_SUCCESS:
    case GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_SUCCESS:
    case GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);

      const eventAmbassadorNewStatus = get(
        newState,
        'editEventAmbassadorStatusRecord.data.status'
      );

      newState = update(newState, {
        eventAmbassadorRecord: {
          data: {
            data: {
              status: {
                $set: eventAmbassadorNewStatus
              }
            }
          }
        },
        applicationConfirmationModal: {
          isAcceptModalOpen: { $set: false },
          isRejectModalOpen: { $set: false }
        },
        isResetStatusModalOpen: { $set: false },
        isPromoteToReceivedModalOpen: { $set: false },
      });

      break;

    case GET_CAMPAIGN_AMBASSADOR_PROGRAM_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);

      const eventAmbassador = get(newState, 'eventAmbassadorRecord.data.data');
      const campaignAmbassadorProgram = get(
        newState,
        'campaignAmbassadorProgramRecord.data.data'
      );
      const eventAmbassadorProgram = get(
        campaignAmbassadorProgram,
        'eventAmbassadorProgram'
      );

      const eventAmbassadorFields = get(eventAmbassador, 'fields');

      const phoneNumber = get(eventAmbassador, 'phoneNumber');
      const address = get(eventAmbassador, 'address');

      if (eventAmbassador && campaignAmbassadorProgram) {
        const questions = getQuestionsFromModel(
          campaignAmbassadorProgram,
          fieldCategoryKey.ADDITIONAL
        );
        const customFieldsWithAnswers = getCustomFieldsWithAnswers(
          questions,
          eventAmbassadorFields,
          phoneNumber,
          address
        );

        newState = update.set(
          newState,
          `eventAmbassadorRecord.data.data.${paths.APPLICATION_ADDITIONAL}`,
          customFieldsWithAnswers
        );
      }

      if (eventAmbassador && eventAmbassadorProgram) {
        const eventEntryQuestions = getQuestionsFromModel(
          eventAmbassadorProgram,
          fieldCategoryKey.ENTRY
        );
        const eventEntryCustomFieldsWithAnswers = getCustomFieldsWithAnswers(
          eventEntryQuestions,
          eventAmbassadorFields,
          phoneNumber,
          address
        );
        const eventEntryCustomFieldsWithReferenceAnswers =
          getCustomFieldsWithReferenceAnswers(
            eventEntryCustomFieldsWithAnswers,
            eventAmbassador
          );

        const eventConfirmationQuestions = getQuestionsFromModel(
          eventAmbassadorProgram,
          fieldCategoryKey.CONFIRMATION
        );
        const eventConfirmationCustomFieldsWithAnswers =
          getCustomFieldsWithAnswers(
            eventConfirmationQuestions,
            eventAmbassadorFields,
            phoneNumber,
            address
          );

        newState = update.set(
          newState,
          `eventAmbassadorRecord.data.data.${paths.APPLICATION_ENTRY}`,
          eventEntryCustomFieldsWithReferenceAnswers
        );
        newState = update.set(
          newState,
          `eventAmbassadorRecord.data.data.${paths.APPLICATION_CONFIRMATION}`,
          eventConfirmationCustomFieldsWithAnswers
        );
      }
      break;

    case UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update(newState, {
        applicationConfirmationModal: {
          isAcceptModalOpen: { $set: false },
          isRejectModalOpen: { $set: false }
        },
        isResetStatusModalOpen: { $set: false },
      });

      break;

    case CREATE_FAILURE:
    case UPDATE_FAILURE:
    case GET_AUTOCOMPLETE_RECORD_FAILURE:
    case GET_EXPORT_FILE_FAILURE:
    case GET_EVENT_ORGANIZER_FAILURE:
    case GET_EVENT_AMBASSADOR_PROGRAM_FAILURE:
    case GET_EVENT_AMBASSADOR_FAILURE:
    case GET_CAMPAIGN_AMBASSADOR_PROGRAM_FAILURE:
    case GET_TOTAL_COUNT_PROGRAMS_FAILURE:
    case GET_TOTAL_COUNT_APPLICATIONS_FAILURE:
    case GET_TOTAL_COUNT_CAMPAIGN_WAITLIST_APPLICATIONS_FAILURE:
    case GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_FAILURE:
    case GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED_BY_RECEIVED_STATUS_FAILURE:
    case GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_BY_RECEIVED_STATUS_FAILURE:
    case GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_FAILURE:
    case GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_FAILURE:
    case GET_EVENT_AMBASSADOR_SIGNATURE_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case GET_EVENT_AMBASSADOR_SIGNATURE_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;
    case TOGGLE_COLUMNS_CHANGE:
      newState = entityHandlers.toggleColumnChangeHandler(state, action);
      break;

    case LIST_ORDERING_CHANGE:
      newState = update.set(
        newState,
        `${action.payload.listKey}.request.orderBy`,
        action.payload.value
      );
      break;

    case ACTIVE_TAB_SET:
      newState = update(newState, { selectedView: { $set: action.payload } });
      break;

    case HANDLE_VALUE_CHANGE:
      const { path, value } = action.payload;
      newState = update.set(newState, path, value);
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    default:
      return state;
  }

  return newState;
};
