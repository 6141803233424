import React from "react";
import { I18n } from "react-redux-i18n";
import { Modal, Button } from "semantic-ui-react";

import { Record } from "../../../../modules/bin/utility";

const ResetStatusModal = (props) => {
  const {
    open,
    handleConfirm,
    handleClose,
    translationKey,
    record,
  } = props;

  const isLoading = Record.isRecordLoading(record);

  return (
    <Modal
      className="application-confirmation-modal"
      size="tiny"
      dimmer="inverted"
      open={open}
    >
      <Modal.Header>
        <label>{I18n.t(`${translationKey}.title`)}</label>
      </Modal.Header>
      <Modal.Content className="application-confirmation-modal-content">
        <span>{I18n.t(`${translationKey}.content`)}</span>
        <div className="application-confirmation-modal-buttons">
          <Button
            primary
            className="accept-button"
            onClick={handleConfirm}
            loading={isLoading}
          >
            {I18n.t(`${translationKey}.accept-button`)}
          </Button>
          <Button onClick={handleClose}>
            {I18n.t(`${translationKey}.decline-button`)}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ResetStatusModal;
