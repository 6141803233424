export const TOGGLE_COLUMNS_CHANGE = 'vipProgram/TOGGLE_COLUMNS_CHANGE';
export const LIST_ORDERING_CHANGE = 'vipProgram/LIST_ORDERING_CHANGE';
export const HANDLE_VALUE_CHANGE = 'vipProgram/HANDLE_VALUE_CHANGE';

export const CREATE_REQUESTED = 'vipProgram/CREATE_REQUESTED';
export const CREATE_SUCCESS = 'vipProgram/CREATE_SUCCESS';
export const CREATE_FAILURE = 'vipProgram/CREATE_FAILURE';

export const METADATA_REQUESTED = 'vipProgram/METADATA_REQUESTED';
export const METADATA_SUCCESS = 'vipProgram/METADATA_SUCCESS';
export const METADATA_FAILURE = 'vipProgram/METADATA_FAILURE';

export const GET_EVENT_ORGANIZER_REQUESTED =
  'vipProgram/GET_EVENT_ORGANIZER_REQUESTED';
export const GET_EVENT_ORGANIZER_SUCCESS =
  'vipProgram/GET_EVENT_ORGANIZER_SUCCESS';
export const GET_EVENT_ORGANIZER_FAILURE =
  'vipProgram/GET_EVENT_ORGANIZER_FAILURE';

export const UPDATE_REQUESTED = 'vipProgram/UPDATE_REQUESTED';
export const UPDATE_SUCCESS = 'vipProgram/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'vipProgram/UPDATE_FAILURE';

export const UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_REQUESTED =
  'vipProgram/UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_REQUESTED';
export const UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_SUCCESS =
  'vipProgram/UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_SUCCESS';
export const UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_FAILURE =
  'vipProgram/UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_FAILURE';

export const GET_EVENT_AMBASSADOR_SIGNATURE_REQUESTED =
  'vipProgram/GET_EVENT_AMBASSADOR_SIGNATURE_REQUESTED';
export const GET_EVENT_AMBASSADOR_SIGNATURE_SUCCESS =
  'vipProgram/GET_EVENT_AMBASSADOR_SIGNATURE_SUCCESS';
export const GET_EVENT_AMBASSADOR_SIGNATURE_FAILURE =
  'vipProgram/GET_EVENT_AMBASSADOR_SIGNATURE_FAILURE';

export const GET_AUTOCOMPLETE_RECORD_REQUESTED =
  'vipProgram/GET_AUTOCOMPLETE_RECORD_REQUESTED';
export const GET_AUTOCOMPLETE_RECORD_SUCCESS =
  'vipProgram/GET_AUTOCOMPLETE_RECORD_SUCCESS';
export const GET_AUTOCOMPLETE_RECORD_FAILURE =
  'vipProgram/GET_AUTOCOMPLETE_RECORD_FAILURE';

export const GET_EVENT_AMBASSADOR_REQUESTED =
  'vipProgram/GET_EVENT_AMBASSADOR_REQUESTED';
export const GET_EVENT_AMBASSADOR_SUCCESS =
  'vipProgram/GET_EVENT_AMBASSADOR_SUCCESS';
export const GET_EVENT_AMBASSADOR_FAILURE =
  'vipProgram/GET_EVENT_AMBASSADOR_FAILURE';

export const GET_EVENT_AMBASSADOR_PROGRAM_REQUESTED =
  'vipProgram/GET_EVENT_AMBASSADOR_PROGRAM_REQUESTED';
export const GET_EVENT_AMBASSADOR_PROGRAM_SUCCESS =
  'vipProgram/GET_EVENT_AMBASSADOR_PROGRAM_SUCCESS';
export const GET_EVENT_AMBASSADOR_PROGRAM_FAILURE =
  'vipProgram/GET_EVENT_AMBASSADOR_PROGRAM_FAILURE';

export const GET_CAMPAIGN_AMBASSADOR_PROGRAM_REQUESTED =
  'vipProgram/GET_CAMPAIGN_AMBASSADOR_PROGRAM_REQUESTED';
export const GET_CAMPAIGN_AMBASSADOR_PROGRAM_SUCCESS =
  'vipProgram/GET_CAMPAIGN_AMBASSADOR_PROGRAM_SUCCESS';
export const GET_CAMPAIGN_AMBASSADOR_PROGRAM_FAILURE =
  'vipProgram/GET_CAMPAIGN_AMBASSADOR_PROGRAM_FAILURE';

export const GET_TOTAL_COUNT_PROGRAMS_REQUESTED =
  'vipProgram/GET_TOTAL_COUNT_PROGRAMS_REQUESTED';
export const GET_TOTAL_COUNT_PROGRAMS_SUCCESS =
  'vipProgram/GET_TOTAL_COUNT_PROGRAMS_SUCCESS';
export const GET_TOTAL_COUNT_PROGRAMS_FAILURE =
  'vipProgram/GET_TOTAL_COUNT_PROGRAMS_FAILURE';

export const GET_TOTAL_COUNT_APPLICATIONS_REQUESTED =
  'vipProgram/GET_TOTAL_COUNT_APPLICATIONS_REQUESTED';
export const GET_TOTAL_COUNT_APPLICATIONS_SUCCESS =
  'vipProgram/GET_TOTAL_COUNT_APPLICATIONS_SUCCESS';
export const GET_TOTAL_COUNT_APPLICATIONS_FAILURE =
  'vipProgram/GET_TOTAL_COUNT_APPLICATIONS_FAILURE';

export const GET_TOTAL_COUNT_CAMPAIGN_WAITLIST_APPLICATIONS_REQUESTED =
  'vipProgram/GET_TOTAL_COUNT_CAMPAIGN_WAITLIST_APPLICATIONS_REQUESTED';
export const GET_TOTAL_COUNT_CAMPAIGN_WAITLIST_APPLICATIONS_SUCCESS =
  'vipProgram/GET_TOTAL_COUNT_CAMPAIGN_WAITLIST_APPLICATIONS_SUCCESS';
export const GET_TOTAL_COUNT_CAMPAIGN_WAITLIST_APPLICATIONS_FAILURE =
  'vipProgram/GET_TOTAL_COUNT_CAMPAIGN_WAITLIST_APPLICATIONS_FAILURE';

export const GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_REQUESTED =
  'vipProgram/GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_REQUESTED';
export const GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_SUCCESS =
  'vipProgram/GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_SUCCESS';
export const GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_FAILURE =
  'vipProgram/GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_FAILURE';

export const GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED_BY_RECEIVED_STATUS_REQUESTED =
  'vipProgram/GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED_BY_RECEIVED_STATUS_REQUESTED';
export const GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED_BY_RECEIVED_STATUS_SUCCESS =
  'vipProgram/GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED_BY_RECEIVED_STATUS_SUCCESS';
export const GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED_BY_RECEIVED_STATUS_FAILURE =
  'vipProgram/GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED_BY_RECEIVED_STATUS_FAILURE';

export const GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_BY_RECEIVED_STATUS_REQUESTED =
  'vipProgram/GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_BY_RECEIVED_STATUS_REQUESTED';
export const GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_BY_RECEIVED_STATUS_SUCCESS =
  'vipProgram/GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_BY_RECEIVED_STATUS_SUCCESS';
export const GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_BY_RECEIVED_STATUS_FAILURE =
  'vipProgram/GET_TOTAL_COUNT_CAMPAIGN_PENDING_PROGRAMS_BY_RECEIVED_STATUS_FAILURE';

export const GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_REQUESTED =
  'vipProgram/GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_REQUESTED';
export const GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_SUCCESS =
  'vipProgram/GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_SUCCESS';
export const GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_FAILURE =
  'vipProgram/GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_FAILURE';

export const GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_REQUESTED =
  'vipProgram/GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_REQUESTED';
export const GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_SUCCESS =
  'vipProgram/GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_SUCCESS';
export const GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_FAILURE =
  'vipProgram/GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_FAILURE';

export const LIST_DATA_REQUESTED = 'vipProgram/LIST_DATA_REQUESTED';
export const LIST_DATA_SUCCESS = 'vipProgram/LIST_DATA_SUCCESS';
export const LIST_DATA_FAILURE = 'vipProgram/LIST_DATA_FAILURE';

export const ACTIVE_TAB_SET = 'vipProgram/ACTIVE_TAB_SET';

export const ERROR_CLEAR = 'vipProgram/ERROR_CLEAR';
export const RECORD_CLEAR = 'vipProgram/RECORD_CLEAR';

export const GET_EXPORT_FILE_REQUESTED = 'vipProgram/GET_EXPORT_FILE_REQUESTED';
export const GET_EXPORT_FILE_SUCCESS = 'vipProgram/GET_EXPORT_FILE_SUCCESS';
export const GET_EXPORT_FILE_FAILURE = 'vipProgram/GET_EXPORT_FILE_FAILURE';

export const TOGGLE_CHANGE_AVAILABILITY_MODAL = 'vipProgram/TOGGLE_CHANGE_AVAILABILITY_MODAL';
export const TOGGLE_CONFIRM_REGISTRATION_MODAL = 'vipProgram/TOGGLE_CONFIRM_REGISTRATION_MODAL';
