import React from "react";

import {
  Message,
  MessageContent,
  Icon
} from "semantic-ui-react";

import { TemplateHeader } from "../../template/templateHeader";
import { TemplateText } from "../../template/templateText";

import "./info-box.css";

interface InfoBoxProps {
  className?: string;
  header?: string;
  content?: string;
  showIcon?: boolean;
  isolated?: boolean;
}

export const InfoBox: React.FC<InfoBoxProps> = (props) => {
  const { showIcon = true } = props;

  return (
    <Message
      className={`info-box ${props.className}`}
    >
      {showIcon && (
        <Icon
          name="info circle"
          size="large"
        />
      )}
      <MessageContent style={{ whiteSpace: "pre-line" }}>
        {props.header && (
          <TemplateHeader
            as="h5"
            label={props.header}
          />
        )}
        {props.content && (
          <TemplateText label={props.content} />
        )}
        {props.children}
      </MessageContent>
    </Message>
  );
};
