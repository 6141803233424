import { customFieldCategory } from "../../../../constants/formBuilder";

export const paths = {
  EVENT: "event",
  EVENT_ID: "eventId",
  PROGRAM_NAME: "name",
  IS_ACTIVE: "isActive",
  FUNDRAISER_DEFAULT_TARGET_AMOUNT: "fundraiserDefaultTargetAmount",
  FUNDRAISER_MINIMUM_TARGET_AMOUNT: "fundraiserMinimumTargetAmount",
  DEFAULT_AMBASSADOR_STATUS: "defaultAmbassadorStatus",
  PAGE_CREATION_STATUS: "pageCreationOption",
  FLOW: "flow",
  DATE_APPLICATIONS_OPEN: "startsAt",
  DATE_APPLICATIONS_CLOSE: "newEntriesEndAt",
  DATE_CLAIMS_CLOSE: "endsAt",
  TERMINOLOGY: "participantName",
  BANNER: "mainImagePath",
  INTRO_TEXT: "description",
  APPLICATION_ENTRY: `template.value.formBuilder.${customFieldCategory.ENTRY}`,
  APPLICATION_CONFIRMATION: `template.value.formBuilder.${customFieldCategory.CONFIRMATION}`,
  APPLICATION_RECEIVED: "emailSettings.newEmailCustomText",
  APPLICATION_ACCEPTED: "emailSettings.acceptedEmailCustomText",
  APPLICATION_REJECTED: "emailSettings.rejectedEmailCustomText",
  SIGNATURE_VERIFICATION_ENABLED: "template.value.enableSignature",
  TERMS_AND_CONDITIONS_ENABLED:
    "template.value.termsAndConditionSetup.allowTermsAndConditions",
  TERMS_AND_CONDITIONS_TITLE: "template.value.termsAndConditionSetup.title",
  TERMS_AND_CONDITIONS_LINK: "template.value.termsAndConditionSetup.link",
  WAIVERS_ENABLED: "template.value.termsAndConditionSetup.allowWaivers",
  WAIVERS: "template.value.termsAndConditionSetup.waivers"
};
