import get from 'lodash.get';
import '../../../helpers/aggregateUrlExtension';
import {
  DELETE_IMPERSONATE_ADMIN_SUCCESS, IMPERSONATE_ADMIN_SUCCESS
} from '../../../modules/session';
import { gridTabs, paths } from '../constants';

import { generalFormBuilderPath } from '../../../constants/formBuilder';
import { gridKeys } from '../../../constants/gridKeys';
import { listReadyStateClear } from '../../../helpers/donationHelper';
import {
  getNewFormBuilderModel, needRemapFormbuilder
} from '../../../helpers/formBuilderHelper';
import update from '../../../helpers/update';
import * as entityHandlers from '../../../modules/bin/entityHandlers';
import { Record } from '../../../modules/bin/utility';
import { CLEAR, LIST_READY_STATE_CLEAR } from '../../../modules/general';
import { campaignTypes } from '../create/templates';
import {
  getCampaignDonationsGrid,
  getCampaignFundraisingGrid, getCampaignMerchandiseGrid, getCampaignSalesGrid, getCampaignsGrid, getCampaignTicketsGrid,
  getFundraiserDonationsGrid, getTeamDonationsGrid,
  getTeamFundraisingGrid
} from '../gridConfiguration';
import { ADD_CUSTOM_CAMPAIGN_OPTION, CAMPAIGN_CLEAR, CLONE_FAILURE, CLONE_REQUESTED, CLONE_SUCCESS, COMPLETE_CREATE_CAMPAIGN_FAILURE, COMPLETE_CREATE_CAMPAIGN_REQUESTED, COMPLETE_CREATE_CAMPAIGN_SUCCESS, CREATE_FAILURE, CREATE_REQUESTED, CREATE_SUCCESS, CREATE_WINDOW_SET_VISIBLE_STATUS, ERROR_CLEAR, GET_EXPORT_FILE_FAILURE, GET_EXPORT_FILE_REQUESTED, GET_EXPORT_FILE_SUCCESS, GET_FAILURE, GET_REQUESTED, GET_SUCCESS, GRID_TAB_CHANGE, LIST_DATA_FAILURE, LIST_DATA_REQUESTED, LIST_DATA_SUCCESS, LIST_ORDERING_CHANGE, METADATA_FAILURE, METADATA_REQUESTED, METADATA_SUCCESS, ON_VALUE_CHANGE, RECORD_CLEAR, RESULT_SET, TEMPLATE_CHANGE, TOGGLE_COLUMNS_CHANGE, TOTAL_AMOUNT_COLLECTIONS_REQUESTED, TOTAL_AMOUNT_COLLECTIONS_SUCCESS, TOTAL_AMOUNT_FUNDRAISERS_REQUESTED, TOTAL_AMOUNT_FUNDRAISERS_SUCCESS, TOTAL_AMOUNT_TEAMS_REQUESTED, TOTAL_AMOUNT_TEAMS_SUCCESS, UPDATE_CAMPAIGN_STATUS_FAILURE, UPDATE_CAMPAIGN_STATUS_REQUESTED, UPDATE_CAMPAIGN_STATUS_SUCCESS } from './campaignTypes';

const i18nListKey = 'campaign.list.header';
const i18nDonationListKey = 'donation.list.header';
const i18nSalesListKey = 'sale.list.header';
const i18nTicketsListKey = 'ticket.list.header';
const i18nMerchandiseListKey = 'merchandise.list.header';

const initialState = {
  timestamp: new Date().getTime(),
  record: Record.getDefaultState(),
  ticketsRecord: Record.getDefaultState(),
  couponsRecord: Record.getDefaultState(),
  recordEdit: Record.getDefaultState(),
  recordCreate: Record.getDefaultState(),
  recordClone: Record.getDefaultState(),
  statusRecord: Record.getDefaultState(),
  totalFundraisersRecord: Record.getDefaultState(),
  totalTeamsRecord: Record.getDefaultState(),
  totalCollectionsRecord: Record.getDefaultState(),
  exportFileRecord: Record.getDefaultState(),
  recordComplete: Record.getDefaultState(),
  templates: {
    metadata: {
      parents: [
        campaignTypes.giveNow,
        campaignTypes.appeal,
        'community-fundraising'
      ],
      examples: [
        {
          key: campaignTypes.giveNow,
          url: 'https://example.grassrootz.com/demo-give-now/donate'
        },
        {
          key: campaignTypes.appeal,
          url: 'https://example.grassrootz.com/appeal'
        }
      ],
      children: {
        'community-fundraising': [
          campaignTypes.bespoke,
          campaignTypes.celebration,
          campaignTypes.challenge,
          campaignTypes.cycling,
          campaignTypes.inMemory,
          campaignTypes.running
        ]
      },
      items: {
        'community-fundraising': {},
        [campaignTypes.giveNow]: {},
        [campaignTypes.appeal]: {},
        [campaignTypes.bespoke]: {},
        [campaignTypes.celebration]: {},
        [campaignTypes.challenge]: {},
        [campaignTypes.cycling]: {},
        [campaignTypes.inMemory]: {},
        [campaignTypes.running]: {},
        [campaignTypes.custom]: {}
      }
    }
  },
  [gridKeys.CAMPAIGNS]: getCampaignsGrid(i18nListKey),
  [gridKeys.CAMPAIGN_DONATIONS]: getCampaignDonationsGrid(i18nDonationListKey),
  [gridKeys.CAMPAIGN_SALES]: getCampaignSalesGrid(i18nSalesListKey),
  [gridKeys.CAMPAIGN_TICKETS]: getCampaignTicketsGrid(i18nTicketsListKey),
  [gridKeys.CAMPAIGN_MERCHANDISE]: getCampaignMerchandiseGrid(i18nMerchandiseListKey),
  [gridKeys.CAMPAIGN_FUNDRAISERS]: getCampaignFundraisingGrid(i18nListKey),
  [gridKeys.FUNDRAISER_DONATIONS]:
    getFundraiserDonationsGrid(i18nDonationListKey),
  [gridKeys.PAGE_DONATIONS]: getTeamDonationsGrid(i18nDonationListKey),
  [gridKeys.PAGE_FUNDRAISERS]: getTeamFundraisingGrid(i18nListKey),
  gridTab: gridTabs.DONATIONS,
  parentTemplate: null,
  childTemplate: null,
  saveModalOpen: false,
  campaignRequestErrors: {
    errors: []
  }
};

// State Reducer
export default (state = initialState, action: { type: any; payload: { key: string | undefined; path: any; value: any; errors: any; listKey: any; }; }) => {
  let newState = state;

  switch (action.type) {
    case CLEAR:
    case CAMPAIGN_CLEAR:
    case DELETE_IMPERSONATE_ADMIN_SUCCESS:
    case IMPERSONATE_ADMIN_SUCCESS: {
      newState = initialState;
      break;
    }
    case RECORD_CLEAR: {
      newState = update.set(
        newState,
        action.payload.key,
        Record.getDefaultState()
      );
      break;
    }

    case RESULT_SET: {
      const path = `${action.payload.key}.data.${action.payload.path}`;
      newState = update.set(newState, path, action.payload.value);
      break;
    }

    case METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case ON_VALUE_CHANGE:
      newState = update.set(
        newState,
        action.payload.path,
        action.payload.value
      );
      break;

    case CREATE_REQUESTED:
    case COMPLETE_CREATE_CAMPAIGN_REQUESTED:
    case CLONE_REQUESTED:
    case TOTAL_AMOUNT_FUNDRAISERS_REQUESTED:
    case TOTAL_AMOUNT_TEAMS_REQUESTED:
    case TOTAL_AMOUNT_COLLECTIONS_REQUESTED:
    case UPDATE_CAMPAIGN_STATUS_REQUESTED:
    case GET_EXPORT_FILE_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case CREATE_SUCCESS:
    case CLONE_SUCCESS:
    case TOTAL_AMOUNT_TEAMS_SUCCESS:
    case TOTAL_AMOUNT_FUNDRAISERS_SUCCESS:
    case TOTAL_AMOUNT_COLLECTIONS_SUCCESS:
    case UPDATE_CAMPAIGN_STATUS_SUCCESS:
    case GET_EXPORT_FILE_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case LIST_READY_STATE_CLEAR:
      newState = listReadyStateClear(newState, gridKeys.CAMPAIGN_SALES);
      newState = listReadyStateClear(newState, gridKeys.CAMPAIGN_TICKETS);
      newState = listReadyStateClear(newState, gridKeys.CAMPAIGN_DONATIONS);
      newState = listReadyStateClear(newState, gridKeys.CAMPAIGN_FUNDRAISERS);
      newState = listReadyStateClear(newState, gridKeys.FUNDRAISER_DONATIONS);
      newState = listReadyStateClear(newState, gridKeys.PAGE_DONATIONS);
      break;

    case GET_REQUESTED:
      newState = entityHandlers.getRequestedHandler(state, action);
      break;

    case COMPLETE_CREATE_CAMPAIGN_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update.set(
        newState,
        'campaignRequestErrors.errors',
        action.payload.errors
      );
      newState = update.set(newState, 'saveModalOpen', true);
      break;

    case COMPLETE_CREATE_CAMPAIGN_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'saveModalOpen', true);
      break;

    case GET_SUCCESS:
      if (!action || !action.payload) {
        break;
      }
      // filter coupon instances (show only enabled)
      if (
        action.payload.key === 'couponsRecord' &&
        get(action, 'payload.data.data.list')
      ) {
        const coupons = get(action, 'payload.data.data.list', []);
        if (coupons && coupons.length > 0) {
          coupons.forEach((coupon: { instances: any[]; }) => {
            if (coupon.instances && coupon.instances.length > 0) {
              coupon.instances = coupon.instances.filter(
                (instance) => instance.enabled
              );
            }
          });
        }
      }
      newState = entityHandlers.getSuccessHandler(state, action);
      //TODO: Think about this 'kostyl' and the one above
      newState = update.set(
        newState,
        `${gridKeys.CAMPAIGN_SALES}.timestamp`,
        new Date().getTime()
      );
      newState = update.set(
        newState,
        `${gridKeys.CAMPAIGN_TICKETS}.timestamp`,
        new Date().getTime()
      );
      newState = update.set(
        newState,
        `${gridKeys.CAMPAIGN_DONATIONS}.timestamp`,
        new Date().getTime()
      );
      newState = update.set(
        newState,
        `${gridKeys.CAMPAIGN_FUNDRAISERS}.timestamp`,
        new Date().getTime()
      );

      const formBuilderModel = get(
        newState,
        `record.data.data.${generalFormBuilderPath}`
      );
      // for backward compatibility with old structure of formbuilder
      if (needRemapFormbuilder(formBuilderModel)) {
        const newFormBuilderModel = getNewFormBuilderModel(formBuilderModel);
        newState = update.set(
          newState,
          `record.data.data.${generalFormBuilderPath}`,
          newFormBuilderModel
        );
      }
      break;

    case CREATE_FAILURE:
    case CLONE_FAILURE:
    case GET_FAILURE:
    case UPDATE_CAMPAIGN_STATUS_FAILURE:
    case GET_EXPORT_FILE_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case TOGGLE_COLUMNS_CHANGE:
      newState = entityHandlers.toggleColumnChangeHandler(state, action);
      break;

    case GRID_TAB_CHANGE:
      newState = update.set(newState, paths.GRID_TAB_FILTER, action.payload);
      break;

    case CREATE_WINDOW_SET_VISIBLE_STATUS:
      newState = update.set(newState, action.payload.key, action.payload.value);
      break;

    case TEMPLATE_CHANGE:
      newState = update.set(newState, action.payload.key, action.payload.value);
      break;

    case LIST_ORDERING_CHANGE:
      newState = update.set(
        newState,
        `${action.payload.listKey}.request.orderBy`,
        action.payload.value
      );
      break;

    case ADD_CUSTOM_CAMPAIGN_OPTION:
      if (!newState.templates.metadata.parents.includes(campaignTypes.custom)) {
        newState = update(newState, {
          templates: {
            metadata: {
              parents: {
                $push: [campaignTypes.custom]
              }
            }
          }
        });
      }
      break;

    default:
      return state;
  }

  return newState;
};
