import React from 'react';
import { useDispatch } from "react-redux";
import { Translate } from 'react-redux-i18n';

import ApplicationConfirmationModal from './applicationConfirmationModal';
import PromoteToReceivedModal from './promoteToReceivedModal';
import ResetStatusModal from './resetStatusModal';
import Button from '../../../../components/common/button';

import { eventAmbassadorStatus } from '../../constants';
import DigitalSignatureModal from './digitalSignatureModal';
import { toggleConfirmRegistrationModal } from '../../store/vipProgramActions';
import { ConfirmRegistrationModal } from '../../confirmRegistrationModal';

const ApplicationPageActions = (props) => {
  const {
    status,
    isResetStatusModalOpen,
    isAcceptModalOpen,
    isDigitalSignatureModalOpen,
    isPromoteToReceivedModalOpen,
    handleModalStateChange,
    isRejectModalOpen,
    handleResetStatus,
    handleReject,
    handlePromoteToReceived,
    handleAccept,
    secureSignatureRecord,
    record,
    registrationConfirmed,
    eventAmbassadorId,
    isSystemAdmin,
  } = props;

  const dispatch = useDispatch();

  const onViewSignatureClick = () => {
    handleModalStateChange.bind(
      this,
      'isDigitalSignatureModalOpen',
      true
    )();
    props.handleViewSignature();
  }

  return (
    <div className="application-actions-container">
      <h5>
        <Translate value="applications.application-actions.title" />
      </h5>
      <div className="application-actions-content">
        <div className="status-title">
          <Translate value="applications.application-actions.status-name.label" />
        </div>
        <div className="status-value">{status}</div>
      </div>
      <div className="actions-buttons-container">
        <ConfirmRegistrationModal />
        <ResetStatusModal
          open={isResetStatusModalOpen}
          handleConfirm={handleResetStatus}
          handleClose={handleModalStateChange.bind(
            this,
            'isResetStatusModalOpen',
            false
          )}
          translationKey='applications.application-actions.reset-status-modal'
          record={record}
        />
        {isPromoteToReceivedModalOpen && (
          <PromoteToReceivedModal
            open={isPromoteToReceivedModalOpen}
            translationKey={
              'applications.application-actions.promote-to-received-modal'
            }
            handleConfirm={handlePromoteToReceived}
            handleClose={handleModalStateChange.bind(
              this,
              'isPromoteToReceivedModalOpen',
              false
            )}
            record={record}
          />
        )}
        {isAcceptModalOpen && (
          <ApplicationConfirmationModal
            open={isAcceptModalOpen}
            showDisableEmailCheckbox={false}
            translationKey={
              'applications.application-actions.application-confirmation-modal.accept-modal'
            }
            handleConfirm={handleAccept}
            handleClose={handleModalStateChange.bind(
              this,
              'applicationConfirmationModal.isAcceptModalOpen',
              false
            )}
            record={record}
          />
        )}
        {isRejectModalOpen && (
          <ApplicationConfirmationModal
            open={isRejectModalOpen}
            showDisableEmailCheckbox
            disableAutomaticEmail={props.disableAutomaticEmail}
            translationKey={
              'applications.application-actions.application-confirmation-modal.reject-modal'
            }
            handleModalStateChange={handleModalStateChange}
            handleConfirm={handleReject}
            handleClose={handleModalStateChange.bind(
              this,
              'applicationConfirmationModal.isRejectModalOpen',
              false
            )}
            record={record}
          />
        )}
        {isDigitalSignatureModalOpen && (
          <DigitalSignatureModal
            open={isDigitalSignatureModalOpen}
            translationKey={
              'applications.application-actions.digital-signature-modal'
            }
            handleModalStateChange={handleModalStateChange}
            handleClose={handleModalStateChange.bind(
              this,
              'isDigitalSignatureModalOpen',
              false
            )}
            record={secureSignatureRecord}
          />
        )}
        {registrationConfirmed ? (
          <div
            style={{
              marginBottom: "14px",
              fontWeight: 700,
            }}
          >
            <Translate value="applications.application-actions.registration-confirmed" />
          </div>
        ) : (
          <div>
            <Button
              className="confirm-registration-button"
              onClick={() => dispatch(toggleConfirmRegistrationModal(eventAmbassadorId))}
            >
              <Translate value="applications.application-actions.buttons.confirm-registration" />
            </Button>
          </div>
        )}
        {status === eventAmbassadorStatus.CLAIMED && (
          <div>
            <Button
              className="view-digital-signature-button"
              onClick={() => onViewSignatureClick()}
            >
              <Translate value="applications.application-actions.buttons.view-digital-signature" />
            </Button>
          </div>
        )}
        {isSystemAdmin && (
          <div>
            <Button
              className="reset-status-button"
              onClick={handleModalStateChange.bind(
                this,
                'isResetStatusModalOpen',
                true
              )}
            >
              <Translate value="applications.application-actions.buttons.reset-status" />
            </Button>
          </div>
        )}
        {status === eventAmbassadorStatus.WAITLISTED && (
          <div>
            <Button
              className="promote-to-received-button"
              onClick={handleModalStateChange.bind(
                this,
                'isPromoteToReceivedModalOpen',
                true
              )}
            >
              <Translate value="applications.application-actions.buttons.promote-to-received" />
            </Button>
          </div>
        )}
        {[eventAmbassadorStatus.WAITLISTED, eventAmbassadorStatus.RECEIVED].includes(status) && (
          <Button
            className="accept-button"
            onClick={handleModalStateChange.bind(
              this,
              'applicationConfirmationModal.isAcceptModalOpen',
              true
            )}
          >
            <Translate value="applications.application-actions.buttons.accept" />
          </Button>
        )}
        {status !== eventAmbassadorStatus.REJECTED && (
          <Button
            className="reject-button"
            onClick={handleModalStateChange.bind(
              this,
              'applicationConfirmationModal.isRejectModalOpen',
              true
            )}
          >
            <Translate value="applications.application-actions.buttons.reject" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default ApplicationPageActions;
