export const gridKeys = {
  CAMPAIGNS: 'campaignList',
  DONATIONS: 'donationsList',
  SALES: 'salesList',
  TICKETS: 'ticketsList',
  FUNDRAISERS: 'fundraisersList',
  ORGANIZATIONS: 'organizationsList',
  ORGANIZATIONS_TIER: 'organizationTierList',
  ACCOUNT_VIEW_DONATIONS: 'accountViewDonationsList',
  ACCOUNT_VIEW_FUNDRAISING: 'accountViewFundraisingList',
  ACCOUNT_ALL_USERS: 'accountAllUsersList',
  ACCOUNT_ADMINS: 'accountAdminsList',
  ACCOUNT_FUNDRAISERS: 'accountFundraisersList',
  ACCOUNT_DONORS: 'accountDonorsList',
  WEBHOOKS: 'webhooksList',
  TERMINALS: 'terminalList',
  CAMPAIGN_DONATIONS: 'campaignDonations',
  CAMPAIGN_FUNDRAISERS: 'campaignFundraisers',
  CAMPAIGN_TICKETS: 'campaignTickets',
  CAMPAIGN_MERCHANDISE: 'campaignMerchandise',
  CAMPAIGN_SALES: 'campaignSales',
  FUNDRAISER_DONATIONS: 'fundraiserDonations',
  PAGE_DONATIONS: 'pageDonations',
  PAGE_FUNDRAISERS: 'pageFundraisers',
  EVENTS: 'eventsList',
  EVENT_ORGANIZATIONS: 'eventOrganizations',
  EVENT_FUNDRAISERS: 'eventFundraisers',
  EVENT_DONATIONS: 'eventDonations',
  MERCHANDISE: 'merchandiseList',
  RECURRING: 'recurringList',
  RECURRING_DONATIONS: 'recurringDonationsList',
  DASHBOARD_CAMPAIGNS: 'dashboardCampaigns',
  DASHBOARD_SALES: 'dashboardSales',
  DASHBOARD_TICKETS: 'dashboardTickets',
  DASHBOARD_FUNDRAISERS: 'dashboardFundraisers',
  DASHBOARD_DONATIONS: 'dashboardDonations',
  DASHBOARD_TOP_CAMPAIGNS: 'dashboardTopCampaigns',
  DASHBOARD_TOP_DONATIONS: 'dashboardTopDonations',
  DASHBOARD_LAST_DONATIONS: 'dashboardLastDonations',
  DASHBOARD_TOP_FUNDRAISERS: 'dashboardTopFundraisers',
  DASHBOARD_LAST_FUNDRAISERS: 'dashboardLastFundraisers',
  DASHBOARD_LAST_SALES: 'dashboardLastSales',
  PROGRAMS: 'programs',
  APPLICATIONS: 'applications',
  PENDING_APPROVAL_PROGRAMS: 'pendingApprovalPrograms',
  VIPPROGRAM: 'vips',
  EVENT_PROGRAMS: 'eventPrograms',
  EVENT_APPLICATIONS: 'eventApplications',
  EVENT_PENDING_APPROVAL_PROGRAMS: 'eventPendingApprovalPrograms',
  EVENT_VIP_PROGRAMS: 'eventVipPrograms',
  CAMPAIGN_PROGRAMS: 'campaignPrograms',
  CAMPAIGN_APPLICATIONS: 'campaignApplications',
  CAMPAIGN_WAITLIST_APPLICATIONS: 'campaignWaitlistApplications',
  CAMPAIGN_PENDING_PROGRAMS_NIL_RAISED: 'campaignPendingProgramsNilRaised',
  CAMPAIGN_PENDING_PROGRAMS: 'campaignPendingPrograms',
  CAMPAIGN_VIP_PROGRAMS: 'campaignVipPrograms'
};
