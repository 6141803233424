// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';

// Actions
import * as dashboardActions from '../reducer';
import * as donationActions from '../../donation/store/donationActions';
import { pageActions } from '../../campaign/page/reducer';
import * as generalActions from '../../../modules/general';
import * as ticketingActions from '../../ticketing/store/ticketingActions';
import { setRedirectUrl } from '../../../modules/session';

// Components
import { Common } from '../../../components';
import Spinner from '../../../components/common/spinner';
import { Menu, Button } from 'semantic-ui-react';
import RefundWindowContainer from '../../donation/refund-window';
import ReceiptWindowContainer from '../../donation/receipt-window';
import RecordResultMessage from '../../../components/common/recordResult';
import RefundSaleWindowContainer from '../../ticketing/modals/refundSaleWindow/RefundSaleWindow';
import ReceiptSaleWindowContainer from '../../ticketing/modals/receiptSaleWindow';
import { ExportDataButton } from '../../../components/template/templateExportButton';
import { ExportDownloadProvider } from '../../export-history';

// Helpers
import get from 'lodash.get';
import { Record } from '../../../modules/bin/utility';
import { hideCondition } from '../../../helpers/hideHelper';
import { getListWithOrgFilterIfSysAdmin } from '../../../helpers/listHelper';

// Constants
import { gridKeys } from '../../../constants/gridKeys';
import { pageTypesStrings } from '../../../constants/pageTypes';
import { exportFileNames } from '../../../constants/exportFiles';
import {
  gridTabsOptions,
  gridTabs,
  gridPageSize,
  gridPanelButtons
} from '../constants';
import { CONTEXT_MENU_KEYS } from '../../../constants/contextMenuEntries';
import {
  OFFLINE_DONATION_TYPE,
  ONLINE_DONATION_TYPE,
  PLEDGE_DONATION_TYPE
} from '../../campaign/constants';
import { FeatureFlag } from '../../../modules/featureflags';

class GridView extends Component {
  handleMenuItemChange = (value) => {
    this.props.generalActions.clearListsReadyState();
    this.props.onValueChange('gridTab', value);

    this.props.applyQueryFilters(
      this.props.timePeriod,
      this.props.timeBracket,
      this.props.reportByDateAndMetricType,
      value,
      this.props.timeLapse,
      this.props.timeLapseFrom,
      this.props.timeLapseTo
    );
  };

  hideColumnCondition = (item) => {
    return hideCondition(
      this.props.isOrganizationAdmin,
      item,
      'path',
      'organization.name'
    );
  };

  isChooseTabButtonDisabled = () => {
    return (
      Record.isRecordLoading(this.props.topCampaignsList) ||
      Record.isRecordLoading(this.props.lastDonationList) ||
      Record.isRecordLoading(this.props.topDonationList) ||
      Record.isRecordLoading(this.props.lastFundraisersList) ||
      Record.isRecordLoading(this.props.topFundraisersList) ||
      Record.isRecordLoading(this.props.lastSalesList)
    );
  };

  onAddOfflineDonation = () => {
    const id = get(this.props, 'match.params.id');
    this.props.history.push(`/donations/create/${id}/donation-detail`);
  };

  handleCreatePageClick = () => {
    const id = get(this.props, 'match.params.id');
    this.props.history.push(`/campaigns/${id}/page/create/page-detail`);
  };

  isFundraisersContextMenuItemHidden = (item) => {
    return item.type === pageTypesStrings.FUNDRAISER;
  };

  isRefundDonationContextMenuItemHidden = (item) => {
    return item.type !== ONLINE_DONATION_TYPE;
  };

  isDeleteDonationContextMenuItemHidden = (item) => {
    return (
      item.type !== OFFLINE_DONATION_TYPE && item.type !== PLEDGE_DONATION_TYPE
    );
  };

  clearMessages = () => {
    this.props.pageActions.clearRecordMessages('saveRecord');
    this.props.donationActions.clearRecordMessages('saveRecord');
    this.props.ticketingActions.clearRecordMessages('saveRecord');
  };

  onViewContextClick = (id, item) => {
    this.props.history.push(`/donations/view/${id}`);
  };

  onRefundContextClick = (id, item) => {
    this.props.donationActions.showRefundConfirmation(item);
  };

  onRefundSaleContextClick = (id, item) => {
    this.props.ticketingActions.showRefundConfirmation(item, 'sale');
  };

  onReissueContextClick = (id, item) => {
    if (get(item, 'donor.email')) {
      this.props.donationActions.showReceiptConfirmation(item);
    } else {
      this.props.donationActions.getReceiptMessageError('saveRecord');
    }
  };

  onReissueSaleContextClick = (id, item) => {
    if (get(item, 'customer.email')) {
      this.props.ticketingActions.showReceiptConfirmation(item, 'sale');
    } else {
      this.props.donationActions.getReceiptMessageError('saveRecord');
    }
  };

  handleViewAllClick = () => {
    const { id, view } = this.props.match.params;
    const tab = gridPanelButtons[this.props.gridTab];
    this.props.history.push(`/${view}/view/${tab}/${id}`);
  };

  isContextMenuDonationsHide = () => {
    return this.props.isEventAdmin || this.props.isEventOrganizerAdmin;
  };

  hideViewButton = (item) => {
    return !this.props.isSystemAdmin;
  };

  render() {
    if (Record.isRecordLoading(this.props.exportFileRecord)) {
      return <Spinner />;
    }

    const { Grid } = Common;
    const { Panel, ContextMenu } = Grid.Managed;

    const { id } = this.props.match.params;
    const model = get(this.props, 'modelRecord.data.data');

    const baseFilter = {
      key: this.props.configurations.baseFilter,
      operator: 'EqualTo',
      value: id
    };

    const gridTab = this.props.gridTab;

    // get list of visible tabs
    const visibleTabs = this.props.configurations.gridTabs.filter(
      (item) =>
        this.props.configurations.disableModelValidation ||
        gridTabsOptions[item].isVisible(
          model,
          this.props.isCampaignAdmin,
          this.props.isEventAdmin,
          this.props.isEventOrganizerAdmin
        )
    );

    // if all tabs are hidden, return null
    if (visibleTabs?.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <RecordResultMessage
          record={this.props.donationSaveRecord}
          onDismiss={this.clearMessages}
        />
        <RefundWindowContainer />
        <ReceiptWindowContainer />
        <RefundSaleWindowContainer />
        <ReceiptSaleWindowContainer />

        <Menu
          className="accounts-tab-buttons-group"
          style={{ margin: '0px', borderRight: '0px' }}
        >
          {visibleTabs?.map((item, index) => {
            const option = gridTabsOptions[item];
            return (
              <React.Fragment key={index}>
                {
                  <Menu.Item
                    id={item}
                    active={gridTab === item}
                    onClick={this.handleMenuItemChange.bind(this, item)}
                    disabled={this.isChooseTabButtonDisabled()}
                  >
                    {I18n.t(`dashboard.table-area.list.${option.i18n}`, {
                      size: gridPageSize
                    })}
                  </Menu.Item>
                }
              </React.Fragment>
            );
          })}
        </Menu>
        {gridTab === gridTabs.campaigns && (
          <Grid.Managed
            id="campaignsList"
            search
            listKey={this.props.campaignsListKey}
            list={this.props.campaignsList}
            actions={this.props.campaignsListActions}
            filter={baseFilter}
            hideColumn={this.hideColumnCondition}
          >
            <Panel name="right">
              <ExportDownloadProvider entity="page/Campaign">
                {({ open }) => (
                  <ExportDataButton
                    versions={['v2']}
                    onExportClick={() => open(this.props.campaignsList.request)}
                  />
                )}
              </ExportDownloadProvider>
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_DASHBOARD.key}
                label={I18n.t('campaign.list.context.dashboard-campaign')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_DASHBOARD
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.EDIT_CAMPAIGN.key}
                label={I18n.t('campaign.list.context.edit-campaign')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_MENU.EDIT_CAMPAIGN
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_PUBLIC_PAGE.key}
                label={I18n.t('campaign.list.context.view-public-page')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_PUBLIC_PAGE
                }
              />
            </ContextMenu>
          </Grid.Managed>
        )}
        {gridTab === gridTabs.sales && (
          <Grid.Managed
            id="salesList"
            search
            listKey={this.props.salesListKey}
            list={this.props.salesList}
            actions={this.props.salesListActions}
            filter={baseFilter}
            hideColumn={this.hideColumnCondition}
          >
            <Panel name="right">
              <ExportDownloadProvider entity="sale">
                {({ open }) => (
                  <ExportDataButton
                    versions={['v3']}
                    onExportClick={() => open(this.props.salesList.request)}
                  />
                )}
              </ExportDownloadProvider>
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_SALE.key}
                label={I18n.t('sale.list.context.edit-sale')}
                contextMenuOptions={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_SALE}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_SALE.key}
                label={I18n.t('sale.list.context.view-sale')}
                contextMenuOptions={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_SALE}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
            </ContextMenu>
          </Grid.Managed>
        )}
        {gridTab === gridTabs.tickets && (
          <Grid.Managed
            id="ticketsList"
            search
            listKey={this.props.ticketsListKey}
            list={this.props.ticketsList}
            actions={this.props.ticketListActions}
            filter={[
              baseFilter,
              {
                key: 'type',
                operator: 'EqualTo',
                value: 'ticket'
              }
            ]}
            hideColumn={this.hideColumnCondition}
          >
            <Panel name="right">
              {/* used is /sale page */}
              <ExportDownloadProvider entity="saleDetail">
                {({ open }) => (
                  <ExportDataButton
                    versions={['v3']}
                    onExportClick={() => open(this.props.ticketsList.request)}
                  />
                )}
              </ExportDownloadProvider>
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_TICKET.key}
                label={I18n.t('ticket.list.context.edit-ticket')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_TICKET
                }
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
            </ContextMenu>
          </Grid.Managed>
        )}
        {gridTab === gridTabs.fundraisers && (
          <Grid.Managed
            id="fundraisersList"
            search
            searchVersion="v3"
            listKey={this.props.fundraisersListKey}
            list={this.props.fundraisersList}
            actions={this.props.fundraisersListActions}
            filter={baseFilter}
            hideColumn={this.hideColumnCondition}
          >
            <Panel name="right">
              <ExportDownloadProvider entity="page">
                {({ open }) => (
                  <ExportDataButton
                    versions={['v2', 'v3']}
                    onExportClick={(version) => {
                      if (version === 'v3') {
                        open({
                          layout: 'Extended',
                          ...(this.props.fundraisersList?.request || {})
                        });
                      } else {
                        open(this.props.fundraisersList.request);
                      }
                    }}
                  />
                )}
              </ExportDownloadProvider>
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.FUNDRAISERS.EDIT_PAGE.key}
                label={I18n.t('fundraiser.list.context.edit-page')}
                contextMenuOptions={CONTEXT_MENU_KEYS.FUNDRAISERS.EDIT_PAGE}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.FUNDRAISERS.VIEW_PUBLIC.key}
                label={I18n.t('fundraiser.list.context.view-public-page')}
                contextMenuOptions={CONTEXT_MENU_KEYS.FUNDRAISERS.VIEW_PUBLIC}
              />
            </ContextMenu>
          </Grid.Managed>
        )}
        {gridTab === gridTabs.donations && (
          <Grid.Managed
            id="donationsList"
            search
            listKey={this.props.donationsListKey}
            list={this.props.donationsList}
            actions={this.props.donationsListActions}
            filter={baseFilter}
            hideColumn={this.hideColumnCondition}
          >
            <Panel name="right">
              <ExportDownloadProvider entity="donation">
                {({ open }) => (
                  <ExportDataButton
                    onExportClick={() => {
                      open(this.props.donationsList.request);
                    }}
                  />
                )}
              </ExportDownloadProvider>
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                id="editDonationContextMenu"
                key={CONTEXT_MENU_KEYS.DONATIONS.EDIT_DONATION.key}
                label={I18n.t('donation.list.context.edit-donation')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DONATIONS.EDIT_DONATION}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DONATIONS.VIEW_DONATION.key}
                label={I18n.t('donation.list.context.view-donation')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DONATIONS.VIEW_DONATION}
              />
            </ContextMenu>
          </Grid.Managed>
        )}
        {gridTab === gridTabs.topCampaigns && (
          <Grid.Managed
            listKey={this.props.topCampaignsListKey}
            list={getListWithOrgFilterIfSysAdmin(
              this.props.topCampaignsList,
              this.props.isSystemAdmin
            )}
            actions={this.props.campaignsListActions}
            hidePagination
            filter={baseFilter}
            hideColumn={this.hideColumnCondition}
          >
            <Panel name="right">
              <FeatureFlag feature="exportData">
                <ExportDownloadProvider entity="page/Campaign">
                  {({ open }) => (
                    <Button
                      onClick={() => open(this.props.topCampaignsList.request)}
                    >
                      <Translate value="campaign.export" />
                    </Button>
                  )}
                </ExportDownloadProvider>
              </FeatureFlag>
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_DASHBOARD.key}
                label={I18n.t('campaign.list.context.dashboard-campaign')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_DASHBOARD
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.EDIT_CAMPAIGN.key}
                label={I18n.t('campaign.list.context.edit-campaign')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_MENU.EDIT_CAMPAIGN
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_PUBLIC_PAGE.key}
                label={I18n.t('campaign.list.context.view-public-page')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_PUBLIC_PAGE
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_SALES.key}
                label={I18n.t('campaign.list.context.view-sales')}
                isHiden={this.isContextMenuSalesAndTicketsHide}
                contextMenuOptions={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_SALES}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_TICKETS.key}
                label={I18n.t('campaign.list.context.view-tickets')}
                isHiden={this.isContextMenuSalesAndTicketsHide}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_TICKETS
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_DONATIONS.key}
                label={I18n.t('campaign.list.context.view-donations')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_DONATIONS
                }
                isHiden={this.isContextMenuDonationsHide}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_FUNDRAISERS.key}
                label={I18n.t('campaign.list.context.view-fundraisers')}
                isHiden={this.isContextMenuFundraisersHide}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_FUNDRAISERS
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.CLONE_CAMPAIGN.key}
                label={I18n.t('campaign.list.context.clone-campaign')}
                isHiden={this.isContextCloneCampaignHide}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_MENU.CLONE_CAMPAIGN
                }
              />
            </ContextMenu>
          </Grid.Managed>
        )}
        {gridTab === gridTabs.lastDonations && (
          <Grid.Managed
            id="lastDonationsList"
            listKey={this.props.lastDonationListKey}
            list={this.props.lastDonationList}
            actions={this.props.donationsListActions}
            hidePagination
            filter={baseFilter}
            hideColumn={this.hideColumnCondition}
          >
            <Panel name="right">
              <ExportDownloadProvider entity="donation">
                {({ open }) => (
                  <ExportDataButton
                    featureFlag
                    onExportClick={() =>
                      open(this.props.lastDonationList.request)
                    }
                  />
                )}
              </ExportDownloadProvider>
              {this.props.configurations.showAddOfflineDonationButton && (
                <Common.Button
                  id="add-offline-donation"
                  onClick={this.onAddOfflineDonation}
                >
                  <Translate value="dashboard.table-area.add-offline-donation" />
                </Common.Button>
              )}
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_DONATION.key}
                label={I18n.t('donation.list.context.view-donation')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_DONATION}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.EDIT_DONATION.key}
                label={I18n.t('donation.list.context.edit-donation')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DASHBOARD.EDIT_DONATION}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.REFUND_DONATION.key}
                label={I18n.t('donation.list.context.refund-donation')}
                onClick={this.onRefundContextClick}
                isHiden={this.isRefundDonationContextMenuItemHidden}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.DELETE_DONATION.key}
                label={I18n.t('donation.list.context.delete-donation')}
                onClick={this.onRefundContextClick}
                isHiden={this.isDeleteDonationContextMenuItemHidden}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.REISSUE_RECEIPT.key}
                label={I18n.t('donation.list.context.reissue-receipt')}
                onClick={this.onReissueContextClick}
              />
            </ContextMenu>
          </Grid.Managed>
        )}

        {gridTab === gridTabs.topDonations && (
          <Grid.Managed
            id="topDonationList"
            listKey={this.props.topDonationListKey}
            list={this.props.topDonationList}
            actions={this.props.donationsListActions}
            hidePagination
            filter={baseFilter}
            hideColumn={this.hideColumnCondition}
          >
            <Panel name="right">
              <ExportDownloadProvider entity="donation">
                {({ open }) => (
                  <ExportDataButton
                    featureFlag
                    onExportClick={() =>
                      open(this.props.topDonationList.request)
                    }
                  />
                )}
              </ExportDownloadProvider>
              {this.props.configurations.showAddOfflineDonationButton && (
                <Common.Button
                  id="add-offline-donation"
                  onClick={this.onAddOfflineDonation}
                >
                  <Translate value="dashboard.table-area.add-offline-donation" />
                </Common.Button>
              )}
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_DONATION.key}
                label={I18n.t('donation.list.context.view-donation')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_DONATION}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.EDIT_DONATION.key}
                label={I18n.t('donation.list.context.edit-donation')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DASHBOARD.EDIT_DONATION}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.REFUND_DONATION.key}
                label={I18n.t('donation.list.context.refund-donation')}
                onClick={this.onRefundContextClick}
                isHiden={this.isRefundDonationContextMenuItemHidden}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.DELETE_DONATION.key}
                label={I18n.t('donation.list.context.delete-donation')}
                onClick={this.onRefundContextClick}
                isHiden={this.isDeleteDonationContextMenuItemHidden}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.REISSUE_RECEIPT.key}
                label={I18n.t('donation.list.context.reissue-receipt')}
                onClick={this.onReissueContextClick}
              />
            </ContextMenu>
          </Grid.Managed>
        )}

        {gridTab === gridTabs.lastFundraisers && (
          <Grid.Managed
            id="lastFundraisersList"
            listKey={this.props.lastFundraisersListKey}
            list={this.props.lastFundraisersList}
            actions={this.props.fundraisersListActions}
            filter={baseFilter}
            hidePagination
            searchVersion="v3"
            hideColumn={this.hideColumnCondition}
          >
            <Panel name="right">
              <FeatureFlag feature="exportData">
                <ExportDownloadProvider entity="page">
                  {({ open }) => (
                    <ExportDataButton
                      versions={['v2', 'v3']}
                      onExportClick={(version) => {
                        if (version === 'v3') {
                          open(
                            {
                              layout: 'Extended',
                              ...(this.props.lastFundraisersList?.request || {})
                            },
                            exportFileNames.FUNDRAISERS,
                            version
                          );
                        } else {
                          open(this.props.lastFundraisersList.request);
                        }
                      }}
                    />
                  )}
                </ExportDownloadProvider>
              </FeatureFlag>
              {this.props.configurations.showCreatePageButton && (
                <Common.Button onClick={this.handleCreatePageClick}>
                  {I18n.t('dashboard.table-area.add-new-page')}
                </Common.Button>
              )}
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.EDIT_PAGE.key}
                label={I18n.t('campaign.list.view.context.edit-page')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DASHBOARD.EDIT_PAGE}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_PUBLIC.key}
                label={I18n.t('campaign.list.view.context.view-public-page')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_PUBLIC}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_DONATIONS.key}
                label={I18n.t('campaign.list.view.context.view-page-donations')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_DONATIONS}
                isHiden={this.isContextMenuDonationsHide}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_FUNDRAISERS.key}
                label={I18n.t(
                  'campaign.list.view.context.view-page-fundraisers'
                )}
                isHiden={this.isFundraisersContextMenuItemHidden}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.DASHBOARD.VIEW_FUNDRAISERS
                }
              />
            </ContextMenu>
          </Grid.Managed>
        )}

        {gridTab === gridTabs.topFundraisers && (
          <Grid.Managed
            id="topFundraisersList"
            listKey={this.props.topFundraisersListKey}
            list={this.props.topFundraisersList}
            actions={this.props.fundraisersListActions}
            filter={baseFilter}
            hidePagination
            searchVersion="v3"
            hideColumn={this.hideColumnCondition}
          >
            <Panel name="right">
              <FeatureFlag feature="exportData">
                <ExportDownloadProvider entity="page">
                  {({ open }) => (
                    <ExportDataButton
                      versions={['v2', 'v3']}
                      onExportClick={(version) => {
                        if (version === 'v3') {
                          open(
                            {
                              layout: 'Extended',
                              ...(this.props.topFundraisersList?.request || {})
                            },
                            exportFileNames.FUNDRAISERS,
                            version
                          );
                        } else {
                          open(this.props.lastFundraisersList.request);
                        }
                      }}
                    />
                  )}
                </ExportDownloadProvider>
              </FeatureFlag>
              {this.props.configurations.showCreatePageButton && (
                <Common.Button onClick={this.handleCreatePageClick}>
                  {I18n.t('dashboard.table-area.add-new-page')}
                </Common.Button>
              )}
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.EDIT_PAGE.key}
                label={I18n.t('campaign.list.view.context.edit-page')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DASHBOARD.EDIT_PAGE}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_PUBLIC.key}
                label={I18n.t('campaign.list.view.context.view-public-page')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_PUBLIC}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_DONATIONS.key}
                label={I18n.t('campaign.list.view.context.view-page-donations')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_DONATIONS}
                isHiden={this.isContextMenuDonationsHide}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.VIEW_FUNDRAISERS.key}
                label={I18n.t(
                  'campaign.list.view.context.view-page-fundraisers'
                )}
                isHiden={this.isFundraisersContextMenuItemHidden}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.DASHBOARD.VIEW_FUNDRAISERS
                }
              />
            </ContextMenu>
          </Grid.Managed>
        )}

        {gridTab === gridTabs.lastSales && (
          <Grid.Managed
            id="lastSalesList"
            listKey={this.props.lastSalesListKey}
            list={this.props.lastSalesList}
            actions={this.props.salesListActions}
            filter={baseFilter}
            hidePagination
            hideColumn={this.hideColumnCondition}
          >
            <Panel name="right">
              <FeatureFlag feature="exportData">
                <ExportDownloadProvider entity="sale">
                  {({ open }) => (
                    <ExportDataButton
                      versions={['v3']}
                      onExportClick={(_) =>
                        open(this.props.lastSalesList.request)
                      }
                    />
                  )}
                </ExportDownloadProvider>
              </FeatureFlag>
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.EDIT_SALE.key}
                label={I18n.t('sale.list.context.edit-sale')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DASHBOARD.EDIT_SALE}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.REFUND_SALE.key}
                label={I18n.t('sale.list.context.refund-sale')}
                onClick={this.onRefundSaleContextClick}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DASHBOARD.REISSUE_INVOICE.key}
                label={I18n.t('sale.list.context.reissue-invoice')}
                onClick={this.onReissueSaleContextClick}
              />
            </ContextMenu>
          </Grid.Managed>
        )}

        {this.props.configurations.showViewAllButton && (
          <div className="grid-top-block">
            <Common.Button onClick={this.handleViewAllClick} id="settings">
              {I18n.t('dashboard.table-area.panel.view-all', {
                entity: gridPanelButtons[gridTab]
              })}
            </Common.Button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapState = ({ dashboard, donation, session }) => {
  const gridTab = dashboard.gridTab;
  const exportFileRecord = dashboard.exportFileRecord;
  const modelRecord = dashboard.modelRecord;

  const campaignsGrid = Common.Grid.Managed.mapGridState(
    dashboard,
    gridKeys.DASHBOARD_CAMPAIGNS
  );
  const salesGrid = Common.Grid.Managed.mapGridState(
    dashboard,
    gridKeys.DASHBOARD_SALES
  );
  const ticketsGrid = Common.Grid.Managed.mapGridState(
    dashboard,
    gridKeys.DASHBOARD_TICKETS
  );
  const fundraisersGrid = Common.Grid.Managed.mapGridState(
    dashboard,
    gridKeys.DASHBOARD_FUNDRAISERS
  );
  const donationsGrid = Common.Grid.Managed.mapGridState(
    dashboard,
    gridKeys.DASHBOARD_DONATIONS
  );
  const topCampaignsGrid = Common.Grid.Managed.mapGridState(
    dashboard,
    gridKeys.DASHBOARD_TOP_CAMPAIGNS
  );
  const lastDonationGrid = Common.Grid.Managed.mapGridState(
    dashboard,
    gridKeys.DASHBOARD_LAST_DONATIONS
  );
  const topDonationGrid = Common.Grid.Managed.mapGridState(
    dashboard,
    gridKeys.DASHBOARD_TOP_DONATIONS
  );
  const lastFundraisersGrid = Common.Grid.Managed.mapGridState(
    dashboard,
    gridKeys.DASHBOARD_LAST_FUNDRAISERS
  );
  const topFundraisersGrid = Common.Grid.Managed.mapGridState(
    dashboard,
    gridKeys.DASHBOARD_TOP_FUNDRAISERS
  );
  const lastSalesGrid = Common.Grid.Managed.mapGridState(
    dashboard,
    gridKeys.DASHBOARD_LAST_SALES
  );

  const isSystemAdmin = get(session, 'isSystemAdmin');
  const isOrganizationAdmin = get(session, 'isOrganizationAdmin');
  const isEventAdmin = get(session, 'isEventAdmin');
  const isEventOrganizerAdmin = get(session, 'isEventOrganizerAdmin');
  const isCampaignAdmin = get(session, 'isCampaignAdmin');

  return {
    gridTab,
    exportFileRecord,
    modelRecord,
    donationSaveRecord: donation.saveRecord,

    campaignsListKey: campaignsGrid.key,
    campaignsTimestamp: campaignsGrid.timestamp,
    campaignsList: campaignsGrid.list,

    salesListKey: salesGrid.key,
    salesTimestamp: salesGrid.timestamp,
    salesList: salesGrid.list,

    ticketsListKey: ticketsGrid.key,
    ticketsTimestamp: ticketsGrid.timestamp,
    ticketsList: ticketsGrid.list,

    fundraisersListKey: fundraisersGrid.key,
    fundraisersTimestamp: fundraisersGrid.timestamp,
    fundraisersList: fundraisersGrid.list,

    donationsListKey: donationsGrid.key,
    donationsTimestamp: donationsGrid.timestamp,
    donationsList: donationsGrid.list,

    topCampaignsListKey: topCampaignsGrid.key,
    topCampaignsTimestamp: topCampaignsGrid.timestamp,
    topCampaignsList: topCampaignsGrid.list,

    lastDonationListKey: lastDonationGrid.key,
    lastDonationTimestamp: lastDonationGrid.timestamp,
    lastDonationList: lastDonationGrid.list,

    topDonationListKey: topDonationGrid.key,
    topDonationTimestamp: topDonationGrid.timestamp,
    topDonationList: topDonationGrid.list,

    lastFundraisersListKey: lastFundraisersGrid.key,
    lastFundraisersTimestamp: lastFundraisersGrid.timestamp,
    lastFundraisersList: lastFundraisersGrid.list,

    topFundraisersListKey: topFundraisersGrid.key,
    topFundraisersTimestamp: topFundraisersGrid.timestamp,
    topFundraisersList: topFundraisersGrid.list,

    lastSalesListKey: lastSalesGrid.key,
    lastSalesTimestamp: lastSalesGrid.timestamp,
    lastSalesList: lastSalesGrid.list,

    isSystemAdmin,
    isOrganizationAdmin,
    isEventAdmin,
    isCampaignAdmin,
    isEventOrganizerAdmin,

    timeLapse: dashboard.timeLapse,
    timeLapseFrom: dashboard.timeLapseFrom,
    timeLapseTo: dashboard.timeLapseTo,
    timeBracket: dashboard.timeBracket,
    timePeriod: dashboard.timePeriod,
    reportByDateAndMetricType: dashboard.reportByDateAndMetricType,

    configurations: dashboard.configurations
  };
};

const mapDispatch = (dispatch) => {
  const dispatchers = bindActionCreators(dashboardActions, dispatch);

  dispatchers.campaignsListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: dashboardActions.getCampaignMetadata,
      getListData: dashboardActions.getCampaignListData,
      getListDataBySearchTerm: dashboardActions.getCampaignListDataBySearchTerm
    }
  );

  dispatchers.salesListActions = Common.Grid.Managed.bindGridActions(dispatch, {
    getMetadata: dashboardActions.getSaleMetadata,
    getListData: dashboardActions.getSaleListData,
    getListDataBySearchTerm: dashboardActions.getSaleListDataBySearchTerm
  });

  dispatchers.ticketListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: dashboardActions.getTicketMetadata,
      getListData: dashboardActions.getTicketListData,
      getListDataBySearchTerm: dashboardActions.getTicketListDataBySearchTerm
    }
  );

  dispatchers.donationsListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: dashboardActions.getDonationMetadata,
      getListData: dashboardActions.getDonationListData,
      getListDataBySearchTerm: dashboardActions.getDonationListDataBySearchTerm
    }
  );

  dispatchers.fundraisersListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: dashboardActions.getFundraiserMetadata,
      getListData: dashboardActions.getFundraiserListData,
      getListDataBySearchTerm:
        dashboardActions.getFundraiserListDataBySearchTerm
    }
  );

  dispatchers.generalActions = bindActionCreators(generalActions, dispatch);
  dispatchers.donationActions = bindActionCreators(donationActions, dispatch);
  dispatchers.ticketingActions = bindActionCreators(ticketingActions, dispatch);
  dispatchers.pageActions = bindActionCreators(pageActions, dispatch);
  dispatchers.setRedirectUrl = bindActionCreators(setRedirectUrl, dispatch);

  return dispatchers;
};

const GridContainer = withRouter(connect(mapState, mapDispatch)(GridView));
export default GridContainer;
