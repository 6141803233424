// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

// Actions
import * as programActions from '../store/vipProgramActions';

// Helpers
import get from 'lodash.get';
import { Menu, Button } from 'semantic-ui-react';
import { hideCondition } from '../../../helpers/hideHelper';
import { Record } from '../../../modules/bin/utility';
import { getColouredMarks } from '../helpers/getColouredMarks';
import {
  getListWithFiltersIfEventOrganizerAdmin,
  getListWithFiltersIfSysAdmin
} from '../../../helpers/listHelper';
import SettingsManager, {
  SETTINGS_KEYS
} from '../../../modules/bin/settingsManager';

// Components
import { Common } from '../../../components';
import BasePage from '../../../components/common/basePageView';
import Spinner from '../../../components/common/spinner';
import clear from '../../../components/clear';
import { CustomGridOptions } from './customGridOptions';
import { ExportDownloadProvider } from '../../export-history';

// Constants
import { gridTabs, i18nKeys, gridKeys } from '../constants';
import {
  EVENT_ID_FILTER,
  ORGANIZATION_ID_FILTER
} from '../../../constants/metadataConstants';

// ----------------------------------------------------------------------

/** A page to view all campaigns in a list */
class ListsView extends Component {
  /**
   * Changes the grid page between donations and fundraisers
   * @param {int} idx - the tab number to be shown
   */

  onFilterButtonClick = (idx) => {
    const eventId = get(this.props, 'match.params.eventId');
    const campaignId = get(this.props, 'match.params.campaignId');

    if (eventId) {
      this.props.history.push(`/${this.props.filterPath}/${eventId}/${idx}`);
      return;
    }
    if (campaignId) {
      this.props.history.push(`/${this.props.filterPath}/${campaignId}/${idx}`);
      return;
    }
    this.props.history.push(`/${this.props.filterPath}/${idx}`);
  };

  isProgramTabActive = () => {
    const gridKey = get(this.props, 'match.params.tab');

    return (
      !this.props.isCampaignAdmin &&
      !this.props.isOrganizationAdmin &&
      gridKey === gridKeys.PROGRAMS
    );
  };

  isCampaignProgramsTabActive = () => {
    const gridKey = get(this.props, 'match.params.tab');

    return (
      (this.props.isCampaignAdmin || this.props.isOrganizationAdmin) &&
      gridKey === gridKeys.PROGRAMS
    );
  };

  isApplicationsTabActive = () => {
    const gridKey = get(this.props, 'match.params.tab');

    return gridKey === gridKeys.APPLICATIONS;
  };

  isCampaignWaitlistTabActive = () => {
    const gridKey = get(this.props, 'match.params.tab');

    return gridKey === gridKeys.WAITLIST;
  };

  isPendingApprovalProgramsTabActive = () => {
    const gridKey = get(this.props, 'match.params.tab');
    return gridKey === gridKeys.PENDING_APPROVAL;
  };

  isCampaignPendingProgramsNilRaisedTabActive = () => {
    const gridKey = get(this.props, 'match.params.tab');
    return gridKey === gridKeys.PENDING_NO_FUNDS_RAISED;
  };

  isVipTabActive = () => {
    const gridKey = get(this.props, 'match.params.tab');

    return gridKey === gridKeys.VIPS;
  };

  getTabsButtons = () => {
    const isChooseTabButtonDisabled = this.isChooseTabButtonDisabled();

    const programsTotalCount = Record.isRecordReady(
      this.props.totalCountProgramsRecord
    )
      ? get(this.props.totalCountProgramsRecord, 'data')
      : 0;
    const applicationsTotalCount = Record.isRecordReady(
      this.props.totalCountApplicationsRecord
    )
      ? get(this.props.totalCountApplicationsRecord, 'data')
      : 0;
    const campaignWaitlistApplicationsTotalCount = Record.isRecordReady(
      this.props.totalCountCampaignWaitlistApplicationsRecord
    )
      ? get(this.props.totalCountCampaignWaitlistApplicationsRecord, 'data')
      : 0;
    const pendingApprovalProgramsTotalCount = Record.isRecordReady(
      this.props.totalCountPendingApprovalProgramsByReceivedStatusRecord
    )
      ? get(
          this.props.totalCountPendingApprovalProgramsByReceivedStatusRecord,
          'data'
        )
      : 0;
    const campaignPendingProgramsNilRaisedTotalCount = Record.isRecordReady(
      this.props.totalCountCampaignPendingProgramsNilRaisedByReceivedStatusRecord
    )
      ? get(
          this.props.totalCountCampaignPendingProgramsNilRaisedByReceivedStatusRecord,
          'data'
        )
      : 0;
    const campaignPendingProgramsTotalCount = Record.isRecordReady(
      this.props.totalCountCampaignPendingProgramsByReceivedStatusRecord
    )
      ? get(
          this.props.totalCountCampaignPendingProgramsByReceivedStatusRecord,
          'data'
        )
      : 0;

    const vipProgramsByClaimedStatusTotalCount = Record.isRecordReady(
      this.props.totalCountVIPProgramsByClaimedStatusRecord
    )
      ? get(this.props.totalCountVIPProgramsByClaimedStatusRecord, 'data')
      : 0;
    const vipProgramsByAcceptedStatusTotalCount = Record.isRecordReady(
      this.props.totalCountVIPProgramsByAcceptedStatusRecord
    )
      ? get(this.props.totalCountVIPProgramsByAcceptedStatusRecord, 'data')
      : 0;
    const vipProrgamsTotalCount =
      vipProgramsByClaimedStatusTotalCount +
      vipProgramsByAcceptedStatusTotalCount;

    return (
      <Menu className="programs-tab-buttons-group">
        {this.props.programsGridKey &&
          !this.props.isCampaignAdmin &&
          !this.props.isOrganizationAdmin && (
            <Menu.Item
              name={this.props.programsGridKey}
              active={this.isProgramTabActive()}
              disabled={isChooseTabButtonDisabled}
              onClick={this.onFilterButtonClick.bind(this, gridKeys.PROGRAMS)}
            >
              <Translate
                value={I18n.t(
                  `${this.props.programsGridKey}.${i18nKeys.PROGRAMS_TAB_TITLE}`,
                  { count: programsTotalCount }
                )}
              />
            </Menu.Item>
          )}
        {(this.props.isCampaignAdmin || this.props.isOrganizationAdmin) && (
          <Menu.Item
            name={this.props.campaignProgramsGridKey}
            active={this.isCampaignProgramsTabActive()}
            disabled={isChooseTabButtonDisabled}
            onClick={this.onFilterButtonClick.bind(this, gridKeys.PROGRAMS)}
          >
            <Translate
              value={I18n.t(
                `${this.props.campaignProgramsGridKey}.${i18nKeys.PROGRAMS_TAB_TITLE}`,
                { count: programsTotalCount }
              )}
            />
          </Menu.Item>
        )}
        <Menu.Item
          name={this.props.applicationsGridKey}
          active={this.isApplicationsTabActive()}
          disabled={isChooseTabButtonDisabled}
          onClick={this.onFilterButtonClick.bind(this, gridKeys.APPLICATIONS)}
        >
          <Translate
            value={I18n.t(
              `${this.props.applicationsGridKey}.${i18nKeys.APPLICATIONS_TAB_TITLE}`,
              { count: applicationsTotalCount }
            )}
          />
        </Menu.Item>
        {this.props.campaignWaitlistApplicationsGridKey && (
          <Menu.Item
            name={this.props.campaignWaitlistApplicationsGridKey}
            active={this.isCampaignWaitlistTabActive()}
            disabled={isChooseTabButtonDisabled}
            onClick={this.onFilterButtonClick.bind(this, gridKeys.WAITLIST)}
          >
            <Translate
              value={I18n.t(
                `${this.props.campaignWaitlistApplicationsGridKey}.${i18nKeys.CAMPAIGN_WAITLIST_APPLICATIONS_TAB_TITLE}`,
                { count: campaignWaitlistApplicationsTotalCount }
              )}
            />
          </Menu.Item>
        )}
        {this.props.campaignPendingProgramsNilRaisedGridKey && (
          <Menu.Item
            name={this.props.campaignPendingProgramsNilRaisedGridKey}
            active={this.isCampaignPendingProgramsNilRaisedTabActive()}
            disabled={isChooseTabButtonDisabled}
            onClick={this.onFilterButtonClick.bind(
              this,
              gridKeys.PENDING_NO_FUNDS_RAISED
            )}
          >
            <Translate
              value={I18n.t(
                `${this.props.campaignPendingProgramsNilRaisedGridKey}.${i18nKeys.PENDING_APPROVAL_NIL_RAISED_TAB_TITLE}`,
                { count: campaignPendingProgramsNilRaisedTotalCount }
              )}
            />
          </Menu.Item>
        )}
        <Menu.Item
          name={this.props.campaignPendingProgramsGridKey || this.props.pendingApprovalProgramsGridKey}
          active={this.isPendingApprovalProgramsTabActive()}
          disabled={isChooseTabButtonDisabled}
          onClick={this.onFilterButtonClick.bind(
            this,
            gridKeys.PENDING_APPROVAL
          )}
        >
          <Translate
            value={I18n.t(
              `${this.props.campaignPendingProgramsGridKey || this.props.pendingApprovalProgramsGridKey}.${i18nKeys.PENDING_APPROVAL_TAB_TITLE}`,
              { count: this.props.campaignPendingProgramsGridKey ? campaignPendingProgramsTotalCount : pendingApprovalProgramsTotalCount }
            )}
          />
        </Menu.Item>
        <Menu.Item
          name={this.props.vipProgramsGridKey}
          active={this.isVipTabActive()}
          disabled={isChooseTabButtonDisabled}
          onClick={this.onFilterButtonClick.bind(this, gridKeys.VIPS)}
        >
          <Translate
            value={I18n.t(
              `${this.props.vipProgramsGridKey}.${i18nKeys.VIP_PROGRAMS_TAB_TITLE}`,
              { count: vipProrgamsTotalCount }
            )}
          />
        </Menu.Item>
      </Menu>
    );
  };

  showSpinner = () => {
    return Record.isRecordLoading(this.props.exportFileRecord);
  };

  hideColumnCondition = (item) => {
    if (this.props.isOrganizationAdmin) {
      return hideCondition(
        this.props.isOrganizationAdmin,
        item,
        'path',
        'organization.name'
      );
    }
    if (this.props.isCampaignAdmin) {
      return hideCondition(
        this.props.isCampaignAdmin,
        item,
        'path',
        'organization.name'
      );
    }
  };

  isChooseTabButtonDisabled = () => {
    let isDisabled = Record.isRecordLoading(this.props.applicationsList) ||
      Record.isRecordLoading(this.props.vipProgramsList);

    if (this.props.pendingApprovalProgramsGridKey) {
      let isDisabled = (
        Record.isRecordLoading(this.props.pendingApprovalProgramsList) ||
          isDisabled
      );
    }

    if (this.props.campaignPendingProgramsNilRaisedGridKey) {
      let isDisabled = (
        Record.isRecordLoading(this.props.campaignPendingProgramsNilRaisedList) ||
          isDisabled
      );
    }

    if (this.props.campaignPendingProgramsGridKey) {
      let isDisabled = (
        Record.isRecordLoading(this.props.campaignPendingProgramsList) ||
          isDisabled
      );
    }

    if (this.props.programGridKey) {
      let isDisabled = (
        Record.isRecordLoading(this.props.programsList) ||
          isDisabled
      );
    }

    if (this.props.campaignWaitlistApplicationsGridKey) {
      let isDisabled = (
        Record.isRecordLoading(this.props.campaignWaitlistApplicationsList) ||
          isDisabled
      );
    }

    return isDisabled;
  };

  getListWithOrgFilter = (list) => {
    if (this.props.isSystemAdmin) {
      return getListWithFiltersIfSysAdmin(list, this.props.isSystemAdmin, [
        EVENT_ID_FILTER,
        ORGANIZATION_ID_FILTER
      ]);
    } else if (this.props.isEventOrganizerAdmin) {
      return getListWithFiltersIfEventOrganizerAdmin(
        list,
        this.props.isEventOrganizerAdmin,
        [EVENT_ID_FILTER, ORGANIZATION_ID_FILTER]
      );
    } else {
      return list;
    }
  };

  getBaseFilter = (existingFilter, archiveKey) => {
    const displayArchived = SettingsManager.getSettingsByKey(
      SETTINGS_KEYS.FILTER,
      'displayArchived'
    );

    let baseFilter = existingFilter || [];

    if (archiveKey && !displayArchived) {
      const statusFilter = {
        key: archiveKey,
        operator: 'EqualTo',
        value: 'live'
      };

      if (!Array.isArray(baseFilter)) {
        baseFilter = [baseFilter];
      }

      baseFilter.push(statusFilter);
    }
    return baseFilter;
  };

  /**
   * Renders the component
   */
  render() {
    if (this.showSpinner()) {
      return <Spinner />;
    }

    const { Grid } = Common;
    const { Panel } = Grid.Managed;

    const tabsButtons = this.getTabsButtons();
    const colouredMarks = getColouredMarks(this.props.metrics);

    const listWithOrgFilterApplicationsTab = this.getListWithOrgFilter(
      this.props.applicationsList
    );
    const listWithOrgFilterCampaignPendingNilRaisedTab = this.props.campaignPendingProgramsNilRaisedGridKey
      ? this.getListWithOrgFilter(this.props.campaignPendingProgramsNilRaisedList)
      : null;
    const listWithOrgFilterPendingApprovalTab = this.getListWithOrgFilter(
      this.props.campaignPendingProgramsGridKey ? this.props.campaignPendingProgramsList : this.props.pendingApprovalProgramsList
    );
    const listWithOrgFilterVipTab = this.getListWithOrgFilter(
      this.props.vipProgramsList
    );

    return (
      <BasePage>
        <div className="grid-cards-block">{colouredMarks}</div>
        {tabsButtons}
        {this.isProgramTabActive() && (
          <Grid.Managed
            listKey={this.props.programsListKey}
            list={this.props.programsList}
            actions={this.props.programsListActions}
            toggleColumns
            searchPlaceholder={`${this.props.programsGridKey}.placeholder`}
            search
            filter={this.getBaseFilter(
              this.props.baseFilter,
              this.props.programsListKey === 'programs'
                ? 'event.status'
                : 'campaign.status'
            )}
            hideColumn={this.hideColumnCondition}
            customGridOptions={CustomGridOptions}
            applyAggregates={this.props.applyAggregates}
            filtersButton
          >
            {this.props.isExportDataAllowed && (
              <Panel name="right">
                <ExportDownloadProvider
                  entity={this.props.programsExportEndpoint}
                >
                  {({ open }) => (
                    <Button
                      onClick={() => open(this.props.programsList.request)}
                    >
                      <Translate
                        value={`${this.props.programsGridKey}.export`}
                      />
                    </Button>
                  )}
                </ExportDownloadProvider>
              </Panel>
            )}
            {this.props.programsContextMenu}
          </Grid.Managed>
        )}
        {this.isCampaignProgramsTabActive() && (
          <Grid.Managed
            listKey={this.props.campaignProgramsListKey}
            list={this.props.campaignProgramsList}
            actions={this.props.campaignProgramsListActions}
            toggleColumns
            searchPlaceholder={`${this.props.campaignProgramsGridKey}.placeholder`}
            search
            filter={this.getBaseFilter(
              this.props.baseFilter,
              'campaign.status'
            )}
            hideColumn={this.hideColumnCondition}
            customGridOptions={CustomGridOptions}
            applyAggregates={this.props.applyAggregates}
            filtersButton
          >
            {this.props.isExportDataAllowed && (
              <Panel name="right">
                <ExportDownloadProvider
                  entity={this.props.campaignProgramsExportEndpoint}
                >
                  {({ open }) => (
                    <Button
                      onClick={() =>
                        open(this.props.campaignProgramsList.request)
                      }
                    >
                      <Translate
                        value={`${this.props.campaignProgramsGridKey}.export`}
                      />
                    </Button>
                  )}
                </ExportDownloadProvider>
              </Panel>
            )}
            {this.props.campaignProgramsContextMenu}
          </Grid.Managed>
        )}
        {this.isApplicationsTabActive() && (
          <Grid.Managed
            id="applicationsList"
            listKey={this.props.applicationsListKey}
            list={listWithOrgFilterApplicationsTab}
            actions={this.props.applicationsListActions}
            toggleColumns
            searchPlaceholder={`${this.props.applicationsGridKey}.placeholder`}
            search
            filter={this.getBaseFilter(
              this.props.baseFilter,
              'campaign.status'
            )}
            hideColumn={this.hideColumnCondition}
            customGridOptions={CustomGridOptions}
            applyAggregates={this.props.applyAggregates}
            filtersButton
          >
            {this.props.isExportDataAllowed && (
              <Panel name="right">
                <ExportDownloadProvider
                  entity={this.props.applicationsExportEndpoint}
                >
                  {({ open }) => (
                    <Button
                      onClick={() => open(this.props.applicationsList.request)}
                    >
                      <Translate
                        value={`${this.props.applicationsGridKey}.export`}
                      />
                    </Button>
                  )}
                </ExportDownloadProvider>
              </Panel>
            )}
            {this.props.applicationsContextMenu}
          </Grid.Managed>
        )}
        {this.isCampaignWaitlistTabActive() && (
          <Grid.Managed
            id="campaignWaitlistApplications"
            listKey={this.props.campaignWaitlistApplicationsListKey}
            list={this.props.campaignWaitlistApplicationsList}
            actions={this.props.campaignWaitlistApplicationsListActions}
            toggleColumns
            searchPlaceholder={`${this.props.campaignWaitlistApplicationsGridKey}.placeholder`}
            search
            filter={this.getBaseFilter(
              this.props.campaignWaitlistApplicationsFilters,
              'campaign.status'
            )}
            hideColumn={this.hideColumnCondition}
            customGridOptions={CustomGridOptions}
            applyAggregates={this.props.applyAggregates}
            filtersButton
            shortDescription={true}
          >
            {this.props.isExportDataAllowed && (
              <Panel name="right">
                <ExportDownloadProvider
                  entity={this.props.campaignWaitlistApplicationsExportEndpoint}
                >
                  {({ open }) => (
                    <Button
                      onClick={() => open(this.props.campaignWaitlistApplicationsList.request)}
                    >
                      <Translate
                        value={`${this.props.campaignWaitlistApplicationsGridKey}.export`}
                      />
                    </Button>
                  )}
                </ExportDownloadProvider>
              </Panel>
            )}
            {this.props.campaignWaitlistApplicationsContextMenu}
          </Grid.Managed>
        )}
        {this.isCampaignPendingProgramsNilRaisedTabActive() && (
          <Grid.Managed
            id="campaignPendingProgramsNilRaisedList"
            listKey={this.props.campaignPendingProgramsNilRaisedListKey}
            list={listWithOrgFilterCampaignPendingNilRaisedTab}
            actions={this.props.pendingApprovalProgramsListActions}
            toggleColumns
            searchPlaceholder={`${this.props.campaignPendingProgramsNilRaisedGridKey}.placeholder`}
            search
            filter={this.getBaseFilter(
              this.props.campaignPendingProgramsNilRaisedFilters,
              'campaign.status'
            )}
            hideColumn={this.hideColumnCondition}
            customGridOptions={CustomGridOptions}
            applyAggregates={this.props.applyAggregates}
            filtersButton
          >
            {this.props.isExportDataAllowed && (
              <Panel name="right">
                <ExportDownloadProvider
                  entity={this.props.campaignPendingProgramsNilRaisedExportEndpoint}
                >
                  {({ open }) => (
                    <Button
                      onClick={() =>
                        open(this.props.campaignPendingProgramsNilRaisedList.request)
                      }
                    >
                      <Translate
                        value={`${this.props.campaignPendingProgramsNilRaisedGridKey}.export`}
                      />
                    </Button>
                  )}
                </ExportDownloadProvider>
              </Panel>
            )}
            {this.props.campaignPendingProgramsNilRaisedContextMenu}
          </Grid.Managed>
        )}
        {this.isPendingApprovalProgramsTabActive() && (
          <Grid.Managed
            id="pendingApprovalProgramsList"
            listKey={this.props.campaignPendingProgramsListKey || this.props.pendingApprovalProgramsListKey}
            list={listWithOrgFilterPendingApprovalTab}
            actions={this.props.pendingApprovalProgramsListActions}
            toggleColumns
            searchPlaceholder={`${this.props.campaignPendingProgramsGridKey || this.props.pendingApprovalProgramsGridKey}.placeholder`}
            search
            filter={this.getBaseFilter(
              this.props.campaignPendingProgramsFilters || this.props.pendingApprovalProgramsFilters,
              'campaign.status'
            )}
            hideColumn={this.hideColumnCondition}
            customGridOptions={CustomGridOptions}
            applyAggregates={this.props.applyAggregates}
            filtersButton
          >
            {this.props.isExportDataAllowed && (
              <Panel name="right">
                <ExportDownloadProvider
                  entity={this.props.campaignPendingProgramsExportEndpoint || this.props.pendingApprovalProgramsExportEndpoint}
                >
                  {({ open }) => (
                    <Button
                      onClick={() =>
                        open(
                          this.props.campaignPendingProgramsGridKey
                            ? this.props.campaignPendingProgramsList.request
                            : this.props.pendingApprovalProgramsList.request
                        )
                      }
                    >
                      <Translate
                        value={`${this.props.campaignPendingProgramsGridKey || this.props.pendingApprovalProgramsGridKey}.export`}
                      />
                    </Button>
                  )}
                </ExportDownloadProvider>
              </Panel>
            )}
            {
              this.props.campaignPendingProgramsGridKey
                ? this.props.campaignPendingProgramsContextMenu
                : this.props.pendingApprovalProgramsContextMenu
            }
          </Grid.Managed>
        )}
        {this.isVipTabActive() && (
          <Grid.Managed
            id="vipProgramsList"
            listKey={this.props.vipProgramsListKey}
            list={listWithOrgFilterVipTab}
            actions={this.props.vipProgramsListActions}
            toggleColumns
            searchPlaceholder={`${this.props.vipProgramsGridKey}.placeholder`}
            searchVersion="v3"
            search
            filter={this.getBaseFilter(
              this.props.vipProgramsFilters,
              'campaign.status'
            )}
            hideColumn={this.hideColumnCondition}
            customGridOptions={CustomGridOptions}
            applyAggregates={this.props.applyAggregates}
            filtersButton
          >
            {this.props.isExportDataAllowed && (
              <Panel name="right">
                <ExportDownloadProvider
                  entity={this.props.vipProgramsExportEndpoint}
                >
                  {({ open }) => (
                    <Button
                      onClick={() => open(this.props.vipProgramsList.request)}
                    >
                      <Translate
                        value={`${this.props.vipProgramsGridKey}.export`}
                      />
                    </Button>
                  )}
                </ExportDownloadProvider>
              </Panel>
            )}
            {this.props.vipProgramsContextMenu}
          </Grid.Managed>
        )}
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ program, session }, ownProps) => {
  const exportFileRecord = program.exportFileRecord;

  // // A reference to the list in state
  const programsGrid =
    ownProps.programsGridKey &&
    !ownProps.isCampaignAdmin &&
    !ownProps.isOrganizationAdmin &&
    Common.Grid.Managed.mapGridState(program, ownProps.programsGridKey);
  const campaignProgramsGrid =
    ownProps.campaignProgramsGridKey &&
    Common.Grid.Managed.mapGridState(program, ownProps.campaignProgramsGridKey);
  const applicationsGrid = Common.Grid.Managed.mapGridState(
    program,
    ownProps.applicationsGridKey
  );
  const campaignWaitlistApplicationsGrid =
    ownProps.campaignWaitlistApplicationsGridKey &&
    Common.Grid.Managed.mapGridState(program, ownProps.campaignWaitlistApplicationsGridKey);
  const pendingApprovalProgramsGrid = 
    ownProps.pendingApprovalProgramsGridKey &&
    Common.Grid.Managed.mapGridState(program, ownProps.pendingApprovalProgramsGridKey);
  const campaignPendingProgramsNilRaisedGrid =
    ownProps.campaignPendingProgramsNilRaisedGridKey &&
    Common.Grid.Managed.mapGridState(program, ownProps.campaignPendingProgramsNilRaisedGridKey);
  const campaignPendingProgramsGrid =
    ownProps.campaignPendingProgramsGridKey &&
    Common.Grid.Managed.mapGridState(program, ownProps.campaignPendingProgramsGridKey);
  const vipProgramsGrid = Common.Grid.Managed.mapGridState(
    program,
    ownProps.vipProgramsGridKey
  );

  return {
    programsListKey: ownProps.programsGridKey && programsGrid.key,
    programsTimestamp: ownProps.programsGridKey && programsGrid.timestamp,
    programsList: ownProps.programsGridKey && programsGrid.list,

    campaignProgramsListKey:
      ownProps.campaignProgramsGridKey && campaignProgramsGrid.key,
    campaignProgramsTimestamp:
      ownProps.campaignProgramsGridKey && campaignProgramsGrid.timestamp,
    campaignProgramsList:
      ownProps.campaignProgramsGridKey && campaignProgramsGrid.list,

    applicationsListKey: applicationsGrid.key,
    applicationsTimestamp: applicationsGrid.timestamp,
    applicationsList: applicationsGrid.list,

    campaignWaitlistApplicationsListKey:
      ownProps.campaignWaitlistApplicationsGridKey && campaignWaitlistApplicationsGrid.key,
    campaignWaitlistApplicationsTimestamp:
      ownProps.campaignWaitlistApplicationsGridKey && campaignWaitlistApplicationsGrid.timestamp,
    campaignWaitlistApplicationsList:
      ownProps.campaignWaitlistApplicationsGridKey && campaignWaitlistApplicationsGrid.list,

    pendingApprovalProgramsListKey:
      ownProps.pendingApprovalProgramsGridKey && pendingApprovalProgramsGrid.key,
    pendingApprovalProgramsTimestamp:
      ownProps.pendingApprovalProgramsGridKey && pendingApprovalProgramsGrid.timestamp,
    pendingApprovalProgramsList:
      ownProps.pendingApprovalProgramsGridKey && pendingApprovalProgramsGrid.list,

    campaignPendingProgramsNilRaisedListKey:
      ownProps.campaignPendingProgramsNilRaisedGridKey && campaignPendingProgramsNilRaisedGrid.key,
    campaignPendingProgramsNilRaisedTimestamp:
      ownProps.campaignPendingProgramsNilRaisedGridKey && campaignPendingProgramsNilRaisedGrid.timestamp,
    campaignPendingProgramsNilRaisedList:
      ownProps.campaignPendingProgramsNilRaisedGridKey && campaignPendingProgramsNilRaisedGrid.list,

    campaignPendingProgramsListKey:
      ownProps.campaignPendingProgramsGridKey && campaignPendingProgramsGrid.key,
    campaignPendingProgramsTimestamp:
      ownProps.campaignPendingProgramsGridKey && campaignPendingProgramsGrid.timestamp,
    campaignPendingProgramsList:
      ownProps.campaignPendingProgramsGridKey && campaignPendingProgramsGrid.list,

    vipProgramsListKey: vipProgramsGrid.key,
    vipProgramsTimestamp: vipProgramsGrid.timestamp,
    vipProgramsList: vipProgramsGrid.list,

    totalCountProgramsRecord: get(program, 'totalCountProgramsRecord'),
    totalCountApplicationsRecord: get(program, 'totalCountApplicationsRecord'),
    totalCountCampaignWaitlistApplicationsRecord: get(program, 'totalCountCampaignWaitlistApplicationsRecord'),
    totalCountPendingApprovalProgramsByReceivedStatusRecord: get(
      program,
      'totalCountPendingApprovalProgramsByReceivedStatusRecord'
    ),
    totalCountCampaignPendingProgramsNilRaisedByReceivedStatusRecord: get(
      program,
      'totalCountCampaignPendingProgramsNilRaisedByReceivedStatusRecord'
    ),
    totalCountCampaignPendingProgramsByReceivedStatusRecord: get(
      program,
      'totalCountCampaignPendingProgramsByReceivedStatusRecord'
    ),
    totalCountVIPProgramsByClaimedStatusRecord: get(
      program,
      'totalCountVIPProgramsByClaimedStatusRecord'
    ),
    totalCountVIPProgramsByAcceptedStatusRecord: get(
      program,
      'totalCountVIPProgramsByAcceptedStatusRecord'
    ),
    exportFileRecord,
    isExportDataAllowed: get(session, 'permissions.exportData') === 'allow'
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch, ownProps) => {
  const dispatchers = bindActionCreators(programActions, dispatch);

  if (
    !ownProps.isCampaignAdmin &&
    !ownProps.isOrganizationAdmin &&
    ownProps.programsGridKey === gridTabs.PROGRAMS
  ) {
    // Maps the required functions for the managed list to a prop.
    dispatchers.programsListActions = Common.Grid.Managed.bindGridActions(
      dispatch,
      {
        getMetadata: programActions.getProgramsMetadata,
        getListData: programActions.getProgramsListData,
        getListDataBySearchTerm: programActions.getProgramsListDataBySearchTerm,
        toggleColumnsChange: programActions.toggleColumnsChange
      }
    );
  }

  if (ownProps.programsGridKey === gridTabs.EVENT_PROGRAMS) {
    // // Maps the required functions for the managed list to a prop.
    dispatchers.programsListActions = Common.Grid.Managed.bindGridActions(
      dispatch,
      {
        getMetadata: programActions.getCampaignProgramsMetadata,
        getListData: programActions.getCampaignProgramsListData,
        getListDataBySearchTerm:
          programActions.getCampaignProgramsListDataBySearchTerm,
        toggleColumnsChange: programActions.toggleColumnsChange
      }
    );
  }

  if (
    (ownProps.isCampaignAdmin || ownProps.isOrganizationAdmin) &&
    ownProps.campaignProgramsGridKey === gridTabs.CAMPAIGN_PROGRAMS
  ) {
    // // Maps the required functions for the managed list to a prop.
    dispatchers.campaignProgramsListActions =
      Common.Grid.Managed.bindGridActions(dispatch, {
        getMetadata: programActions.getCampaignProgramsMetadata,
        getListData: programActions.getCampaignProgramsListData,
        getListDataBySearchTerm:
          programActions.getCampaignProgramsListDataBySearchTerm,
        toggleColumnsChange: programActions.toggleColumnsChange
      });
  }

  // Maps the required functions for the managed list to a prop.
  dispatchers.applicationsListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: programActions.getApplicationsMetadata,
      getListData: programActions.getApplicationsListData,
      getListDataBySearchTerm:
        programActions.getApplicationsListDataBySearchTerm,
      toggleColumnsChange: programActions.toggleColumnsChange
    }
  );

  if (ownProps.campaignWaitlistApplicationsGridKey === gridTabs.CAMPAIGN_WAITLIST_APPLICATIONS) {
    // Maps the required functions for the managed list to a prop.
    dispatchers.campaignWaitlistApplicationsListActions = Common.Grid.Managed.bindGridActions(
      dispatch,
      {
        getMetadata: programActions.getApplicationsMetadata,
        getListData: programActions.getApplicationsListData,
        getListDataBySearchTerm:
        programActions.getApplicationsListDataBySearchTerm,
        toggleColumnsChange: programActions.toggleColumnsChange
      }
    );
  }

  // Maps the required functions for the managed list to a prop.
  dispatchers.pendingApprovalProgramsListActions =
    Common.Grid.Managed.bindGridActions(dispatch, {
      getMetadata: programActions.getPendingApprovalProgramsMetadata,
      getListData: programActions.getPendingApprovalProgramsListData,
      getListDataBySearchTerm:
        programActions.getPendingApprovalProgramsListDataBySearchTerm,
      toggleColumnsChange: programActions.toggleColumnsChange
    });

  // Maps the required functions for the managed list to a prop.
  dispatchers.vipProgramsListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: programActions.getVIPsMetadata,
      getListData: programActions.getVIPsListData,
      getListDataBySearchTerm: programActions.getVIPsListDataBySearchTerm,
      toggleColumnsChange: programActions.toggleColumnsChange
    }
  );

  dispatchers.programActions = bindActionCreators(programActions, dispatch);

  return dispatchers;
};

/** Connects component to Redux store */
const ListsViewContainer = clear(
  withRouter(connect(mapState, mapDispatch)(ListsView))
);
export default ListsViewContainer;
