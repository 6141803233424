import React from 'react';

import { Translate } from 'react-redux-i18n';

import { enumToOptions } from '../../../../helpers/enumHelper';

import TemplateEditableUploadContainer from '../../../../components/template/templateEditableUpload';
import { TemplatePage } from '../../../../components/common/pager';
import { TemplateAutocomplete } from '../../../../components/template/templateAutocomplete';
import { TemplateCurrency } from '../../../../components/template/templateCurrency';
import { TemplateHeader } from '../../../../components/template/templateHeader';
import { TemplateTextArea } from '../../../../components/template/templateTextArea';
import { TemplateDropdown } from '../../../../components/template/templateDropdown';

import {
  entityTypes,
  imageFormats,
  imageTypes
} from '../../../../constants/imageUpload';
import { paths } from '../constants/template';
import config from '../../../../constants';

import { defaultAmbassadorStatus } from '../../constants';

export const getSetupTab = (props) => {
  const {
    onChange,
    required,
    target,
    optional,
    getEventAmbassadorProgramRequest,
    getEventAmbassadorProgramItem,
    getOrganizationRequest,
    onOrganizationChange,
    getOrganizationItem,
    onEventAmbassadorProgramChange,
    urlLink
  } = props;

  return (
    <TemplatePage id="setup" i18nKey="eventPrograms.template.page.setup.title">
      <TemplateHeader
        as="h2"
        path="edit-program-header"
        label="eventPrograms.template.page.setup.edit-program-content.header"
      />

      <div path={paths.EDIT_PROGRAM_SUBHEADER}>
        <span>
          <Translate value="eventPrograms.template.page.setup.edit-program-content.subheader" />
          <a target={urlLink} href={urlLink}>
            <Translate value="eventPrograms.template.page.setup.edit-program-content.link" />
          </a>
        </span>
      </div>

      <TemplateEditableUploadContainer
        id="setupProgramHeroImage"
        path={paths.BANNER}
        entity={entityTypes.EVENT}
        position="top"
        height="500"
        width="1500"
        format={imageFormats}
        uploadType={imageTypes.HERO_IMAGE}
        label="programs.template.page.setup.hero-image.label"
        onChange={onChange}
      />

      <TemplateTextArea
        id="setupVipProgramDescription"
        path={paths.INTRO_TEXT}
        label="eventPrograms.template.page.setup.intro-text.label"
        subtext="eventPrograms.template.page.setup.intro-text.subtext"
        validation={optional}
        onChange={onChange}
        maxLength={config.VIP_PROGRAM_INTRO_TEXT_MAX_LENGTH}
      />

      <TemplateAutocomplete
        id="vipProgram"
        entity="EventAmbassadorProgram"
        path={paths.EVENT_AMBASSADOR_PROGRAM_ID}
        selected="EventAmbassadorProgram"
        label="eventPrograms.template.page.setup.vip-program-name.label"
        onChange={onEventAmbassadorProgramChange}
        request={getEventAmbassadorProgramRequest}
        getItem={getEventAmbassadorProgramItem}
        validation={required}
      />

      <TemplateAutocomplete
        id="vipOrganization"
        entity="page"
        path={paths.ORGANIZATION_ID}
        selected="campaign"
        label="eventPrograms.template.page.setup.organization-name.label"
        onChange={onOrganizationChange}
        request={getOrganizationRequest}
        getItem={getOrganizationItem}
        validation={required}
      />

      <TemplateCurrency
        id="setupAvailableSeats"
        path={paths.VIP_PLACES_ALLOCATED}
        validation={target}
        hideInputLabel
        label="eventPrograms.template.page.setup.vip-places-allocated-name.label"
        onChange={onChange}
      />

      <TemplateDropdown
        path={paths.DEFAULT_AMBASSADOR_STATUS}
        label="eventPrograms.template.page.setup.default-ambassador-status.label"
        onChange={onChange}
        options={enumToOptions(
          defaultAmbassadorStatus,
          "eventPrograms.template.page.setup.default-ambassador-status"
        )}
        clearable
      />
    </TemplatePage>
  );
};
