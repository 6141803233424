// @ts-nocheck
import { RSAA } from 'redux-api-middleware';

import {
  UPDATE_REQUESTED,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  LIST_DATA_REQUESTED,
  LIST_DATA_SUCCESS,
  LIST_DATA_FAILURE,
} from './vipProgramTypes';
import constants from '../../../constants';

export const confirmRegistration = (
  record: { id: any, registrationId: string },
  refreshList?: boolean,
) => {
  return async (dispatch, getState) => {
    const state = getState();

    const response = await dispatch({
      [RSAA]: {
        endpoint: `${constants.baseApiHost}/api/v2/EventAmbassador/${record.id}/registration`,
        method: 'PUT',
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${state.session.key}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          isConfirmed: true,
          data: record.registrationId || null,
        }),
        types: [
          {
            type: UPDATE_REQUESTED,
            payload: async (action, state, res) => {
              return {
                key: 'editApplicationRecord'
              };
            }
          },
          {
            type: UPDATE_SUCCESS,
            payload: async (action, state, res) => {
              const json = await res.json();
              return {
                ...json,
                key: 'editApplicationRecord'
              };
            }
          },
          {
            type: UPDATE_FAILURE,
            payload: async (action, state, res) => {
              const json = await res.json();
              return {
                ...json,
                key: 'editApplicationRecord',
                status: res.status
              };
            }
          }
        ]
      }
    });

    if (refreshList && response.type === UPDATE_SUCCESS) {
      const request = state.program.campaignVipPrograms.request;
      dispatch({
        [RSAA]: {
          endpoint: `${constants.baseApiHost}/api/v3/EventAmbassador/search`,
          method: 'POST',
          headers: {
            ApiKey: constants.apikey,
            Authorization: `bearer ${state.session.key}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(request),
          types: [
            {
              type: LIST_DATA_REQUESTED,
              payload: (action, state) => ({
                id: undefined,
                key: 'campaignVipPrograms',
                request,
                invalidate: true,
                fetchRequired: true,
              })
            },
            {
              type: LIST_DATA_SUCCESS,
              payload: async (action, state, res) => {
                const payload = await res.json();
                return Object.assign({}, payload, { id: undefined, key: 'campaignVipPrograms' });
              }
            },
            {
              type: LIST_DATA_FAILURE,
              payload: async (action, state, res) => {
                const json = await res.json();
                return {
                  key: 'campaignVipPrograms',
                  status: res.status,
                  ...json
                };
              }
            }
          ]
        }
      });
    }
  };
}
