import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash.get';
import * as programActions from '../store/vipProgramActions';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import TemplateBuilderContainerEx from '../../../components/template/builder/indexEx';
import ApplicationPageActions from './components/applicationPageActions';
import { getPartOneViewTab } from './templateTabs/partOneView';
import { getPartOneEditTab } from './templateTabs/partOneEdit';
import { eventAmbassadorStatus, viewModes } from '../constants';

class ApplicationTemplateView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewMode: viewModes.VIEW
    };

    this.required = {
      required: true
    };

    this.optional = {
      required: false
    };

    this.email = {
      required: true,
      email: true
    };

    this.formBuilderAnswers = {
      formBuilderRequired: true,
      formBuilderAnswers: true
    };
  }

  onEditClick = () => {
    this.setState({
      viewMode: viewModes.EDIT
    });
  };

  onApplyClick = () => {
    this.setState({
      viewMode: viewModes.VIEW
    });
  };

  onChange = (path, value, item) => {
    this.props.setResultValue(path, value);
  };

  handleModalStateChange = (path, value) => {
    this.props.programActions.handleValueChange(path, value);
  };

  handleViewSignature = () => {
    if (!get(this.props.secureSignatureRecord.data, 'data')) {
      const signature = get(
        this.props.eventAmbassadorRecord,
        'data.data.agreement.signatureSecureUploadId'
      );

      this.props.programActions.getSecureSignature(signature);
    }
  };

  handleResetStatus = () => {
    const eventAmbassadorId = get(
      this.props.eventAmbassadorRecord,
      'data.data.id'
    );

    this.props.programActions.resetEventAmbassadorStatus(eventAmbassadorId);
  };

  handlePromoteToReceived = () => {
    const eventAmbassadorId = get(
      this.props.eventAmbassadorRecord,
      'data.data.id'
    );

    this.props.programActions.updateEventAmbassadorStatus(
      eventAmbassadorId,
      eventAmbassadorStatus.RECEIVED
    );
  };

  handleAccept = () => {
    const eventAmbassadorId = get(
      this.props.eventAmbassadorRecord,
      'data.data.id'
    );

    this.props.programActions.updateEventAmbassadorStatus(
      eventAmbassadorId,
      eventAmbassadorStatus.ACCEPTED
    );
  };

  handleReject = () => {
    const eventAmbassador = get(this.props.eventAmbassadorRecord, 'data.data');
    const disableAutomaticEmail = this.props.disableAutomaticEmail;

    if (eventAmbassador.status === eventAmbassadorStatus.CLAIMED) {
      this.props.programActions.updateEventAmbassadorStatus(
        eventAmbassador.id,
        eventAmbassadorStatus.RESCINDED,
        !disableAutomaticEmail
      );
    } else {
      this.props.programActions.updateEventAmbassadorStatus(
        eventAmbassador.id,
        eventAmbassadorStatus.REJECTED,
        !disableAutomaticEmail
      );
    }
  };

  applicationActionsComponent = () => {
    const eventAmbassadorId = get(
      this.props.eventAmbassadorRecord,
      'data.data.id'
    );

    const eventAmbassadorStatus = get(
      this.props.eventAmbassadorRecord,
      'data.data.status'
    );

    const registrationConfirmed = get(
      this.props.eventAmbassadorRecord,
      'data.data.externalRegistration.isConfirmed'
    );

    return (
      <ApplicationPageActions
        status={eventAmbassadorStatus}
        record={this.props.editEventAmbassadorStatusRecord}
        eventAmbassadorRecord={this.props.eventAmbassadorRecord}
        secureSignatureRecord={this.props.secureSignatureRecord}
        isResetStatusModalOpen={this.props.isResetStatusModalOpen}
        isPromoteToReceivedModalOpen={this.props.isPromoteToReceivedModalOpen}
        isAcceptModalOpen={this.props.isAcceptModalOpen}
        isRejectModalOpen={this.props.isRejectModalOpen}
        isDigitalSignatureModalOpen={this.props.isDigitalSignatureModalOpen}
        disableAutomaticEmail={this.props.disableAutomaticEmail}
        handleModalStateChange={this.handleModalStateChange}
        handleResetStatus={this.handleResetStatus}
        handlePromoteToReceived={this.handlePromoteToReceived}
        handleAccept={this.handleAccept}
        handleReject={this.handleReject}
        handleViewSignature={this.handleViewSignature}
        registrationConfirmed={registrationConfirmed}
        eventAmbassadorId={eventAmbassadorId}
        isSystemAdmin={this.props.isSystemAdmin}
      />
    );
  };

  isEditButtonDisabled = (status) => {
    return status === eventAmbassadorStatus.CLAIMED;
  };

  render() {
    const dateReceived = get(
      this.props.eventAmbassadorRecord,
      'data.data.createdAtLocal'
    );

    const fundraiserRaisedAmount = get(
      this.props.eventAmbassadorRecord,
      'data.data.fundraiser.raisedAmount'
    );
    const fundraiserUrlFull = get(
      this.props.eventAmbassadorRecord,
      'data.data.fundraiser.urlFull'
    );
    const fundraiserId = get(
      this.props.eventAmbassadorRecord,
      'data.data.fundraiser.id'
    );
    const fundraiserName = get(
      this.props.eventAmbassadorRecord,
      'data.data.fundraiser.name'
    );
    const eventAmbassadorId = get(
      this.props.eventAmbassadorRecord,
      'data.data.id'
    );

    const eventName = get(
      this.props.campaignAmbassadorProgramRecord,
      'data.data.event.name'
    );
    const eventAmbassadorProgramName = get(
      this.props.campaignAmbassadorProgramRecord,
      'data.data.eventAmbassadorProgram.name'
    );

    const eventAmbassadorStatus = get(
      this.props.eventAmbassadorRecord,
      'data.data.status'
    );
    const isEditButtonDisabled = this.isEditButtonDisabled(
      eventAmbassadorStatus
    );

    const pageActions = this.applicationActionsComponent();

    const partOneViewTab = getPartOneViewTab({
      required: this.required,
      email: this.email,
      formBuilderAnswers: this.formBuilderAnswers,
      dateReceived: dateReceived,
      fundraiserRaisedAmount: fundraiserRaisedAmount,
      fundraiserUrlFull: fundraiserUrlFull,
      fundraiserId,
      fundraiserName,
      eventName: eventName,
      eventAmbassadorId,
      eventAmbassadorProgramName: eventAmbassadorProgramName,
      onEditClick: this.onEditClick,
      isApplicationPartTwoVisible: this.props.isApplicationPartTwoVisible,
      isEditButtonDisabled: isEditButtonDisabled
    });
    const partOneEditTab = getPartOneEditTab({
      onChange: this.onChange,
      required: this.required,
      email: this.email,
      formBuilderAnswers: this.formBuilderAnswers,
      dateReceived: dateReceived,
      fundraiserRaisedAmount: fundraiserRaisedAmount,
      fundraiserUrlFull: fundraiserUrlFull,
      eventName: eventName,
      eventAmbassadorProgramName: eventAmbassadorProgramName,
      onApplyClick: this.onApplyClick,
      isApplicationPartTwoVisible: this.props.isApplicationPartTwoVisible
    });

    return (
      <TemplateBuilderContainerEx
        onSave={this.props.onSave}
        pageActions={pageActions}
        isLoading={this.props.isLoading}
        showCancelButton
        customCancelButtonLabel="Return to applications"
        onCancel={this.props.onCancel}
      >
        {this.state.viewMode === viewModes.VIEW && partOneViewTab}
        {this.state.viewMode === viewModes.EDIT && partOneEditTab}
      </TemplateBuilderContainerEx>
    );
  }
}

const mapState = ({ session, program }) => {
  return {
    eventAmbassadorRecord: program.eventAmbassadorRecord,
    campaignAmbassadorProgramRecord: program.campaignAmbassadorProgramRecord,
    editEventAmbassadorStatusRecord: program.editEventAmbassadorStatusRecord,
    secureSignatureRecord: program.secureSignatureRecord,
    isDigitalSignatureModalOpen: program.isDigitalSignatureModalOpen,
    isResetStatusModalOpen: program.isResetStatusModalOpen,
    isRejectModalOpen: program.applicationConfirmationModal.isRejectModalOpen,
    isPromoteToReceivedModalOpen: program.isPromoteToReceivedModalOpen,
    isAcceptModalOpen: program.applicationConfirmationModal.isAcceptModalOpen,
    disableAutomaticEmail:
      program.applicationConfirmationModal.disableAutomaticEmail,
    isSystemAdmin: session.isSystemAdmin,
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(templateBuilderActions, dispatch),
    programActions: bindActionCreators(programActions, dispatch)
  };
};

const ApplicationTemplate = connect(
  mapState,
  mapDispatch
)(ApplicationTemplateView);
export default ApplicationTemplate;
