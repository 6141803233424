import { get } from "lodash";
import { listFilterAggregate, listFilterOperator } from "../../../constants/aggregate";
import { templateKeys } from "../../../constants/templateKeys";
import { isArrayWithItems } from "../../../helpers/arrayHelper";
import { getDataCapture } from "../../../helpers/dataCaptureHelper";
import { giftMatchingConditionUpdater } from "../../../helpers/giftMatchingHelper";
import { getDefaultAboutElements } from "../../../helpers/pageAboutHelper";
import { getFullTimeObject } from "../../../helpers/templateHelper";
import { donationType, campaignStatuses } from "../constants";
import { campaignContract, campaignTypes } from "../create/templates";
import { ON_VALUE_CHANGE, LIST_ORDERING_CHANGE, TOGGLE_COLUMNS_CHANGE, GRID_TAB_CHANGE, TOTAL_AMOUNT_FUNDRAISERS_REQUESTED, TOTAL_AMOUNT_FUNDRAISERS_SUCCESS, TOTAL_AMOUNT_FUNDRAISERS_FAILURE, TOTAL_AMOUNT_TEAMS_REQUESTED, TOTAL_AMOUNT_TEAMS_SUCCESS, TOTAL_AMOUNT_TEAMS_FAILURE, TOTAL_AMOUNT_COLLECTIONS_REQUESTED, TOTAL_AMOUNT_COLLECTIONS_SUCCESS, TOTAL_AMOUNT_COLLECTIONS_FAILURE, CREATE_WINDOW_SET_VISIBLE_STATUS, TEMPLATE_CHANGE, ADD_CUSTOM_CAMPAIGN_OPTION, METADATA_REQUESTED, METADATA_SUCCESS, METADATA_FAILURE, LIST_DATA_REQUESTED, LIST_DATA_SUCCESS, LIST_DATA_FAILURE, CREATE_REQUESTED, CREATE_SUCCESS, CREATE_FAILURE, CLONE_REQUESTED, CLONE_SUCCESS, CLONE_FAILURE, UPDATE_CAMPAIGN_STATUS_REQUESTED, UPDATE_CAMPAIGN_STATUS_SUCCESS, UPDATE_CAMPAIGN_STATUS_FAILURE, GET_EXPORT_FILE_REQUESTED, GET_EXPORT_FILE_SUCCESS, GET_EXPORT_FILE_FAILURE, GET_REQUESTED, GET_SUCCESS, GET_FAILURE, CAMPAIGN_CLEAR, RECORD_CLEAR, ERROR_CLEAR } from "./campaignTypes";
import * as campaignEntityHandlers from '../../../modules/bin/campaignEntityHandlers';
import update from '../../../helpers/update';
import * as entityHandlers from '../../../modules/bin/entityHandlers';

export const onValueChange = (path: any, value: any) => {
    return {
        type: ON_VALUE_CHANGE,
        payload: {
            path,
            value
        }
    };
};

export const listOrderingChange = (listKey: any, ordering: any) => {
    return {
        type: LIST_ORDERING_CHANGE,
        payload: {
            value: ordering,
            listKey: listKey
        }
    };
};

export const toggleColumnsChange = (listKey: any, toggleColumns: any) =>
    entityHandlers.toggleColumnsChange(
        listKey,
        toggleColumns,
        TOGGLE_COLUMNS_CHANGE
    );

export const onGridTabChange = (gridTab: any) => {
    return {
        type: GRID_TAB_CHANGE,
        payload: gridTab
    };
};

export const getTotalAmountFundraisers = (campaignId: any) => {
    let aggregateUrl = `page/aggregate`
        // @ts-ignore
        .addFieldName('fundraiserId')
        .addFieldAggregate(listFilterAggregate.COUNT)
        .addFilter('campaignId', listFilterOperator.EQUAL_TO, campaignId, 0)
        .addFilter('type', listFilterOperator.EQUAL_TO, 'Fundraiser', 1);

    return entityHandlers.getAggregate(
        TOTAL_AMOUNT_FUNDRAISERS_REQUESTED,
        TOTAL_AMOUNT_FUNDRAISERS_SUCCESS,
        TOTAL_AMOUNT_FUNDRAISERS_FAILURE,
        aggregateUrl,
        'totalFundraisersRecord'
    );
};

export const getTotalAmountTeams = (campaignId: any) => {
    let aggregateUrl = `page/aggregate`
        // @ts-ignore  
        .addFieldName('teamId')
        .addFieldAggregate(listFilterAggregate.COUNT)
        .addFilter('campaignId', listFilterOperator.EQUAL_TO, campaignId, 0)
        .addFilter('type', listFilterOperator.EQUAL_TO, 'Team', 1);

    return entityHandlers.getAggregate(
        TOTAL_AMOUNT_TEAMS_REQUESTED,
        TOTAL_AMOUNT_TEAMS_SUCCESS,
        TOTAL_AMOUNT_TEAMS_FAILURE,
        aggregateUrl,
        'totalTeamsRecord'
    );
};

export const getTotalAmountCollections = (campaignId: any) => {
    let aggregateUrl = `page/aggregate`
        // @ts-ignore
        .addFieldName('collectionId')
        .addFieldAggregate(listFilterAggregate.COUNT)
        .addFilter('campaignId', listFilterOperator.EQUAL_TO, campaignId, 0)
        .addFilter('type', listFilterOperator.EQUAL_TO, 'Collection', 1);

    return entityHandlers.getAggregate(
        TOTAL_AMOUNT_COLLECTIONS_REQUESTED,
        TOTAL_AMOUNT_COLLECTIONS_SUCCESS,
        TOTAL_AMOUNT_COLLECTIONS_FAILURE,
        aggregateUrl,
        'totalCollectionsRecord'
    );
};

export const changeParentCreateWindowVisible = (value: any) => ({
    type: CREATE_WINDOW_SET_VISIBLE_STATUS,
    payload: { key: 'parentWindowVisible', value }
});

export const changeChildCreateWindowVisible = (value: any) => ({
    type: CREATE_WINDOW_SET_VISIBLE_STATUS,
    payload: { key: 'childWindowVisible', value }
});
export const selectParentTemplate = (value: any) => ({
    type: TEMPLATE_CHANGE,
    payload: { key: 'parentTemplate', value }
});
export const selectChildTemplate = (value: any) => ({
    type: TEMPLATE_CHANGE,
    payload: { key: 'childTemplate', value }
});

export const addCustomCampaignOption = () => ({
    type: ADD_CUSTOM_CAMPAIGN_OPTION
});

export const getMetadata = (listKey: any) =>
    entityHandlers.getMetadata(
        'page/campaign',
        listKey,
        METADATA_REQUESTED,
        METADATA_SUCCESS,
        METADATA_FAILURE
    );

export const getListData = (
    listKey: any,
    id: any,
    request: any,
    invalidate = false,
    cachedData?: undefined
) =>
    entityHandlers.getListData(
        'page/campaign',
        listKey,
        id,
        LIST_DATA_REQUESTED,
        LIST_DATA_SUCCESS,
        LIST_DATA_FAILURE,
        request,
        invalidate,
        cachedData
    );
export const getListDataBySearchTerm = (listKey: any, id: any, request: object, searchTerm: any) => {
    const newRequest = update(request, { text: { $set: searchTerm } });
    return getListData(listKey, id, newRequest, true);
};

export const getSaleMetadata = (listKey: any) =>
    entityHandlers.getMetadata(
        'sale',
        listKey,
        METADATA_REQUESTED,
        METADATA_SUCCESS,
        METADATA_FAILURE
    );
export const getSaleListData = (
    listKey: any,
    id: any,
    request: any,
    invalidate = false,
    cachedData?: undefined
) =>
    entityHandlers.getListData(
        'sale',
        listKey,
        id,
        LIST_DATA_REQUESTED,
        LIST_DATA_SUCCESS,
        LIST_DATA_FAILURE,
        request,
        invalidate,
        cachedData
    );

export const getSaleListDataBySearchTerm = (
    listKey: any,
    id: any,
    request: object,
    searchTerm: any
) => {
    const newRequest = update(request, { text: { $set: searchTerm } });
    return getSaleListData(listKey, id, newRequest, true);
};

export const getTicketMetadata = (listKey: any) =>
    entityHandlers.getMetadata(
        'saleDetail',
        listKey,
        METADATA_REQUESTED,
        METADATA_SUCCESS,
        METADATA_FAILURE
    );
export const getTicketListData = (
    listKey: any,
    id: any,
    request: any,
    invalidate = false,
    cachedData?: undefined
) =>
    entityHandlers.getListData(
        'saleDetail',
        listKey,
        id,
        LIST_DATA_REQUESTED,
        LIST_DATA_SUCCESS,
        LIST_DATA_FAILURE,
        request,
        invalidate,
        cachedData
    );

export const getTicketListDataBySearchTerm = (
    listKey: any,
    id: any,
    request: object,
    searchTerm: any
) => {
    const newRequest = update(request, { text: { $set: searchTerm } });
    return getTicketListData(listKey, id, newRequest, true);
};

export const getDonationMetadata = (listKey: any) =>
    entityHandlers.getMetadata(
        'donation',
        listKey,
        METADATA_REQUESTED,
        METADATA_SUCCESS,
        METADATA_FAILURE
    );
export const getDonationListData = (
    listKey: any,
    id: any,
    request: any,
    invalidate = false,
    cachedData?: undefined
) =>
    entityHandlers.getListData(
        'donation',
        listKey,
        id,
        LIST_DATA_REQUESTED,
        LIST_DATA_SUCCESS,
        LIST_DATA_FAILURE,
        request,
        invalidate,
        cachedData
    );
export const getDonationListDataBySearchTerm = (
    listKey: any,
    id: any,
    request: object,
    searchTerm: any
) => {
    const newRequest = update(request, { text: { $set: searchTerm } });
    return getDonationListData(listKey, id, newRequest, true);
};

export const getFundraiserMetadata = (listKey: any) =>
    entityHandlers.getMetadata(
        'page',
        listKey,
        METADATA_REQUESTED,
        METADATA_SUCCESS,
        METADATA_FAILURE
    );

export const getFundraiserListData = (
    listKey: any,
    id: any,
    request: any,
    invalidate = false,
    cachedData?: undefined
) =>
    entityHandlers.getListData(
        'page',
        listKey,
        id,
        LIST_DATA_REQUESTED,
        LIST_DATA_SUCCESS,
        LIST_DATA_FAILURE,
        request,
        invalidate,
        cachedData,
        'v3'
    );
export const getFundraiserListDataBySearchTerm = (
    listKey: any,
    id: any,
    request: object,
    searchTerm: any
) => {
    const newRequest = update(request, { text: { $set: searchTerm } });
    return getFundraiserListData(listKey, id, newRequest, true);
};

export const createRecord = (record: any) =>
    campaignEntityHandlers.createCampaignRecord(
        'page/campaign',
        CREATE_REQUESTED,
        CREATE_SUCCESS,
        CREATE_FAILURE,
        record,
        (state: any, data: any) => {
            const dataCapture = getDataCapture(data);
            const time = getFullTimeObject(data);

            const about = get(data, campaignContract.about);
            if (!about || !about.elements || about.elements.length === 0) {
                data = update.set(
                    data,
                    campaignContract.about,
                    getDefaultAboutElements()
                );
            }

            data = update.set(data, 'template.key', templateKeys.CAMPAIGN);
            data = update.set(data, 'dataCapture', dataCapture);

            const originalGiftMatching = get(data, 'giftMatching');

            if (isArrayWithItems(originalGiftMatching)) {
                let giftMatching = giftMatchingConditionUpdater(
                    originalGiftMatching,
                    'dateTime',
                    'startDateTime',
                    'greaterOrEqualThan'
                );
                giftMatching = giftMatchingConditionUpdater(
                    giftMatching,
                    'dateTime',
                    'endDateTime',
                    'lesserOrEqualThan'
                );

                data = update.set(data, 'giftMatching', giftMatching);
            }

            data = update.set(data, 'timeAndPlace.time', time ? time : null);
            data = update.set(
                data,
                'timeAndPlace.time.includesTime',
                time ? time.includesTime : null
            );

            data = update.unset(data, campaignContract.date);
            data = update.unset(data, campaignContract.time);
            data = update.unset(data, campaignContract.allowTimeAndPlace);
            data = update.unset(data, campaignContract.endDate);
            data = update.unset(data, campaignContract.endTime);

            const useAsCustom = get(
                data,
                'organization.template.value.customDomain.useAsCustomOrganization'
            );
            const customDomain = get(
                data,
                'organization.template.value.customDomain.domain'
            );

            if (data.type !== campaignTypes.event && useAsCustom && customDomain) {
                data = update.set(
                    data,
                    campaignContract.customURLStyle,
                    'campaignAsPath'
                );
                data = update.set(data, campaignContract.customUrlDomain, customDomain);
            }

            const allowedDonationTypes = get(
                data,
                campaignContract.allowedDonationTypes
            );
            if (allowedDonationTypes === donationType.onlyOnceOff) {
                data = update.set(
                    data,
                    campaignContract.disableDonationRecurringGiving,
                    true
                );
                data = update.set(
                    data,
                    campaignContract.disableDonationOnceOffGiving,
                    false
                );
            } else if (allowedDonationTypes === donationType.onlyRecurring) {
                data = update.set(
                    data,
                    campaignContract.disableDonationOnceOffGiving,
                    true
                );
                data = update.set(
                    data,
                    campaignContract.disableDonationRecurringGiving,
                    false
                );
            } else {
                data = update.set(
                    data,
                    campaignContract.disableDonationOnceOffGiving,
                    false
                );
                data = update.set(
                    data,
                    campaignContract.disableDonationRecurringGiving,
                    false
                );
            }
            data = update.unset(data, campaignContract.allowedDonationTypes);

            const enableSaleDonations = get(
                data,
                campaignContract.enableSaleDonations
            );
            data = update.set(
                data,
                campaignContract.disableOneClickSaleDonations,
                enableSaleDonations === false
            );
            data = update.unset(data, campaignContract.enableSaleDonations);

            const allowedSaleDonationTypes = get(
                data,
                campaignContract.allowedSaleDonationTypes
            );
            if (allowedSaleDonationTypes === donationType.onlyOnceOff) {
                data = update.set(
                    data,
                    campaignContract.disableSaleRecurringGiving,
                    true
                );
                data = update.set(
                    data,
                    campaignContract.disableSaleOnceOffGiving,
                    false
                );
            } else if (allowedSaleDonationTypes === donationType.onlyRecurring) {
                data = update.set(
                    data,
                    campaignContract.disableSaleOnceOffGiving,
                    true
                );
                data = update.set(
                    data,
                    campaignContract.disableSaleRecurringGiving,
                    false
                );
            } else {
                data = update.set(
                    data,
                    campaignContract.disableSaleOnceOffGiving,
                    false
                );
                data = update.set(
                    data,
                    campaignContract.disableSaleRecurringGiving,
                    false
                );
            }
            data = update.unset(data, campaignContract.allowedSaleDonationTypes);

            return update(data, {
                status: { $set: campaignStatuses.LIVE.description.toLowerCase() }
            });
        },
        'recordCreate'
    );

export const updateRecord = (data: { id: any; }) => {
    return campaignEntityHandlers.updateCampaignRecord(
        'page/campaign',
        CREATE_REQUESTED,
        CREATE_SUCCESS,
        CREATE_FAILURE,
        data.id,
        data,
        (state: any, data: { type: string; }) => {
            const dataCapture = getDataCapture(data);
            const time = getFullTimeObject(data);

            const about = get(data, campaignContract.about);
            if (!about || !about.elements || about.elements.length === 0) {
                data = update.set(
                    data,
                    campaignContract.about,
                    getDefaultAboutElements()
                );
            }

            const originalGiftMatching = get(data, 'giftMatching');

            if (isArrayWithItems(originalGiftMatching)) {
                let giftMatching = giftMatchingConditionUpdater(
                    originalGiftMatching,
                    'dateTime',
                    'startDateTime',
                    'greaterOrEqualThan'
                );
                giftMatching = giftMatchingConditionUpdater(
                    giftMatching,
                    'dateTime',
                    'endDateTime',
                    'lesserOrEqualThan'
                );

                data = update.set(data, 'giftMatching', giftMatching);
            }

            data = update.set(data, 'dataCapture', dataCapture);

            data = update.set(data, 'timeAndPlace.time', time ? time : null);
            data = update.set(
                data,
                'timeAndPlace.time.includesTime',
                time ? time.includesTime : null
            );

            data = update.unset(data, campaignContract.date);
            data = update.unset(data, campaignContract.time);
            data = update.unset(data, campaignContract.allowTimeAndPlace);
            data = update.unset(data, campaignContract.endDate);
            data = update.unset(data, campaignContract.endTime);

            const exemptCustomCampaign = get(
                data,
                'template.value.exemptCustomCampaign'
            );
            const useAsCustom = get(
                data,
                'organization.template.value.customDomain.useAsCustomOrganization'
            );
            const customDomain = get(
                data,
                'organization.template.value.customDomain.domain'
            );

            if (
                data.type !== campaignTypes.event &&
                useAsCustom &&
                customDomain &&
                !exemptCustomCampaign
            ) {
                data = update.set(
                    data,
                    campaignContract.customURLStyle,
                    'campaignAsPath'
                );
                data = update.set(data, campaignContract.customUrlDomain, customDomain);
            }

            const allowedDonationTypes = get(
                data,
                campaignContract.allowedDonationTypes
            );
            if (allowedDonationTypes === donationType.onlyOnceOff) {
                data = update.set(
                    data,
                    campaignContract.disableDonationRecurringGiving,
                    true
                );
                data = update.set(
                    data,
                    campaignContract.disableDonationOnceOffGiving,
                    false
                );
            } else if (allowedDonationTypes === donationType.onlyRecurring) {
                data = update.set(
                    data,
                    campaignContract.disableDonationOnceOffGiving,
                    true
                );
                data = update.set(
                    data,
                    campaignContract.disableDonationRecurringGiving,
                    false
                );
            } else {
                data = update.set(
                    data,
                    campaignContract.disableDonationOnceOffGiving,
                    false
                );
                data = update.set(
                    data,
                    campaignContract.disableDonationRecurringGiving,
                    false
                );
            }
            data = update.unset(data, campaignContract.allowedDonationTypes);

            const enableSaleDonations = get(
                data,
                campaignContract.enableSaleDonations
            );
            data = update.set(
                data,
                campaignContract.disableOneClickSaleDonations,
                enableSaleDonations === false
            );
            data = update.unset(data, campaignContract.enableSaleDonations);

            const allowedSaleDonationTypes = get(
                data,
                campaignContract.allowedSaleDonationTypes
            );
            if (allowedSaleDonationTypes === donationType.onlyOnceOff) {
                data = update.set(
                    data,
                    campaignContract.disableSaleRecurringGiving,
                    true
                );
                data = update.set(
                    data,
                    campaignContract.disableSaleOnceOffGiving,
                    false
                );
            } else if (allowedSaleDonationTypes === donationType.onlyRecurring) {
                data = update.set(
                    data,
                    campaignContract.disableSaleOnceOffGiving,
                    true
                );
                data = update.set(
                    data,
                    campaignContract.disableSaleRecurringGiving,
                    false
                );
            } else {
                data = update.set(
                    data,
                    campaignContract.disableSaleOnceOffGiving,
                    false
                );
                data = update.set(
                    data,
                    campaignContract.disableSaleRecurringGiving,
                    false
                );
            }
            data = update.unset(data, campaignContract.allowedSaleDonationTypes);

            return data;
        },
        'recordEdit'
    );
}

export const cloneRecord = (id: any, data: any, includeCampaignAdmins: boolean = false) =>
    entityHandlers.createRecord(
        `page/campaign/${id}/copy?includeCampaignAdmins=${includeCampaignAdmins}`,
        CLONE_REQUESTED,
        CLONE_SUCCESS,
        CLONE_FAILURE,
        data,
        (state: any, data: any) => {
            const time = getFullTimeObject(data);

            const allowTimeAndPlace = get(data, campaignContract.allowTimeAndPlace);

            if (allowTimeAndPlace !== undefined) {
                data = update.set(data, 'timeAndPlace.time', time ? time : null);
                data = update.set(
                    data,
                    'timeAndPlace.time.includesTime',
                    time ? time.includesTime : null
                );
            }

            const customClone = get(data, 'template.value.custom-clone') || {};

            if (customClone?.newUrl?.path && customClone?.newUrl?.enabled) {
                data = update.set(data, 'urlPath', customClone.newUrl.path);
            }

            if (customClone?.newOrg?.enabled && customClone?.newOrg?.organization) {
                const org = customClone.newOrg.organization;
                data = update.set(data, 'organizationPaymentPlatformId', org.paymentPlatforms[0].id);
                data = update.set(data, 'organizationId', org.id);
            }
            
            data = update.unset(data, campaignContract.date);
            data = update.unset(data, campaignContract.time);
            data = update.unset(data, 'template');

            return entityHandlers.getPatchModel(data);
        },
        'recordClone'
    );

export const updateCampaignStatus = (id: any, editableData: any) => {
    return entityHandlers.patchRecord(
        'page/campaign',
        UPDATE_CAMPAIGN_STATUS_REQUESTED,
        UPDATE_CAMPAIGN_STATUS_SUCCESS,
        UPDATE_CAMPAIGN_STATUS_FAILURE,
        id,
        editableData,
        'statusRecord'
    );
};

// export const getExportFile = (entity: any, request: any, fileName: string | undefined, version: string | undefined) => {
//     return entityHandlers.getExportFile(
//         entity,
//         GET_EXPORT_FILE_REQUESTED,
//         GET_EXPORT_FILE_SUCCESS,
//         GET_EXPORT_FILE_FAILURE,
//         request,
//         'exportFileRecord',
//         fileName,
//         version
//     );
// };

export const getRecord = (id: any) =>
    entityHandlers.getRecord(
        'page/campaign',
        GET_REQUESTED,
        GET_SUCCESS,
        GET_FAILURE,
        id
    );

export const getTicketsRecord = (request: any) =>
    entityHandlers.getSearchRecord(
        'ticket',
        GET_REQUESTED,
        GET_SUCCESS,
        GET_FAILURE,
        request,
        'ticketsRecord',
        'v2',
        true
    );

export const getCouponsRecord = (request: any) =>
    entityHandlers.getSearchRecord(
        'saleCoupon',
        GET_REQUESTED,
        GET_SUCCESS,
        GET_FAILURE,
        request,
        'couponsRecord'
    );

export const getFundraiserRecord = (id: any) =>
    entityHandlers.getRecord(
        'page/fundraiser',
        GET_REQUESTED,
        GET_SUCCESS,
        GET_FAILURE,
        id
    );

export const getTeamRecord = (id: any) =>
    entityHandlers.getRecord(
        'page/team',
        GET_REQUESTED,
        GET_SUCCESS,
        GET_FAILURE,
        id
    );

export const getCollectionRecord = (id: any) =>
    entityHandlers.getRecord(
        'page/collection',
        GET_REQUESTED,
        GET_SUCCESS,
        GET_FAILURE,
        id
    );

export const clear = () => ({ type: CAMPAIGN_CLEAR });

export const clearRecord = () => ({
    type: RECORD_CLEAR,
    payload: { key: 'record' }
});

export const clearRecordCreate = () => ({
    type: RECORD_CLEAR,
    payload: { key: 'recordCreate' }
});

export const clearRecordEdit = () => ({
    type: RECORD_CLEAR,
    payload: { key: 'recordEdit' }
});

export const clearRecordMessages = (record: any) => ({
    type: ERROR_CLEAR,
    payload: { key: record }
});

export const clearRecordCreateMessages = () => ({
    type: ERROR_CLEAR,
    payload: { key: 'recordCreate' }
});

export const clearRecordCloneMessages = () => ({
    type: ERROR_CLEAR,
    payload: { key: 'recordClone' }
});
