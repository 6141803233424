import React, { FunctionComponent, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Modal, Button } from 'semantic-ui-react';

import { TemplateInput } from '../../components/template/templateInput';

import { toggleConfirmRegistrationModal, confirmRegistration } from './store/vipProgramActions';

export const ConfirmRegistrationModal: FunctionComponent = () => {
  const open = useSelector((state: any) => state.program.confirmRegistrationModal.isModalOpen);
  const itemId = useSelector((state: any) => state.program.confirmRegistrationModal.itemId);
  const refreshList = useSelector((state: any) => state.program.confirmRegistrationModal.refreshList);

  const [registrationId, setRegistrationId] = useState("");

  const dispatch = useDispatch();

  return (
    <Modal
      className="wizard confirm-registration-modal"
      size="tiny"
      dimmer="inverted"
      open={open}
    >
      <Modal.Header>
        <Translate value="campaignVipPrograms.confirm-registration-modal.header" />
      </Modal.Header>
      <Modal.Content>
        <div style={{ textAlign: "center" }}>
          <Translate
            style={{ marginBottom: "1rem" }}
            value="campaignVipPrograms.confirm-registration-modal.content"
          />

          <TemplateInput
            value={registrationId}
            label="campaignVipPrograms.confirm-registration-modal.input-label"
            validation={{
              required: false,
              maxLength: true,
              length: 80,
            }}
            onChange={(_: any, value: any) => setRegistrationId(value)}
          />
        </div>
        <div
          style={{
            marginTop: "2rem",
            textAlign: "center",
          }}
        >
          <Button
            primary
            style={{
              width: 95,
            }}
            disabled={registrationId.length > 80}
            onClick={() => {
              dispatch(toggleConfirmRegistrationModal());
              dispatch(confirmRegistration(
                {
                  id: itemId,
                  registrationId,
                },
                refreshList,
              ));
              setRegistrationId("");
            }}
          >
            <Translate value="campaignVipPrograms.confirm-registration-modal.confirm" />
          </Button>
          <Button
            style={{
              width: 95,
            }}
            onClick={() => {
              dispatch(toggleConfirmRegistrationModal());
              setRegistrationId("");
            }}
          >
            <Translate value="campaignVipPrograms.confirm-registration-modal.cancel" />
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
