import React, { Component } from 'react';
import get from 'lodash.get';
import update from '../../../helpers/update';
import TemplateBuilderContainerEx from '../../../components/template/builder/indexEx';
// @ts-ignore
import { Form, Button } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import moment from "moment";
import { connect } from 'react-redux';
import {
  formBuilderKeys,
  ticketIssuingStyleOptions,
  admissionTranslationKeys,
  ticketIssuingStyle,
  simpleTicketingEnabledOptions,
  customUrlStyles,
  mandatoryOptions,
  ticketPurchaseFundraisingStyles,
  joiningExperienceOptions,
  campaignTypeOptions,
  invertionTextColourOptions,
  donationTypeOptions,
  saleDonationTypeOptions,
  raisedTotalTypeOptions,
  donationType,
  DONATION_VIDEO,
  defaultFitnessActivities,
  defaultFitnessTrackedStatistics,
  raisedTotalType,
} from '../constants';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import { TemplatePage } from '../../../components/common/pager';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateAutocomplete } from '../../../components/template/templateAutocomplete';
import { TemplateCurrency } from '../../../components/template/templateCurrency';
import TemplateEditableUpload from '../../../components/template/templateEditableUpload';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';
import { TemplateColorPicker } from '../../../components/template/templateColorPicker';
import { TemplateText } from '../../../components/template/templateText';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';
import { TemplateToogle } from '../../../components/template/templateToggle';
import TemplateFormBuilder from '../../../components/template/templateFromBuilder';
import { TemplateCustomMediaSelector } from '../../../components/template/custom/templateCustomMediaSelector';
import { TemplateCustomVideoSelector } from '../../../components/template/custom/templateCustomVideoSelector';
import { TemplateDefaultAmounts } from '../../../components/template/custom/templateDefaultAmounts';
import { withRouter } from 'react-router-dom';
import { campaignContract, campaignTypes } from './templates';
import { I18n } from 'react-redux-i18n';
import {
  imageTypes,
  entityTypes,
  iconFormats
} from '../../../constants/imageUpload';
import { TemplateAbout } from '../../../components/template/templateAbout';
import { getAboutValidation } from '../../../helpers/pageAboutHelper';
import { imageFormats } from '../../../constants/imageUpload';
import { TARGET_VALIDATION } from '../../../constants/validationModels';
import { getFromBuilderInitialData } from '../../../helpers/formBuilderHelper';
import TemplateTicketBuilder from '../../../components/template/templateTicketBuilder';
import { TemplateCouponBuilder } from '../../../components/template/custom/templateCouponBuilder';
import { AboutElementTypes } from '../../../components/common/aboutElements/aboutElementType';
import { TemplateWebTracking } from '../../../components/template/templateWebTracking';
import { TemplateRadioGroup } from '../../../components/template/templateRadioGroup';
import * as templateHelper from '../../../components/template/templateHelper';
import { getUrl } from '../../../modules/bin/utility';
import config from '../../../constants';
import { TagsInput } from '../../../components/tagInput';
import { TemplateDropdown } from '../../../components/template/templateDropdown';
import { TemplateTimePicker } from '../../../components/template/templateTimePicker';
import { TemplateDatePicker } from '../../../components/template/templateDatePicker';
import TemplateAboutElementsBuilder from '../../../components/template/templateAboutElementsBuilder';
import PaymentFeeCalculation from '../../../components/customComponents/paymentFeeCalculation';
import './template.css';
import { TemplateGiftMatchingBuilder } from '../../../components/template/custom/templateGiftMatchingBuilder';
import { TemplateTextArea } from '../../../components/template/templateTextArea';
import { getQRCodeImageUrl } from '../../../helpers/imageUrlHelper';
import CustomSection from '../../../components/campaign/advanced/custom-section';
import { TemplateSetupButtons } from '../../../components/template/custom/templateSetupButtonBuilder';
import {
  donationRecurringFrequencyOptions,
  installmentCountOptions
} from '../../recurring/constants';
import { FitnessTemplate } from './fitness/template';
import { FitnessContract, FitnessContractValues } from './fitness/constants';
import { TermsAndConditionSetupBuilder } from './TermAndConditionsBuilder';
import { MerchandiseBuilder } from './merchandise';
import { FundraisingResourcesBuilder } from './fundraising-resources/fundraising-resources';
import { paths } from '../../event/constants/template';
import { TemplateList } from './template-list';
import { WebTrackingTemplate } from '../../../components/webTracking/web-tracking';
import { InfoBox } from '../../../components/common/infoBox';
import { Multicurrency } from '../../../components/multicurrency';
import { AmountListInput } from '../../../components/customComponents/amountList';

interface CampaignTemplateViewProps {
  mvpFundraisersSettings: any;
  enableSaleDonations: boolean;
  simpleTicketingEnabled: boolean;
  customActivitiesValue1Unit: string;
  campaignName: any;
  ticketIssuingStyle: any;
  isLoading: any;
  newsletterPageTypes: any;
  customExtraFields: any;
  fitnessTargets: any;
  type: string;
  fitnessAllowedTrackedStatistics: null;
  fitnessAllowedTypes: null;
  fitnessEnabled: any;
  isSystemAdmin: any;
  purchaseFundraisingEnabled: boolean;
  fundraisersEnabled: any;
  templateBuilder: any;
  allowedSaleDonationTypes: any;
  generalSettings: any;
  onSave: (v?: any) => void;
  changeItemVisibility: (
    contractPath: string,
    arg1: boolean,
    arg2: boolean
  ) => void;
  showControl: (contractPath: string) => void;
  setResultValue: (oldV?: any, nValue?: any) => void;
}

class CampaignTemplateView extends Component {
  required: { required: boolean };
  optional: { required: boolean };
  customActivitiesLabelMaxLength: {
    required: boolean;
    maxLength: boolean;
    length: number;
  };
  requiredMaxLength: { required: boolean; maxLength: boolean; length: number };
  target: { amount: boolean; maxValue: number; required: boolean };
  defaultAmountsValidation: { defaultAmounts: boolean };
  defaultSetupButtonsValidation: { defaultSetupButtons: boolean };
  ticketBuilderValidation: { ticketBuilder: boolean };
  termsAndConditionSetupBuilderValidation: { termsAndConditionSetupTab: boolean };
  couponBuilderValidation: { couponBuilder: boolean };
  giftMatchingValidation: { giftMatchingBuilder: boolean };
  formBuilderQuestionsValidation: { formBuilderQuestions: boolean };
  aboutValidation: { required: boolean; validateAboutElements: boolean };
  urlValidation: { url: boolean; required: boolean };
  optionalUrlValidation: { url: boolean };
  domainValidation: { domainUrl: boolean; required: boolean };
  timeDetailsValidation: { maxLength: boolean; length: number };
  locationValidation: { maxLength: boolean; length: number };
  aboutBuilderValidation: { aboutBuilder: boolean };
  dateValidation: { date: boolean };
  photoPhormats: string[];
  iconFormats: string[];
  donationThankYouMessageMaxLength: number;
  props: CampaignTemplateViewProps;

  constructor(props: CampaignTemplateViewProps) {
    super(props);
    this.props = {
      ...props
    };

    this.required = {
      required: true
    };

    this.optional = {
      required: false
    };

    this.customActivitiesLabelMaxLength = {
      required: true,
      maxLength: true,
      length: config.CUSTOM_ACTIVITIES_LABEL_LENGTH
    };

    this.requiredMaxLength = {
      required: true,
      maxLength: true,
      length: config.URL_PATH_MAX_LENGTH
    };

    this.target = {
      required: false,
      ...TARGET_VALIDATION
    };

    this.defaultAmountsValidation = {
      defaultAmounts: true
    };

    this.defaultSetupButtonsValidation = {
      defaultSetupButtons: true
    };

    this.ticketBuilderValidation = {
      ticketBuilder: true
    };

    this.termsAndConditionSetupBuilderValidation =  {
      termsAndConditionSetupTab: true
    }

    this.couponBuilderValidation = {
      couponBuilder: true
    };

    this.giftMatchingValidation = {
      giftMatchingBuilder: true
    };

    this.formBuilderQuestionsValidation = {
      formBuilderQuestions: true
    };

    this.aboutValidation = getAboutValidation();

    this.urlValidation = {
      url: true,
      required: true
    };

    this.optionalUrlValidation = {
      url: true
    };

    this.domainValidation = {
      domainUrl: true,
      required: true
    };

    this.timeDetailsValidation = {
      maxLength: true,
      length: config.TIME_DETAILS_MAX_LENGTH
    };

    this.locationValidation = {
      maxLength: true,
      length: config.LOCATION_MAX_LENGTH
    };

    this.aboutBuilderValidation = {
      aboutBuilder: true
    };

    this.dateValidation = {
      date: true
    };

    this.photoPhormats = imageFormats;

    this.iconFormats = iconFormats;

    this.state = {
      organization: {
        name: ''
      }
    };

    this.donationThankYouMessageMaxLength = 280;
  }

  getMaxWithStyle = (width: string) => {
    return {
      width
    }
  };

  getOrganizationRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [],
      includeDataTemplate: true
    };
  };

  onOrganizationChange = (
    path: any,
    value: any,
    item: { paymentPlatforms: string | any[] }
  ) => {
    const orgDefaultAmounts = get(item, 'template.value.donationSetup.defaultAmounts');
    const orgPrimaryColor = get(item, 'template.value.primaryColor');
    const orgSecondaryColor = get(item, 'template.value.secondaryColor');
    const orgMission = get(item, 'template.value.missionStatement');
    const orgLogo = get(item, 'mainImagePath');
    const organizationName = get(item, 'name');

    if (orgDefaultAmounts && (orgDefaultAmounts as []).length > 0) {
      this.props.setResultValue(
        campaignContract.defaultAmounts,
        orgDefaultAmounts
      );
    }

    this.props.setResultValue(
      campaignContract.primaryColor,
      orgPrimaryColor || '#FF0000'
    );

    this.props.setResultValue(
      campaignContract.secondaryColor,
      orgSecondaryColor || '#0000FF'
    );

    let campaignAbout = get(this.props, 'templateBuilder.template.value.about');
    let aboutElementIndex = campaignAbout.elements.findIndex(
      (element: { type: string }) => element.type === AboutElementTypes.Text
    );

    campaignAbout = update(campaignAbout, {
      elements: {
        [aboutElementIndex]: {
          value: { $set: orgMission }
        }
      }
    });

    this.props.setResultValue(campaignContract.about, campaignAbout);
    this.props.setResultValue(campaignContract.mainImage, orgLogo);

    this.setDonationText(item);
    this.setThankYouMessage(item);

    if (item && item.paymentPlatforms && item.paymentPlatforms.length !== 0) {
      this.props.setResultValue(
        'organizationPaymentPlatformId',
        item.paymentPlatforms[0].id
      );
    }

    this.props.setResultValue(
      'organization.country',
      get(item, 'address.country')
    );

    this.props.setResultValue('organization.currency', get(item, 'currency'));

    this.props.setResultValue(
      'organization.template.value.customDomain',
      get(item, 'template.value.customDomain')
    );

    this.props.setResultValue('organization.timezone', get(item, 'timezone'));

    this.setState({
      organization: {
        name: organizationName
      }
    });

    this.props.setResultValue(path, value);
  };

  onCampaignChange = (path: any, value: any, _item: any) => {
    this.setSignUpText(value);
    this.setIntroductoryText(value);
    this.setUrlPath(value);
    this.onChange(path, value);
  };

  onCustomUrlCheckBoxChange = (path: any, value: any) => {
    this.componentEnabledChange(!value, campaignContract.customUrlPath);
    this.onChange(path, value);
    if (value) {
      const campaignName = get(
        this.props,
        `templateBuilder.${campaignContract.name}`
      );
      const newUrlPath = getUrl(campaignName);
      this.props.setResultValue(campaignContract.customUrlPath, newUrlPath);
    }
  };

  setDonationText = (item: any) => {
    const organizationName = get(item, 'name');

    const donationText = get(
      this.props,
      `templateBuilder.${campaignContract.donatinPageText}`
    );
    const donationTextI18key =
      'template.page.content.donation.donation-page-text.default-text';
    if (
      !donationText ||
      donationText ===
        I18n.t(donationTextI18key, {
          // @ts-ignore
          organizationName: this.state.organization.name
        })
    ) {
      const newDonationText = I18n.t(donationTextI18key, {
        organizationName: organizationName
      });
      this.props.setResultValue(
        campaignContract.donatinPageText,
        newDonationText
      );
    }
  };

  setThankYouMessage = (item: any) => {
    const organizationName = get(item, 'name');

    const thankYouMessage = get(
      this.props,
      `templateBuilder.${campaignContract.thankYouMessage}`
    );
    const thankYouMessageI18key =
      'template.page.content.donation.thank-you-message.default-text';
    if (
      !thankYouMessage ||
      thankYouMessage ===
        I18n.t(thankYouMessageI18key, {
          // @ts-ignore
          organizationName: this.state.organization.name
        })
    ) {
      const newThankYouMessage = I18n.t(thankYouMessageI18key, {
        organizationName: organizationName
      });
      this.props.setResultValue(
        campaignContract.thankYouMessage,
        newThankYouMessage
      );
    }
  };

  setSignUpText = (value: any) => {
    const signUpText = get(
      this.props,
      `templateBuilder.${campaignContract.registrationPageText}`
    );
    const signUpI18key =
      'template.page.content.fundraising.data-capture-text.default-text';
    if (
      !signUpText ||
      signUpText ===
        I18n.t(signUpI18key, { campaignName: this.props.templateBuilder.name })
    ) {
      const newSignUpText = I18n.t(signUpI18key, { campaignName: value });
      this.props.setResultValue(
        campaignContract.registrationPageText,
        newSignUpText
      );
    }
  };

  setIntroductoryText = (value: any) => {
    const introductoryText = get(
      this.props,
      `templateBuilder.${campaignContract.ticketingIntroductoryText}`
    );
    const introductoryI18Key =
      'template.page.content.ticketing.other-info.introductory.default-text';
    if (
      !introductoryText ||
      introductoryText ===
        I18n.t(introductoryI18Key, {
          campaignName: this.props.templateBuilder.name
        })
    ) {
      const newIntroductoryText = I18n.t(introductoryI18Key, {
        campaignName: value
      });
      this.props.setResultValue(
        campaignContract.ticketingIntroductoryText,
        newIntroductoryText
      );
    }
  };

  hasTallySettings = () => {
    const settings = this.props.generalSettings;
    const invalidCampaignTypes = [campaignTypes.event, campaignTypes.giveNow];
    const acceptableValues = [null, raisedTotalType.donationAndSales, raisedTotalType.donations, raisedTotalType.sales];

    if (invalidCampaignTypes.includes(this.props.type?.toLowerCase())) {
        return false;
    }

    return acceptableValues.includes(settings.raisedTotalType);
  }

  getTallySettingOptions = () => {
    const titlePath = 'template.page.content.advanced.tally-settings.options';
    return [
      { text: I18n.t(`${titlePath}.default`), value: raisedTotalType.donationAndSales },
      { text: I18n.t(`${titlePath}.aggregate-donations`), value: raisedTotalType.donations },
      { text: I18n.t(`${titlePath}.aggregate-sales`), value: raisedTotalType.sales }
    ];
  }

  setUrlPath = (value: any) => {
    const urlPathEnabled = get(
      this.props,
      `templateBuilder.${campaignContract.customUrlCheckboxPath}`
    );
    // explicitly check for false as it's a nullish object
    if (urlPathEnabled === false) {
      // if use campaign name as url checkbox un-ticked, don't update
      return;
    }
    const oldCampaignName = get(
      this.props,
      `templateBuilder.${campaignContract.name}`
    );
    const oldUrlPath = get(
      this.props,
      `templateBuilder.${campaignContract.customUrlPath}`
    );

    if (!oldUrlPath || oldUrlPath === getUrl(oldCampaignName)) {
      this.props.setResultValue(campaignContract.customUrlPath, getUrl(value));
    }
  };

  getOrganizationItem = (item: {
    paymentPlatforms: string | any[];
    name: any;
    urlPath: any;
    id: any;
  }, valueIsUrl?: boolean) => {
    if (item.paymentPlatforms && item.paymentPlatforms.length === 0) {
      return;
    }

    return {
      text: item.name,
      subtext: item.urlPath,
      value: valueIsUrl ? item.urlPath : item.id,
    };
  };

  componentEnabledChange = (value: any, contractPath: string) => {
    if (value) {
      this.props.showControl(contractPath);
    } else {
      this.props.changeItemVisibility(contractPath, false, false);
    }
  };

  changeFundraisingComponentVisibility = (fundraisingVisible: any) => {
    this.componentEnabledChange(fundraisingVisible, 'defaultAboutHeader');
    this.componentEnabledChange(
      fundraisingVisible,
      campaignContract.purchaseFundraisingEnabled
    );
    this.componentEnabledChange(
      fundraisingVisible,
      campaignContract.purchaseFundraisingIsMandatory
    );
    this.componentEnabledChange(
      fundraisingVisible,
      campaignContract.purchaseFundraisingStyle
    );
  };

  onFundraiserEnabledChange = (path: any, value: any) => {
    const teamsEnabled = get(
      this.props,
      `templateBuilder.${campaignContract.teamsEnable}`
    );
    const collectionsEnabled = get(
      this.props,
      `templateBuilder.${campaignContract.allowCollections}`
    );
    const fundraisingVisible = value || teamsEnabled;

    this.changeFundraisingAdvancedVisibility(value, teamsEnabled, collectionsEnabled);
    this.componentEnabledChange(value, campaignContract.defaultFundraiserAbout);
    this.changeFundraisingComponentVisibility(fundraisingVisible);

    this.onChange(path, value);
  };

  onTeamEnabledChange = (path: any, value: any) => {
    const fundraisersEnabled = get(
      this.props,
      `templateBuilder.${campaignContract.fundraisersEnable}`
    );
    const collectionsEnabled = get(
      this.props,
      `templateBuilder.${campaignContract.allowCollections}`
    );
    const fundraisingVisible = value || fundraisersEnabled;

    this.changeFundraisingAdvancedVisibility(fundraisersEnabled, value, collectionsEnabled);
    this.componentEnabledChange(value, campaignContract.defaultTeamAbout);
    this.changeFundraisingComponentVisibility(fundraisingVisible);

    this.onChange(path, value);
  };

  onFundraisingResourcesChange = (path: any, value: any) => {
    this.props.changeItemVisibility(
      campaignContract.fundraisersResourcesPage,
      value,
      false
    );
    this.onChange(path, value);
  };

  onChange = (
    path: string,
    value: string | number | boolean | any[] | null
  ) => {
    this.props.setResultValue(path, value);
  };

  getTargetValidation = (
    parentPath: string,
    childPath: string,
    contractPath: string
  ) => {
    const validationModel = [
      this.getChildValidation(contractPath, this.target)
    ];
    return this.getConditionValidation(parentPath, childPath, validationModel);
  };

  getConditionValidation = (
    parentPath: any,
    childPath: any,
    validationModel: { path: any; validation: any }[]
  ) => {
    const parentCondition = get(this.props, `templateBuilder.${parentPath}`);
    const childCondition = childPath
      ? get(this.props, `templateBuilder.${childPath}`)
      : true;
    return parentCondition && childCondition ? validationModel : [];
  };

  getChildValidation = (
    path: any,
    validation: {
      amount?: boolean;
      maxValue?: number;
      required?: boolean;
      date?: boolean;
      startDateRange?: boolean;
      endDateRange?: boolean;
      optionalParamPath?: any;
    }
  ) => {
    return {
      path,
      validation
    };
  };

  onSimpleTicketingEnabledChange = (path: any, value: any) => {
    this.changeTicketingConrolsVisibility(value);
    this.onChange(path, value);
  };

  changeTicketingConrolsVisibility = (simpleTicketingEnabled: any) => {
    const { purchaseFundraisingEnabled } = this.props;

    if (simpleTicketingEnabled) {
      this.changeExtendedTicketingControlsVisibility(false);
      this.props.showControl(campaignContract.purchaseTicketUrl);
      this.props.showControl(campaignContract.ticketsButtonText);
      this.changeTicketingFundraisingControlsVisibility(false);
    } else {
      this.props.changeItemVisibility(
        campaignContract.purchaseTicketUrl,
        false,
        false
      );
      this.changeExtendedTicketingControlsVisibility(true);
      if (purchaseFundraisingEnabled) {
        this.changeTicketingFundraisingControlsVisibility(true);
      }
    }
  };

  changeExtendedTicketingControlsVisibility = (isVisible: boolean) => {
    this.props.changeItemVisibility(
      campaignContract.ticketingThankYou,
      isVisible,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.ticketsButtonText,
      isVisible,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.ticketingIntroductoryText,
      isVisible,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.ticketsTitleText,
      isVisible,
      false
    );
    this.props.changeItemVisibility(campaignContract.tickets, isVisible, false);
    this.props.changeItemVisibility(
      campaignContract.ticketsInformation,
      isVisible,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.allowMerchandise,
      isVisible,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.ticketingInvoiceQuestions,
      isVisible,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.ticketIssuingStyle,
      isVisible,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.allowedSaleDonationTypes,
      isVisible,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.enableSaleDonations,
      isVisible,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.ticketingDefaultDonationAmounts,
      isVisible,
      true
    );
    this.props.changeItemVisibility(
      campaignContract.paymentFeeCalculation,
      isVisible,
      false
    );

    if (this.props.enableSaleDonations !== true) {
      this.props.changeItemVisibility(
        'recurring-sale-info-recurringAndOnceOff',
        false,
        false
      );
      this.props.changeItemVisibility(
        'recurring-sale-info-onlyOnceOff',
        false,
        false
      );
      this.props.changeItemVisibility(
        'recurring-sale-info-onlyRecurring',
        false,
        false
      );
      this.props.changeItemVisibility(
        campaignContract.saleRecurringLimit,
        false,
        true
      );
    } else {
      if (
        this.props.allowedSaleDonationTypes === donationType.recurringAndOnceOff
      ) {
        this.props.changeItemVisibility(
          'recurring-sale-info-recurringAndOnceOff',
          isVisible,
          false
        );
        this.props.changeItemVisibility(
          'recurring-sale-info-onlyOnceOff',
          false,
          false
        );
        this.props.changeItemVisibility(
          'recurring-sale-info-onlyRecurring',
          false,
          false
        );
        this.props.changeItemVisibility(
          campaignContract.saleRecurringLimit,
          isVisible,
          true
        );
      }
      if (this.props.allowedSaleDonationTypes === donationType.onlyOnceOff) {
        this.props.changeItemVisibility(
          'recurring-sale-info-recurringAndOnceOff',
          false,
          false
        );
        this.props.changeItemVisibility(
          'recurring-sale-info-onlyOnceOff',
          isVisible,
          false
        );
        this.props.changeItemVisibility(
          'recurring-sale-info-onlyRecurring',
          false,
          false
        );
        this.props.changeItemVisibility(
          campaignContract.saleRecurringLimit,
          false,
          true
        );
      }
      if (this.props.allowedSaleDonationTypes === donationType.onlyRecurring) {
        this.props.changeItemVisibility(
          'recurring-sale-info-recurringAndOnceOff',
          false,
          false
        );
        this.props.changeItemVisibility(
          'recurring-sale-info-onlyOnceOff',
          false,
          false
        );
        this.props.changeItemVisibility(
          'recurring-sale-info-onlyRecurring',
          isVisible,
          false
        );
        this.props.changeItemVisibility(
          campaignContract.saleRecurringLimit,
          isVisible,
          true
        );
      }
    }

    const { fundraisersEnabled } = this.props;
    if (fundraisersEnabled) {
      this.props.changeItemVisibility(
        campaignContract.purchaseFundraisingEnabled,
        isVisible,
        false
      );
    }
  };

  onAllowTicketingChange = (path: any, value: any) => {
    const { simpleTicketingEnabled, purchaseFundraisingEnabled } = this.props;

    if (value) {
      this.changeTicketingConrolsVisibility(simpleTicketingEnabled);
      this.props.showControl(campaignContract.simpleTicketingEnabled);
      this.props.showControl('discountsTab');

      this.props.showControl(campaignContract.simpleTicketingEnabled);

      // enable terms and waivers
      if (purchaseFundraisingEnabled)
        this.props.showControl(campaignContract.termsAndConditionSetupTab);

      if (!simpleTicketingEnabled && purchaseFundraisingEnabled) {
        this.changeTicketingFundraisingControlsVisibility(true);
      }
    } else {
      if (simpleTicketingEnabled) {
        this.props.changeItemVisibility(
          campaignContract.purchaseTicketUrl,
          false,
          false
        );
      } else {
        this.changeExtendedTicketingControlsVisibility(false);
      }
      this.changeTicketingFundraisingControlsVisibility(false);
      this.props.changeItemVisibility(
        campaignContract.simpleTicketingEnabled,
        false,
        false
      );
      this.props.changeItemVisibility(campaignContract.ticketsButtonText, false, false);
      this.props.changeItemVisibility('discountsTab', false, false);
    }

    this.onChange(path, value);
  };

  changeAllowMerchandise = () => {
    const allowTicketing = !!get(this.props, `templateBuilder.${campaignContract.allowTicketing}`, false);
    const campaignId = !!get(this.props, 'templateBuilder.id', null);
    const allowMerchandise = allowTicketing && campaignId && !this.props.simpleTicketingEnabled;
  
    this.props.changeItemVisibility(campaignContract.allowMerchandise, allowMerchandise, false);
    if (allowMerchandise) {
      const showMerchandiseTab = !!get(this.props, `templateBuilder.${campaignContract.allowMerchandise}`, false);
      this.props.changeItemVisibility(campaignContract.merchandiseTab, showMerchandiseTab, false);
    }
  }
  
  onAllowMerchandiseChange = (path: any, value: any) => {
    this.props.changeItemVisibility(campaignContract.merchandiseTab, value, false);
    this.props.changeItemVisibility(
      campaignContract.merchandise,
      value,
      false
    );
    this.onChange(path, value);
  }

  onAdmissionTypeChange = (path: any, value: any) => {
    this.onChange(path, value);
  }
  
  changeSingleUserRegistrationVisibility = () => {
    const allowTicketing = get(this.props, `templateBuilder.${campaignContract.allowTicketing}`);
    const ticketIssuingStyle = get(this.props, `templateBuilder.${campaignContract.ticketIssuingStyle}`);
    const toggleShowSingleUser = allowTicketing && ticketIssuingStyle === 'individualTicket' && !this.props.simpleTicketingEnabled;
    this.props.changeItemVisibility(campaignContract.singleUserRegistrationMode, toggleShowSingleUser, false);

    const singleUser = get(this.props, `templateBuilder.${campaignContract.singleUserRegistrationMode}`, false);
    const oneTicketTypePerSale = get(this.props, `templateBuilder.${campaignContract.oneTicketTypePerSale}`, false);
    const maxTicketsToSale = 1 === get(this.props, `templateBuilder.${campaignContract.maxTicketsToSale}`, 0);
    const allowtermsAndConditions = !!get(this.props, `templateBuilder.${campaignContract.allowtermsAndConditionSetupTab}`, false);

    this.props.changeItemVisibility(campaignContract.allowtermsAndConditionSetupTab, singleUser && toggleShowSingleUser, false);

     if (!oneTicketTypePerSale || !maxTicketsToSale) {
      this.onChange(campaignContract.singleUserRegistrationMode, false);
      this.changeWaivers(false);
    } else {
      this.changeWaivers(allowtermsAndConditions && singleUser && toggleShowSingleUser);
    }
  }

  onSingleUserRegistrationChange = (path: any, value: any) => {
    this.props.changeItemVisibility(campaignContract.allowtermsAndConditionSetupTab, value, false);
    if (!value) {
      this.onChange(campaignContract.oneTicketTypePerSale, false);
      this.onChange(campaignContract.maxTicketsToSale, null);
      this.changeWaivers(false);
    } else {
      this.onChange(campaignContract.oneTicketTypePerSale, true);
      this.onChange(campaignContract.maxTicketsToSale, 1);
    }
    this.onChange(path, value);
  }

  changeWaivers = (value: any) => {
    this.props.changeItemVisibility(campaignContract.termsAndConditionSetupTab, value, false);
    this.onChange(campaignContract.allowtermsAndConditionSetupTab, value);
    this.onChange(campaignContract.allowTermsAndConditions, value);
    this.onChange(campaignContract.allowWaivers, value);
  }

  onAllowWaiversChange = (path: any, value: any) => {
    this.changeWaivers(value);
  }

  onSaleDonationsChange = (path: any, value: any) => {
    this.changeSaleDonationsControlsVisibility(value);
    this.onChange(path, value);
  };

  changeSaleDonationsControlsVisibility = (saleDonationsEnabled: boolean) => {
    this.props.changeItemVisibility(
      campaignContract.allowedSaleDonationTypes,
      saleDonationsEnabled,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.ticketingDefaultDonationAmounts,
      saleDonationsEnabled,
      false
    );
    if (saleDonationsEnabled === true)
      this.changeSaleRecurringVisibility(this.props.allowedSaleDonationTypes);
    else {
      this.props.changeItemVisibility(
        'recurring-sale-info-recurringAndOnceOff',
        false,
        false
      );
      this.props.changeItemVisibility(
        'recurring-sale-info-onlyOnceOff',
        false,
        false
      );
      this.props.changeItemVisibility(
        'recurring-sale-info-onlyRecurring',
        false,
        false
      );
      this.props.changeItemVisibility(
        campaignContract.saleRecurringLimit,
        false,
        false
      );
    }
  };

  onAllowTicketingFundraisingChange = (path: any, value: any) => {
    this.changeTicketingFundraisingControlsVisibility(value);
    this.onChange(path, value);
  };

  changeTicketingFundraisingControlsVisibility = (
    ticketingFundraisingEnabled: boolean
  ) => {
    // terms and waivers
    if (ticketingFundraisingEnabled) {
      this.props.showControl(campaignContract.termsAndConditionSetupTab);
    } else {
      this.props.changeItemVisibility(
        campaignContract.termsAndConditionSetupTab,
        false,
        false
      )
    }

    const fundraisersEnabled = this.props.fundraisersEnabled;
    if (ticketingFundraisingEnabled && fundraisersEnabled) {
      this.props.showControl(campaignContract.allowTeamStep);
      this.props.showControl(campaignContract.purchaseFundraisingIsMandatory);
      this.props.showControl(campaignContract.purchaseFundraisingStyle);
    } else {
      this.props.changeItemVisibility(
        campaignContract.allowTeamStep,
        false,
        false
      );
      this.props.changeItemVisibility(
        campaignContract.purchaseFundraisingIsMandatory,
        false,
        false
      );
      this.props.changeItemVisibility(
        campaignContract.purchaseFundraisingStyle,
        false,
        false
      );
    }
  };

  onAllowCouponsChange = (path: any, value: any) => {
    if (value) {
      this.props.showControl(campaignContract.couponsInformation);
      this.props.showControl(campaignContract.coupons);
    } else {
      this.props.changeItemVisibility(
        campaignContract.couponsInformation,
        false,
        false
      );
      this.props.changeItemVisibility(campaignContract.coupons, false, false);
    }
    this.onChange(path, value);
  };

  onAllowCollectionsChange = (path: any, value: any) => {
    const fundraisersEnabled = get(
      this.props,
      `templateBuilder.${campaignContract.fundraisersEnable}`
    );
    const teamsEnabled = get(
      this.props,
      `templateBuilder.${campaignContract.teamsEnable}`
    );

    this.changeFundraisingAdvancedVisibility(fundraisersEnabled, teamsEnabled, value);

    this.props.changeItemVisibility(
      campaignContract.collectionsGeneralName,
      value,
      true
    );
    this.props.changeItemVisibility(
      campaignContract.allowCollectionDonations,
      value,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.allowCollectionSales,
      value,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.enableCollectionPageManager,
      value,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.joiningExperience,
      value,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.isCollectionRequired,
      this.props.isSystemAdmin && value,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.defaultCollectionAbout,
      value,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.disableCollectionsPublicPages,
      value,
      false
    );

    this.onChange(path, value);
  };

  onAllowRecurringGivingChange = (path: any, value: string) => {
    if (value === donationType.recurringAndOnceOff) {
      this.props.changeItemVisibility(
        'recurring-donation-info-recurringAndOnceOff',
        true,
        false
      );
      this.props.changeItemVisibility(
        'recurring-donation-info-onlyOnceOff',
        false,
        false
      );
      this.props.changeItemVisibility(
        'recurring-donation-info-onlyRecurring',
        false,
        false
      );
      this.props.changeItemVisibility(
        campaignContract.donationRecurringLimit,
        true,
        true
      );
    }

    if (value === donationType.onlyOnceOff) {
      this.props.changeItemVisibility(
        'recurring-donation-info-recurringAndOnceOff',
        false,
        false
      );
      this.props.changeItemVisibility(
        'recurring-donation-info-onlyOnceOff',
        true,
        false
      );
      this.props.changeItemVisibility(
        'recurring-donation-info-onlyRecurring',
        false,
        false
      );
      this.props.changeItemVisibility(
        campaignContract.donationRecurringLimit,
        false,
        true
      );
    }

    if (value === donationType.onlyRecurring) {
      this.props.changeItemVisibility(
        'recurring-donation-info-recurringAndOnceOff',
        false,
        false
      );
      this.props.changeItemVisibility(
        'recurring-donation-info-onlyOnceOff',
        false,
        false
      );
      this.props.changeItemVisibility(
        'recurring-donation-info-onlyRecurring',
        true,
        false
      );
      this.props.changeItemVisibility(
        campaignContract.donationRecurringLimit,
        true,
        true
      );
    }

    this.onChange(path, value);
  };

  onTicketInstallmentChanged = (path: string, value: any) => {
    this.props.changeItemVisibility(
      campaignContract.installmentCountOptions,
      value,
      true
    );
    this.props.changeItemVisibility(
      campaignContract.ticketInstallmentFrequency,
      value,
      true
    );
    this.props.changeItemVisibility(
      campaignContract.ticketInstallmentNewSalesUntil,
      value,
      true
    );
    this.onChange(path, value);
  };

  changeSaleRecurringVisibility = (value: string) => {
    if (value === donationType.recurringAndOnceOff) {
      this.props.changeItemVisibility(
        'recurring-sale-info-recurringAndOnceOff',
        true,
        false
      );
      this.props.changeItemVisibility(
        'recurring-sale-info-onlyOnceOff',
        false,
        false
      );
      this.props.changeItemVisibility(
        'recurring-sale-info-onlyRecurring',
        false,
        false
      );
      this.props.changeItemVisibility(
        campaignContract.saleRecurringLimit,
        true,
        true
      );
    }

    if (value === donationType.onlyOnceOff) {
      this.props.changeItemVisibility(
        'recurring-sale-info-recurringAndOnceOff',
        false,
        false
      );
      this.props.changeItemVisibility(
        'recurring-sale-info-onlyOnceOff',
        true,
        false
      );
      this.props.changeItemVisibility(
        'recurring-sale-info-onlyRecurring',
        false,
        false
      );
      this.props.changeItemVisibility(
        campaignContract.saleRecurringLimit,
        false,
        true
      );
    }

    if (value === donationType.onlyRecurring) {
      this.props.changeItemVisibility(
        'recurring-sale-info-recurringAndOnceOff',
        false,
        false
      );
      this.props.changeItemVisibility(
        'recurring-sale-info-onlyOnceOff',
        false,
        false
      );
      this.props.changeItemVisibility(
        'recurring-sale-info-onlyRecurring',
        true,
        false
      );
      this.props.changeItemVisibility(
        campaignContract.saleRecurringLimit,
        true,
        true
      );
    }
  };

  onAllowSaleRecurringGivingChange = (path: any, value: any) => {
    this.changeSaleRecurringVisibility(value);
    this.onChange(path, value);
  };

  onAllowTimeAndPlaceChange = (path: any, value: any) => {
    this.props.changeItemVisibility(campaignContract.dateAndTime, value, false);
    this.props.changeItemVisibility(campaignContract.timeDetails, value, true);
    this.props.changeItemVisibility(campaignContract.place, value, true);

    if (!value) {
      this.onChange(campaignContract.date, null);
      this.onChange(campaignContract.time, null);
      this.onChange(campaignContract.endDate, null);
      this.onChange(campaignContract.endTime, null);
    }

    this.onChange(path, value);
  };

  onSelectDefaultDonationAmounts = (path: any, value: any) => {
    // if value is an array with one value, get that value, otherwise null
    const newValue = value && value.length === 1 ? value[0] : null;
    this.onChange(campaignContract.ticketingDefaultSelectedDonationAmount, newValue);
    this.onChange(path, value);
  };

  getAboutWidget = () => {
    if (this.props.type !== campaignTypes.event) {
      return (
        <TemplateAboutElementsBuilder
          id="setupCampaignAbout"
          path={campaignContract.about}
          // label='template.page.content.setup.campaign-about.label'
          subtext="template.page.content.setup.campaign-story.subtext"
          onChange={this.onChange}
          entity={entityTypes.CAMPAIGN}
          validation={this.aboutBuilderValidation}
        />
      );
    } else {
      return (
        <TemplateAbout
          id="setupCampaignAbout"
          validation={this.aboutValidation}
          path={campaignContract.about}
          label="template.page.content.setup.campaign-about.label"
          subtext="template.page.content.setup.campaign-about.subtext"
          onChange={this.onChange}
          entity={entityTypes.CAMPAIGN}
        />
      );
    }
  };

  onAllowFitnessChange = (path: any, value: any) => {
    if (path !== FitnessContract.fitnessEnabled) return;

    const {
      fitnessEnabled,
      fitnessAllowedTypes,
      fitnessAllowedTrackedStatistics
    } = this.props;
    
    if (!fitnessEnabled) {
      if (fitnessAllowedTypes === null) {
        this.onChange(
          FitnessContract.fitnessAllowedTypes,
          defaultFitnessActivities
        );
      }
      if (fitnessAllowedTrackedStatistics === null) {
        this.onChange(
          FitnessContract.fitnessAllowedTrackedStatistics,
          defaultFitnessTrackedStatistics
        );
      }
    }

    FitnessContractValues.forEach(path => {
      if (path === FitnessContract.fitnessEnabled) return;
      
      this.props.changeItemVisibility(path, value, false)
    })

    this.onChange(FitnessContract.fitnessEnabled, value);
  };

  handleCustomExtraFieldChange = (path: any, value: any) => {
    let customExtraFields = this.props.customExtraFields;

    const fieldIndex = customExtraFields.findIndex(
      (x: { key: any }) => x.key === path
    );

    if (fieldIndex !== -1) {
      this.props.setResultValue(
        'template.value.customExtraFields',
        update(customExtraFields, {
          [fieldIndex]: {
            value: { $set: value }
          }
        })
      );
    }
  };

  getCustomExtraFieldValue = (path: string) => {
    const customExtraFields = this.props.customExtraFields;
    const fieldIndex = customExtraFields.findIndex(
      (x: { key: any }) => x.key === path
    );

    if (fieldIndex !== -1) {
      return customExtraFields[fieldIndex].value;
    }

    return '';
  };

  getNewsletterPageTypeValue = (page: string) => {
    return this.props.newsletterPageTypes.some((x: any) => x === page);
  };

  onNewsletterPageTypeChange = (page: any) => {
    let newsletterPageTypes = [...this.props.newsletterPageTypes];

    const index = newsletterPageTypes.findIndex((x) => x === page);
    if (index !== -1) {
      newsletterPageTypes.splice(index, 1);
    } else {
      newsletterPageTypes.push(page);
    }

    this.onChange(campaignContract.newsletterPageTypes, newsletterPageTypes);
  };

  onEnableCustomActivitiesChange = (path: any, value: any) => {
    this.props.changeItemVisibility(
      campaignContract.customActivitiesValue1Name,
      value,
      true
    );

    this.props.changeItemVisibility(
      campaignContract.customActivitiesValue1ThermometerEnabled,
      value,
      true
    );
    this.props.changeItemVisibility(
      campaignContract.customActivitiesValue1LeaderBoardEnabled,
      value,
      true
    );
    this.props.changeItemVisibility(
      campaignContract.customActivitiesValue1Unit,
      value,
      true
    );

    this.props.changeItemVisibility(
      campaignContract.customActivitiesValue1CampaignTargetField,
      false,
      true
    );
    this.props.changeItemVisibility(
      campaignContract.customActivitiesValue1FundraiserDefaultTargetField,
      false,
      true
    );
    this.props.changeItemVisibility(
      campaignContract.customActivitiesValue1TeamDefaultTargetField,
      false,
      true
    );
    this.props.changeItemVisibility(
      campaignContract.customActivitiesValue1Svg,
      false,
      true
    );

    this.onChange(path, value);
  };

  changeFundraisingAdvancedVisibility = (
    fundrasingEnabled: boolean,
    teamsEnabled: boolean,
    collectionsEnabled: boolean,
  ) => {
    const showAdvanced = this.props.type !== "event" && (fundrasingEnabled || teamsEnabled || collectionsEnabled);

    this.props.changeItemVisibility(
      campaignContract.allowFundraisingTimeAndPlace,
      showAdvanced,
      true
    );
  };

  setInitialFundraisingAdvancedVisibility = () => {
    const fundraisersEnabled = get(
      this.props,
      `templateBuilder.${campaignContract.fundraisersEnable}`
    );
    const teamsEnabled = get(
      this.props,
      `templateBuilder.${campaignContract.teamsEnable}`
    );
    const collectionsEnabled = get(
      this.props,
      `templateBuilder.${campaignContract.allowCollections}`
    );
    this.changeFundraisingAdvancedVisibility(fundraisersEnabled, teamsEnabled, collectionsEnabled);
  };

  onEnableCustomActivitiesThermometerChange = (path: any, value: any) => {
    this.props.changeItemVisibility(
      campaignContract.customActivitiesValue1CampaignTargetField,
      value,
      true
    );
    this.props.changeItemVisibility(
      campaignContract.customActivitiesValue1FundraiserDefaultTargetField,
      value,
      true
    );
    this.props.changeItemVisibility(
      campaignContract.customActivitiesValue1TeamDefaultTargetField,
      value,
      true
    );
    this.props.changeItemVisibility(
      campaignContract.customActivitiesValue1Svg,
      value,
      true
    );

    this.onChange(path, value);
  };

  onDisplayOrgLogoInQRCodesChange = (path: any, value: any) => {
    this.onChange(campaignContract.unbrandedQrCode, !value);
    this.onChange(path, value);
  };

  onViewBrandedClick = () => {
    const campaignUrlFull = get(this.props, 'campaignUrlFull');
    const organizationLogo = get(this.props, 'organizationLogo');

    window.open(getQRCodeImageUrl(campaignUrlFull, organizationLogo), '_blank');
  };

  onViewStardardClick = () => {
    const campaignUrlFull = get(this.props, 'campaignUrlFull');

    window.open(getQRCodeImageUrl(campaignUrlFull), '_blank');
  };

  render() {
    const aboutWidget = this.getAboutWidget();
    const saleDefaultDonationAmounts = get(this.props.templateBuilder, 'template.value.customSetup.ticketingDefaultDonationAmounts', []);
    const hasSaleDefaultDonationAmounts = Array.isArray(saleDefaultDonationAmounts) ? saleDefaultDonationAmounts.length >= 1 : !!saleDefaultDonationAmounts;
    const saleDonationLabelKey = hasSaleDefaultDonationAmounts ? 
      "template.page.content.ticketing.donation-info.enableSaleDonationsCustomAmounts"
      : "template.page.content.ticketing.donation-info.enableSaleDonations";
    const currency = get(this.props.templateBuilder, 'organization.currency');

    this.setInitialFundraisingAdvancedVisibility();
    this.changeAllowMerchandise();
    this.changeSingleUserRegistrationVisibility();

    return (
      <TemplateBuilderContainerEx
        // @ts-ignore
        onSave={this.props.onSave}
        isLoading={this.props.isLoading}
      >
        <TemplatePage id="setup" path="setup" i18nKey="template.page.setup">
          <div className="template-heading">
            <TemplateHeader
              as="h3"
              label="template.page.content.setup.header"
            />

            <TemplateText
              label="template.page.content.setup.subheader"
              size="small"
            />
          </div>

          <TemplateAutocomplete
            id="setupOrganization"
            entity="organization"
            path={campaignContract.organization}
            selected="organization"
            label="template.page.content.setup.organization.label"
            placeholder="template.page.content.setup.organization.placeholder"
            validation={this.required}
            onChange={this.onOrganizationChange}
            request={this.getOrganizationRequest}
            getItem={this.getOrganizationItem}
          />

          <TemplateInput
            id="setupCampaignName"
            path={campaignContract.name}
            label="template.page.content.setup.campaign-name.label"
            subtext="template.page.content.setup.campaign-name.subtext"
            placeholder="template.page.content.setup.campaign-name.label"
            validation={this.requiredMaxLength}
            onChange={this.onCampaignChange}
          />

          <TemplateRadioGroup
            id="customUrlStyle"
            path={campaignContract.customURLStyle}
            onChange={this.onChange}
            options={customUrlStyles}
          />

          <TemplateInput
            id={campaignContract.customUrlDomain}
            path={campaignContract.customUrlDomain}
            label="campaign.customUrl.domain.label"
            subtext="campaign.customUrl.domain.subtext"
            subtextPosition="bottom"
            validation={this.domainValidation}
            onChange={this.onChange}
          />

          <TemplateCheckbox
            id={campaignContract.customUrlCheckbox}
            path={campaignContract.customUrlCheckboxPath}
            inputLabel="campaign.customUrl.enabled.label"
            onChange={this.onCustomUrlCheckBoxChange}
          />

          <TemplateInput
            id={campaignContract.customUrlPath}
            path={campaignContract.customUrlPath}
            label="campaign.customUrl.path.label"
            subtext="campaign.customUrl.path.subtext"
            subtextPosition="bottom"
            validation={this.requiredMaxLength}
            onChange={this.onChange}
          />

          <TemplateCurrency
            id="setupFundraisingTarget"
            path={campaignContract.targetAmount}
            validation={this.target}
            label="template.page.content.setup.fundraising-target.label"
            subtext="template.page.content.setup.fundraising-target.subtext"
            onChange={this.onChange}
          />

          {this.props.isSystemAdmin && this.props.type !== campaignTypes.event && (
              <TemplateCurrency
                id="additionalFee"
                path={campaignContract.additionalEventFee}
                inputLabel={'%'}
                maxValue={config.MAX_PERCENT_VALUE}
                allowDecimal
                label="template.page.content.setup.additional-fee.label"
                onChange={this.onChange}
              />
          )}

          <TemplateHorizontalRule />

          <TemplateHeader
            as="h3"
            label="template.page.content.setup.campaign-brand.label"
          />

          <TemplateInput
            id="setupTagline"
            path={campaignContract.tagline}
            label="template.page.content.setup.campaign-tagline.label"
            onChange={this.onChange}
          />

          <TemplateEditableUpload
            id="setupCampaignPhoto"
            path={campaignContract.mainImage}
            entity={entityTypes.CAMPAIGN}
            position="left"
            height="350"
            width="350"
            format={this.photoPhormats}
            uploadType={imageTypes.LOGO}
            label="template.page.content.setup.campaign-photo.label"
            subtext="template.page.content.setup.campaign-photo.subtext"
            validation={this.required}
            onChange={this.onChange}
          />

          <Form.Group widths="equal">
            <TemplateColorPicker
              id="setupPrimaryColour"
              path={campaignContract.primaryColor}
              label="template.page.content.setup.colour-primary.label"
              subtext="template.page.content.setup.colour-primary.subtext"
              onChange={this.onChange}
            />

            <TemplateColorPicker
              id="setupSecondaryColour"
              path={campaignContract.secondaryColor}
              label="template.page.content.setup.colour-secondary.label"
              subtext="template.page.content.setup.colour-secondary.subtext"
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <TemplateDropdown
              id={campaignContract.invertPrimaryTextColor}
              path={campaignContract.invertPrimaryTextColor}
              label="template.page.content.setup.invert-colors.invertPrimaryTextColor.label"
              onChange={this.onChange}
              options={invertionTextColourOptions}
            />

            <TemplateDropdown
              id={campaignContract.invertSecondaryTextColor}
              path={campaignContract.invertSecondaryTextColor}
              label="template.page.content.setup.invert-colors.invertSecondaryTextColor.label"
              onChange={this.onChange}
              options={invertionTextColourOptions}
            />
          </Form.Group>

          <TemplateHorizontalRule />

          <TemplateHeader
            as="h3"
            path={campaignContract.heroImage}
            label="template.page.content.setup.hero-video-and-image.header"
          />

          <div className="input-sub">
            {I18n.t('template.page.content.setup.hero-video-and-image.subtext')}
          </div>
          <TemplateHeader
            as="h5"
            path={campaignContract.heroImage}
            label="template.page.content.setup.hero-video-and-image.detail"
          />

          <TemplateEditableUpload
            id="setupHeroImage"
            path={campaignContract.heroImage}
            entity={entityTypes.CAMPAIGN}
            position="top"
            height="350"
            width="1500"
            format={this.photoPhormats}
            uploadType={imageTypes.HERO_IMAGE}
            label="template.page.content.setup.hero-image.label"
            validation={this.required}
            onChange={this.onChange}
          />

          <TemplateCustomVideoSelector
            id="heroVideo"
            label="Hero video URL"
            onChange={this.onChange}
            path={campaignContract.heroVideo}
            validation={this.optionalUrlValidation}
          />

          <TemplateHorizontalRule path={campaignContract.about} />
          <div className="template-heading" path={campaignContract.about}>
            <TemplateHeader
              as="h3"
              label="template.page.content.setup.campaign-story.label"
            />
          </div>
          {aboutWidget}
          <TemplateHorizontalRule />

          <TemplateHeader
            as="h3"
            path={campaignContract.timeAndPlaceHeader}
            label="template.page.content.setup.time-and-place.header"
          />

          <TemplateCheckbox
            id="fundraisersFundraising"
            path={campaignContract.allowTimeAndPlace}
            inputLabel="template.page.content.setup.time-and-place.checkbox.label"
            onChange={this.onAllowTimeAndPlaceChange}
          />

          <Form.Group
            widths="equal"
            path={campaignContract.dateAndTime}
            validation={[
              this.getChildValidation(campaignContract.date, {
                startDateRange: true,
                optionalParamPath: campaignContract.endDate
              })
            ]}
          >
            <TemplateDatePicker
              id="setupDate123"
              path={campaignContract.date}
              label="template.page.content.setup.time-and-place.date.label"
              subtext="template.page.content.setup.time-and-place.date.subtext"
              onChange={this.onChange}
              onClear={() => this.onChange(campaignContract.time, null)}
              validation={{
                startDateRange: true,
                optionalParamPath: campaignContract.endDate
              }}
            />

            <TemplateTimePicker
              id="setupTime"
              path={campaignContract.time}
              label="template.page.content.setup.time-and-place.time.label"
              subtext="template.page.content.setup.time-and-place.time.subtext"
              onChange={this.onChange}
            />
          </Form.Group>

          <Form.Group widths="equal" path={campaignContract.dateAndTime}>
            <TemplateDatePicker
              id="setupDate"
              path={campaignContract.endDate}
              label="template.page.content.setup.time-and-place.endDate.label"
              subtext="template.page.content.setup.time-and-place.endDate.subtext"
              onChange={this.onChange}
              onClear={() => this.onChange(campaignContract.endTime, null)}
              validation={{
                endDateRange: true,
                optionalParamPath: campaignContract.date
              }}
            />

            <TemplateTimePicker
              id="setupTime"
              path={campaignContract.endTime}
              label="template.page.content.setup.time-and-place.endTime.label"
              subtext="template.page.content.setup.time-and-place.endTime.subtext"
              onChange={this.onChange}
            />
          </Form.Group>

          <TemplateInput
            id="setupTimeDetails"
            path={campaignContract.timeDetails}
            label="template.page.content.setup.time-and-place.time-details.label"
            placeholder="template.page.content.setup.time-and-place.time-details.placeholder"
            validation={this.timeDetailsValidation}
            onChange={this.onChange}
          />

          <TemplateInput
            id="setupLocation"
            path={campaignContract.place}
            label="template.page.content.setup.time-and-place.place.label"
            subtext="template.page.content.setup.time-and-place.place.subtext"
            validation={this.locationValidation}
            onChange={this.onChange}
          />

          <TemplateInput
            path={campaignContract.coordinatorFirstName}
            label="template.page.content.setup.time-and-place.coordinatorFirstName.label"
            subtext="template.page.content.setup.time-and-place.coordinatorFirstName.subtext"
            onChange={this.handleCustomExtraFieldChange}
            value={this.getCustomExtraFieldValue(
              campaignContract.coordinatorFirstName
            )}
          />

          <TemplateInput
            path={campaignContract.coordinatorLastName}
            label="template.page.content.setup.time-and-place.coordinatorLastName.label"
            subtext="template.page.content.setup.time-and-place.coordinatorLastName.subtext"
            onChange={this.handleCustomExtraFieldChange}
            value={this.getCustomExtraFieldValue(
              campaignContract.coordinatorLastName
            )}
          />

          <TemplateInput
            path={campaignContract.coordinatorEmail}
            label="template.page.content.setup.time-and-place.coordinatorEmail.label"
            subtext="template.page.content.setup.time-and-place.coordinatorEmail.subtext"
            onChange={this.handleCustomExtraFieldChange}
            value={this.getCustomExtraFieldValue(
              campaignContract.coordinatorEmail
            )}
          />

          <TemplateInput
            path={campaignContract.coordinatorPhone}
            label="template.page.content.setup.time-and-place.coordinatorPhone.label"
            subtext="template.page.content.setup.time-and-place.coordinatorPhone.subtext"
            onChange={this.handleCustomExtraFieldChange}
            value={this.getCustomExtraFieldValue(
              campaignContract.coordinatorPhone
            )}
          />
        </TemplatePage>

        <TemplatePage
          id="fundraising"
          path="fundraisingTab"
          i18nKey="template.page.fundraising"
        >
          <TemplateHeader
            as="h3"
            label="template.page.content.fundraising.personal-pages-header"
          />

          <TemplateCheckbox
            id="fundraisersFundraising"
            path={campaignContract.fundraisersEnable}
            inputLabel="template.page.content.fundraising.fundraisers.label"
            onChange={this.onFundraiserEnabledChange}
          />

          <TemplateToogle
            path={campaignContract.fundraisersEnable}
            className={'padding-left-style'}
            validation={this.getTargetValidation(
              campaignContract.fundraisersEnable,
              campaignContract.fundraisersAllowSelfSignUp,
              campaignContract.fundraisersDefaultTargetAmount
            )}
          >
            <TemplateToogle path={campaignContract.fundraisersEnable}>
              <TemplateCurrency
                id="fundraiserFundraisingTarget"
                path={campaignContract.fundraisersDefaultTargetAmount}
                label="template.page.content.fundraising.fundraisers.target.label"
                subtext="template.page.content.fundraising.fundraisers.target.subtext"
                subtextPosition="bottom"
                validation={this.target}
                onChange={this.onChange}
              />

              <TemplateInput
                id="fundraiserLabel"
                path={campaignContract.fundraisersDefaultText}
                label="template.page.content.fundraising.fundraisers.name.label"
                subtext="template.page.content.fundraising.fundraisers.name.subtext"
                placeholder="template.page.content.fundraising.fundraisers.name.label"
                subtextPosition="bottom"
                style={{ width: '224px'}}
                onChange={this.onChange}
              />

              <TemplateCheckbox
                id="fundraiserSelfSignup"
                path={campaignContract.fundraisersAllowSelfSignUp}
                inputLabel="template.page.content.fundraising.fundraisers.selfsignup.label"
                onChange={this.onChange}
              />
              <TemplateCheckbox
                id={campaignContract.fundraisersResourcesPageEnabled}
                path={campaignContract.fundraisersResourcesPageEnabled}
                inputLabel="template.page.content.fundraising.fundraisers.resources.checkbox.label"
                onChange={this.onFundraisingResourcesChange}
              />
            </TemplateToogle>
          </TemplateToogle>

          <TemplateHeader
            as="h3"
            path={campaignContract.teamsEnable}
            label="template.page.content.fundraising.team.header"
          />

          <TemplateCheckbox
            id="teamFundraising"
            path={campaignContract.teamsEnable}
            inputLabel="template.page.content.fundraising.team.label"
            onChange={this.onTeamEnabledChange}
          />

          <TemplateToogle
            path={campaignContract.teamsEnable}
            className={'padding-left-style'}
            validation={this.getTargetValidation(
              campaignContract.teamsEnable,
              campaignContract.teamsAllowSelfSignUp,
              campaignContract.teamsDefaultTargetAmount
            )}
          >
            <TemplateToogle path={campaignContract.teamsEnable}>
              <TemplateCurrency
                id="teamFundraisingTarget"
                path={campaignContract.teamsDefaultTargetAmount}
                label="template.page.content.fundraising.team.target.label"
                subtext="template.page.content.fundraising.team.target.subtext"
                subtextPosition="bottom"
                validation={this.target}
                onChange={this.onChange}
              />

              <TemplateInput
                id="teamLabel"
                path={campaignContract.teamsDefaultText}
                label="template.page.content.fundraising.team.name.label"
                subtext="template.page.content.fundraising.team.name.subtext"
                placeholder="template.page.content.fundraising.team.name.label"
                subtextPosition="bottom"
                style={{ width: '224px'}}
                onChange={this.onChange}
              />

              <TemplateCheckbox
                id="teamSelfSignup"
                path={campaignContract.teamsAllowSelfSignUp}
                inputLabel="template.page.content.fundraising.team.selfsignup.label"
                onChange={this.onChange}
              />

              <TemplateCheckbox
                id="teamUniqueNames"
                path={campaignContract.teamsUniqueNames}
                inputLabel="template.page.content.fundraising.team.unique-names.label"
                onChange={this.onChange}
              />
            </TemplateToogle>
          </TemplateToogle>

          <TemplateHeader
            as="h3"
            label="template.page.content.fundraising.collections.header"
          />

          <TemplateText
            label="template.page.content.fundraising.collections.subheader"
            size="small"
          />

          <TemplateCheckbox
            id={campaignContract.allowCollections}
            path={campaignContract.allowCollections}
            inputLabel="template.page.content.fundraising.collections.allowCollections.label"
            onChange={this.onAllowCollectionsChange}
          />

          <TemplateInput
            id={campaignContract.collectionsGeneralName}
            path={campaignContract.collectionsGeneralName}
            label="template.page.content.fundraising.collections.generalName.label"
            placeholder="template.page.content.fundraising.collections.generalName.label"
            style={{ width: '224px'}}
            subtext="template.page.content.fundraising.collections.generalName.placeholder"
            onChange={this.onChange}
          />

          <TemplateCheckbox
            id={campaignContract.allowCollectionDonations}
            path={campaignContract.allowCollectionDonations}
            inputLabel="template.page.content.fundraising.collections.allowDonations.label"
            onChange={this.onChange}
          />

          <TemplateCheckbox
            id={campaignContract.allowCollectionSales}
            path={campaignContract.allowCollectionSales}
            inputLabel="template.page.content.fundraising.collections.allowSales.label"
            onChange={this.onChange}
          />

          <TemplateCheckbox
            id={campaignContract.enableCollectionPageManager}
            path={campaignContract.enableCollectionPageManager}
            inputLabel="template.page.content.fundraising.collections.allowPageManager.label"
            onChange={this.onChange}
          />

          <TemplateCheckbox
            id={campaignContract.isCollectionRequired}
            path={campaignContract.isCollectionRequired}
            inputLabel="template.page.content.fundraising.collections.isRequired.label"
            onChange={this.onChange}
          />

          <TemplateCheckbox
            id={campaignContract.disableCollectionsPublicPages}
            path={campaignContract.disableCollectionsPublicPages}
            inputLabel="template.page.content.fundraising.collections.disablePublicPages.label"
            onChange={this.onChange}
          />

          <TemplateDropdown
            id={campaignContract.joiningExperience}
            path={campaignContract.joiningExperience}
            label="template.page.content.fundraising.collections.joining-experience.label"
            validation={this.required}
            onChange={this.onChange}
            options={joiningExperienceOptions}
            style={{ width: '224px'}}
          />

          <TemplateHorizontalRule />

          <div className="template-heading">
            <TemplateHeader
              as="h3"
              label="template.page.content.fundraising.data-capture.header"
            />
          </div>

          <TemplateInput
            id="signupTextInput"
            path={campaignContract.newPageButtonText}
            label="template.page.content.fundraising.registration-page-button-text.label"
            subtext="template.page.content.fundraising.registration-page-button-text.subtext"
            onChange={this.onChange}
          />

          <TemplateInput
            id="signupTextInput"
            path={campaignContract.registrationPageText}
            label="template.page.content.fundraising.data-capture-text.label"
            subtext="template.page.content.fundraising.data-capture-text.subtext"
            onChange={this.onChange}
            validation={this.required}
          />

          <TemplateHeader
            as="h5"
            path={campaignContract.registrationQuestions}
            label="template.page.content.fundraising.data-capture-questions.label"
          />

          <TemplateFormBuilder
            formBuilderKey={formBuilderKeys.CAMPAIGN_FUNDRAISING}
            path={campaignContract.registrationQuestions}
            initialData={getFromBuilderInitialData(
              formBuilderKeys.CAMPAIGN_FUNDRAISING
            )}
            onChange={this.onChange}
            validation={this.formBuilderQuestionsValidation}
            subtext={I18n.t(
              'template.page.content.fundraising.data-capture-questions.subtext'
            )}
          />

          <TemplateHorizontalRule
            path={campaignContract.allowFundraisingTimeAndPlace}
          />

          <TemplateHeader
            as="h3"
            label="template.page.content.fundraising.advanced.header"
            path={campaignContract.allowFundraisingTimeAndPlace}
          />

          <TemplateCheckbox
            id={campaignContract.allowFundraisingTimeAndPlace}
            path={campaignContract.allowFundraisingTimeAndPlace}
            inputLabel="template.page.content.fundraising.advanced.allow-fundraising-time-and-place.label"
            onChange={this.onChange}
          />
        </TemplatePage>

        <TemplatePage
          id="fundraisersResourcesPage"
          path={campaignContract.fundraisersResourcesPage}
          i18nKey="template.page.content.fundraising.resources.header.text"
        >
          <FundraisingResourcesBuilder
            path={campaignContract.fundraisersResourcesPage}
            onChange={this.onChange}
            isSystemAdmin={this.props.isSystemAdmin}
          />
        </TemplatePage>

        <TemplatePage id="donations" path="donations" i18nKey="template.page.donations">
          <div className="template-heading">
            <TemplateHeader
              as="h3"
              label="template.page.content.donation.header"
            />
          </div>

          <TemplateHeader
            as="h5"
            label="template.page.content.donation.default-donation-amounts.label"
          />

          <TemplateText
            label="template.page.content.donation.default-donation-amounts.subtext"
            size="small"
          />

          <TemplateDefaultAmounts
            id="donationDefaultAmounts"
            path={campaignContract.defaultAmounts}
            validation={this.defaultAmountsValidation}
            onChange={this.onChange}
            parser={Number}
          />

          <TemplateHorizontalRule />

          <div className="template-heading">
            <TemplateHeader
              as="h3"
              label="template.page.content.donation.making-donation-section.label"
            />
          </div>

          <TemplateInput
            id="donationPageTitle"
            path={campaignContract.donatinPageTitle}
            label="template.page.content.donation.donation-page-title.label"
            subtext="template.page.content.donation.donation-page-title.subtext"
            onChange={this.onChange}
          />

          <TemplateInput
            id="donationPageText"
            path={campaignContract.donatinPageText}
            label="template.page.content.donation.donation-page-text.label"
            subtext="template.page.content.donation.donation-page-text.subtext"
            validation={this.required}
            onChange={this.onChange}
          />

          <TemplateHeader
            as="h5"
            label="template.page.content.donation.donation-questions.label"
            path={campaignContract.donationQuestions}
          />

          <TemplateFormBuilder
            formBuilderKey={formBuilderKeys.CAMPAIGN_DONATIONS}
            path={campaignContract.donationQuestions}
            initialData={getFromBuilderInitialData(
              formBuilderKeys.CAMPAIGN_DONATIONS
            )}
            onChange={this.onChange}
            validation={this.formBuilderQuestionsValidation}
            subtext={I18n.t(
              'template.page.content.donation.donation-questions.subtext'
            )}
          />

          <TemplateHorizontalRule />

          <div className="template-heading">
            <TemplateHeader
              as="h3"
              label="template.page.content.donation.thank-you-page-section.label"
            />
          </div>

          <TemplateInput
            id="donationThankYouMessage"
            path={campaignContract.thankYouMessage}
            label="template.page.content.donation.thank-you-message.label"
            subtext="template.page.content.donation.thank-you-message.subtext"
            validation={this.optional}
            onChange={this.onChange}
          />

          <TemplateHeader
            as="h5"
            label="template.page.content.donation.thank-you-media-heading.label"
          />

          <TemplateCustomMediaSelector
            onChange={this.onChange}
            path={campaignContract.thankYouMediaType}
            validation={{
              customMediaSelector: true,
              optionalParamPath: campaignContract.thankYouVideo
            }}
          >
            <TemplateCustomVideoSelector
              id={DONATION_VIDEO}
              label="Video URL"
              radioLabel="template.page.content.donation.donation-video.label"
              path={campaignContract.thankYouVideo}
              validation={this.urlValidation}
              subtext="template.page.content.donation.donation-video.subtext"
              onChange={this.onChange}
            />

            <TemplateEditableUpload
              id="donationImage"
              path={campaignContract.thankYouImage}
              radioLabel="template.page.content.donation.donation-image.label"
              entity={entityTypes.CAMPAIGN}
              position="top"
              height="350"
              width="1500"
              format={this.photoPhormats}
              disableCropping
              uploadType={imageTypes.THANK_YOU}
              label="template.page.content.donation.media.image"
              onChange={this.onChange}
            />
          </TemplateCustomMediaSelector>

          <TemplateHorizontalRule />

          <div className="template-heading">
            <TemplateHeader
              as="h3"
              label="template.page.content.ticketing.donation-receipt-section.header"
            />
          </div>

          <TemplateEditableUpload
            id="donationEmailImage"
            path={campaignContract.donationReceiptImage}
            entity={entityTypes.CAMPAIGN}
            position="top"
            height="350"
            width="1500"
            format={this.photoPhormats}
            uploadType={imageTypes.ASSET}
            label="template.page.content.ticketing.donation-receipt-section.banner"
            subtext="template.page.content.ticketing.donation-receipt-section.info"
            onChange={this.onChange}
          />

          <TemplateTextArea
            path={campaignContract.donationReceiptMessage}
            label="template.page.content.ticketing.donation-receipt-section.thank-you-header"
            subtext={{
              key: 'template.page.content.ticketing.donation-receipt-section.thank-you-description',
              translationObject: {
                characterCount: this.donationThankYouMessageMaxLength
              }
            }}
            maxLength={this.donationThankYouMessageMaxLength}
            onChange={this.onChange}
          />

          <TemplateHorizontalRule
            path={campaignContract.allowedDonationTypes}
          />

          <div
            className="template-heading"
            path={campaignContract.allowedDonationTypes}
          >
            <TemplateHeader
              as="h3"
              label="template.page.content.donation.recurring.header"
            />
          </div>

          <TemplateRadioGroup
            id={campaignContract.allowedDonationTypes}
            path={campaignContract.allowedDonationTypes}
            onChange={this.onAllowRecurringGivingChange}
            options={donationTypeOptions}
          />

          <br />

          <TemplateText
            label="template.page.content.donation.recurring.info.recurringAndOnceOff"
            path="recurring-donation-info-recurringAndOnceOff"
            size="small"
          />
          <TemplateText
            label="template.page.content.donation.recurring.info.onlyOnceOff"
            path="recurring-donation-info-onlyOnceOff"
            size="small"
          />
          <TemplateText
            label="template.page.content.donation.recurring.info.onlyRecurring"
            path="recurring-donation-info-onlyRecurring"
            size="small"
          />

          <TemplateHorizontalRule path={campaignContract.nameForDonors} />

          <div
            className="template-heading"
            path={campaignContract.nameForDonors}
          >
            <TemplateHeader
              as="h3"
              label="template.page.content.donation.donation-advanced-section.label"
            />
          </div>

          <TemplateInput
            id="donationButtonText"
            path={campaignContract.donationButtonText}
            label="template.page.content.donation.donation-advanced-section.donate-button-text.label"
            subtext="template.page.content.donation.donation-advanced-section.donate-button-text.subtext"
            onChange={this.onChange}
          />

          <TemplateInput
            id="donationDonorCall"
            path={campaignContract.nameForDonors}
            label="template.page.content.donation.donor-call.label"
            subtext="template.page.content.donation.donor-call.subtext"
            placeholder="template.page.content.donation.donor-call.label"
            onChange={this.onChange}
          />

          {/* <TemplateDropdown id={campaignContract.donationRecurringLimit}
						path={campaignContract.donationRecurringLimit}
						defaultLabel='template.page.content.donation.recurring.recurringLimit.defaultLabel'
						onChange={this.onChange}
						options={recurringLimitOptions} /> */}
        </TemplatePage>

        <TemplatePage id="advanced" path="advanced" i18nKey="template.page.advanced">
          <TemplateHeader
            as="h3"
            label="template.page.content.advanced.microblogs.header"
            path={campaignContract.allowMicroblogs}
          />

          <TemplateCheckbox
            id={campaignContract.allowMicroblogs}
            path={campaignContract.allowMicroblogs}
            inputLabel="template.page.content.advanced.microblogs.checkbox"
            onChange={this.onChange}
          />

          <TemplateHorizontalRule path={campaignContract.allowMicroblogs} />

          <TemplateHeader
            as="h3"
            label="template.page.content.advanced.achievements.header"
            path={campaignContract.allowAchievements}
          />

          <TemplateCheckbox
            id={campaignContract.allowAchievements}
            path={campaignContract.allowAchievements}
            inputLabel="template.page.content.advanced.achievements.checkbox"
            onChange={this.onChange}
          />

          <TemplateHorizontalRule path={campaignContract.allowAchievements} />

          <WebTrackingTemplate
            type="campaign"
            path={campaignContract.webTracking}
            onChange={this.onChange}
          />

          {this.props.isSystemAdmin && (
            <Multicurrency
              type="campaign"
              onChange={this.onChange}
            />
          )}

          <TemplateHorizontalRule path={campaignContract.qrCodesHeader} />

          <TemplateHeader
            as="h3"
            label="template.page.content.advanced.qr-codes.header"
            path={campaignContract.qrCodesHeader}
          />

          <TemplateText
            size="small"
            className="qr-codes-content"
            label="template.page.content.advanced.qr-codes.content"
            path={campaignContract.qrCodesContent}
          />

          <TemplateCheckbox
            path={campaignContract.qrCodesCheckbox}
            label="template.page.content.advanced.qr-codes.checkbox.title"
            inputLabel="template.page.content.advanced.qr-codes.checkbox.content"
            onChange={this.onDisplayOrgLogoInQRCodesChange}
          />

          <Form.Field path={campaignContract.qrCodesButtons}>
            <label>
              {I18n.t('template.page.content.advanced.qr-codes.buttons.title')}
            </label>
            <div className="qr-codes-buttons">
              <Button
                type="button"
                className="preview-branded-btn"
                onClick={this.onViewBrandedClick}
              >
                {I18n.t(
                  'template.page.content.advanced.qr-codes.buttons.preview-branded'
                )}
              </Button>
              <Button 
                type="button"
                onClick={this.onViewStardardClick}
              >
                {I18n.t(
                  'template.page.content.advanced.qr-codes.buttons.preview-standard'
                )}
              </Button>
            </div>
          </Form.Field>

          <TemplateHorizontalRule
            path={campaignContract.defaultFundraiserAbout}
          />

          <TemplateHeader
            as="h3"
            label="template.page.content.advanced.header"
            path="defaultAboutHeader"
          />

          {/* <TemplateHeader as='h5'
						label='template.page.content.advanced.default-fundraiser-about.header'
						path={campaignContract.defaultFundraiserAbout} />
 					*/}
          <TemplateAbout
            id="advancedDefaultFundraiser"
            path={campaignContract.defaultFundraiserAbout}
            label="template.page.content.advanced.default-fundraiser-about.label"
            onChange={this.onChange}
            entity={entityTypes.CAMPAIGN}
          />

          <TemplateHorizontalRule path={campaignContract.defaultTeamAbout} />

          <TemplateHeader
            as="h3"
            label="template.page.content.advanced.default-team-about.header"
            path={campaignContract.defaultTeamAbout}
          />

          <TemplateAbout
            id="advancedDefaultTeam"
            path={campaignContract.defaultTeamAbout}
            label="template.page.content.advanced.default-team-about.label"
            onChange={this.onChange}
            entity={entityTypes.CAMPAIGN}
          />

          <TemplateHorizontalRule
            path={campaignContract.defaultCollectionAbout}
          />

          <TemplateHeader
            as="h3"
            label="template.page.content.advanced.default-collection-about.header"
            path={campaignContract.defaultCollectionAbout}
          />

          <TemplateAbout
            id="advancedDefaultCollection"
            path={campaignContract.defaultCollectionAbout}
            label="template.page.content.advanced.default-collection-about.label"
            onChange={this.onChange}
            entity={entityTypes.CAMPAIGN}
          />

          <TemplateHorizontalRule />

          <TemplateHeader
            as="h3"
            label="template.page.content.advanced.salutation-section.header"
          />

          <TemplateCheckbox
            path={campaignContract.salutationEnabled}
            inputLabel="template.page.content.advanced.salutation-section.checkbox.label"
            onChange={this.onChange}
          />

          <CustomSection />

          {this.hasTallySettings() &&
            <>
              <TemplateHorizontalRule />

              <TemplateHeader
                as="h3"
                label="template.page.content.advanced.tally-settings.header"
              />

              <TemplateText
                size="small"
                className="qr-codes-content"
                label="template.page.content.advanced.tally-settings.description"
              />

              <TemplateDropdown
                id={paths.RAISED_TOTAL_TYPE}
                path={paths.RAISED_TOTAL_TYPE}
                defaultLabel="template.page.content.advanced.tally-settings.options.default"
                onChange={this.onChange}
                getOptions={this.getTallySettingOptions}
                style={{ width: '400px' }}
              />
            </>
          }

          <TemplateSetupButtons
            id="defaultSetupButtons"
            validation={this.defaultSetupButtonsValidation}
            path="template.value.customSetup.pageButtons"
            onChange={this.onChange}
          ></TemplateSetupButtons>

          
          {/* Special effects */}

          {!get(this.props.templateBuilder, 'event.id') ? (
            <>
            <TemplateHorizontalRule />
            <TemplateHeader
              as="h3"
              label="template.page.content.eyes-only.special-effects.header"
            />
            <TemplateCheckbox
              inputLabel="template.page.content.eyes-only.special-effects.checkbox-enable-confetti"
              path={campaignContract.specialEffectsConfettiEnabled}
              onChange={this.onChange}
            />
            <div style={{
              marginTop: "-1.5rem"
            }}>
              <p style={{
                marginBottom: "0"
              }}>{I18n.t("template.page.content.eyes-only.special-effects.checkbox-enable-confetti-description")}</p>
              <ul style={{
                marginTop: "0"
              }}>
                <li>{I18n.t("template.page.content.eyes-only.special-effects.checkbox-enable-confetti-description-list.0")}</li>
                {/* <li>{I18n.t("template.page.content.eyes-only.special-effects.checkbox-enable-confetti-description-list.1")}</li> */}
                <li>{I18n.t("template.page.content.eyes-only.special-effects.checkbox-enable-confetti-description-list.2")}</li>
              </ul>
            </div>
            </>
          ) : null}


        </TemplatePage>

        <TemplatePage
          id="ticketing"
          path="ticketingTab"
          i18nKey="template.page.ticketing"
        >
          <TemplateHeader
            as="h3"
            label="template.page.content.ticketing.ticketing.header"
          />

          <TemplateCheckbox
            id={campaignContract.allowTicketing}
            path={campaignContract.allowTicketing}
            inputLabel="template.page.content.ticketing.ticketing.checkbox"
            onChange={this.onAllowTicketingChange}
            />

          <TemplateRadioGroup
            id="simpleTicketingEnabled"
            path={campaignContract.simpleTicketingEnabled}
            onChange={this.onSimpleTicketingEnabledChange}
            options={simpleTicketingEnabledOptions}
            />

          <TemplateHorizontalRule path={campaignContract.ticketsInformation} />

          <TemplateHeader
            as="h3"
            label="template.page.content.ticketing.ticket-type.header"
            path={campaignContract.ticketsInformation}
          />

          <TemplateRadioGroup
            id="ticketIssuingStyleRadioGroup"
            path={campaignContract.ticketIssuingStyle}
            onChange={this.onAdmissionTypeChange}
            options={ticketIssuingStyleOptions}
          />

          <TemplateText
            label={`template.page.content.ticketing.admission.${
              admissionTranslationKeys[this.props.ticketIssuingStyle]
            }`}
            size="small"
            path={campaignContract.ticketsInformation}
          />

          <TemplateCheckbox
            id={campaignContract.singleUserRegistrationMode}
            path={campaignContract.singleUserRegistrationMode}
            inputLabel="template.page.content.termsAndConditions.single-user-checkbox"
            subtext="template.page.content.termsAndConditions.single-user-description"
            onChange={this.onSingleUserRegistrationChange}
          />
          
          <TemplateCheckbox
            id={campaignContract.allowtermsAndConditionSetupTab}
            path={campaignContract.allowtermsAndConditionSetupTab}
            inputLabel="template.page.content.termsAndConditions.enable-checkbox"
            subtext="template.page.content.termsAndConditions.enable-description"
            onChange={this.onAllowWaiversChange}
          />

          <PaymentFeeCalculation
            path={campaignContract.paymentFeeCalculation}
            onChange={this.onChange}
          />

          <TemplateHorizontalRule path={campaignContract.ticketsInformation} />

          <TemplateHeader
            as="h3"
            label="template.page.content.ticketing.invoice-info.header"
            path={campaignContract.ticketsInformation}
          />

          <TemplateText
            label="template.page.content.ticketing.invoice-info.label"
            path={campaignContract.ticketsInformation}
            size="small"
          />

          <TemplateHeader
            as="h5"
            path={campaignContract.ticketsInformation}
            label="template.page.content.ticketing.invoice-info.questions.label"
          />

          <TemplateFormBuilder
            formBuilderKey={formBuilderKeys.CAMPAIGN_TICKETING}
            path={campaignContract.ticketingInvoiceQuestions}
            initialData={getFromBuilderInitialData(
              formBuilderKeys.CAMPAIGN_TICKETING
            )}
            onChange={this.onChange}
            validation={this.formBuilderQuestionsValidation}
          />

          <TemplateHorizontalRule path={campaignContract.ticketsInformation} />

          <TemplateHeader
            as="h3"
            path={campaignContract.ticketsInformation}
            label="template.page.content.ticketing.tickets.header"
          />

          <TemplateText
            path={campaignContract.ticketsInformation}
            label="template.page.content.ticketing.tickets.label"
            size="small"
          />

          <TemplateTicketBuilder
            isQuestionsEnabled={
              this.props.ticketIssuingStyle ===
              ticketIssuingStyle.IndividualTicket
            }
            path={campaignContract.tickets}
            onChange={this.onChange}
            validation={this.ticketBuilderValidation}
          />

          {get(this.props.templateBuilder, "ticketing.enabled") && (
            <>
              <TemplateHorizontalRule />

              <TemplateHeader
                as="h3"
                label="template.page.content.ticketing.instalments.header"
              />

              <InfoBox 
                header="template.page.content.ticketing.instalments.header"
                content="template.page.content.ticketing.instalments.description"
              />

              <TemplateCheckbox
                path={campaignContract.allowTicketInstallments}
                inputLabel="template.page.content.ticketing.instalments.enabled"
                onChange={this.onTicketInstallmentChanged}
              />

              <TemplateDropdown
                id={campaignContract.installmentCountOptions}
                path={campaignContract.installmentCountOptions}
                label="template.page.content.ticketing.instalments.count.label"
                subtext="template.page.content.ticketing.instalments.count.subtext"
                onChange={this.onChange}
                validation={this.required}
                options={installmentCountOptions}
              />

              <TemplateDropdown
                id={campaignContract.ticketInstallmentFrequency}
                path={campaignContract.ticketInstallmentFrequency}
                label="template.page.content.ticketing.instalments.frequency.label"
                subtext="template.page.content.ticketing.instalments.frequency.subtext"
                onChange={this.onChange}
                validation={this.required}
                options={donationRecurringFrequencyOptions(this.props.isSystemAdmin)}
              />

              <TemplateDatePicker
                id={campaignContract.ticketInstallmentNewSalesUntil}
                path={campaignContract.ticketInstallmentNewSalesUntil}
                label="template.page.content.ticketing.instalments.newSalesUntil.label"
                subtext="template.page.content.ticketing.instalments.newSalesUntil.subtext"
                minDate={new Date()}
                onChange={this.onChange}
              />

              {get(this.props.templateBuilder, "ticketing.installments.enabled")
                && get(this.props.templateBuilder, "ticketing.installments.count")
                && get(this.props.templateBuilder, "ticketing.installments.frequency")
                && get(this.props.templateBuilder, "template.value.ticketingSetup.instalmentsNewSalesUntil")
                && (
                  <InfoBox
                    isolated
                    className="last-potential-payment"
                    showIcon={false}
                  >
                    <TemplateText label="template.page.content.ticketing.instalments.lastPotentialPayment" />
                    <TemplateHeader
                      as="h4"
                      label={
                        moment(get(this.props.templateBuilder, "template.value.ticketingSetup.instalmentsNewSalesUntil"))
                        .add(
                          get(this.props.templateBuilder, "ticketing.installments.count") - 1,
                          get(this.props.templateBuilder, "ticketing.installments.frequency") === "daily"
                            ? "d"
                            : get(this.props.templateBuilder, "ticketing.installments.frequency") === "weekly"
                              ? "w"
                              : "M"
                        )
                        .format("DD/MM/YYYY")
                      }
                    />
                  </InfoBox>
                )}
            </>
          )}

          <TemplateHorizontalRule
            path={campaignContract.purchaseFundraisingEnabled}
          />

          <TemplateHeader
            as="h3"
            label="template.page.content.ticketing.fundraising-info.header"
            path={campaignContract.purchaseFundraisingEnabled}
          />

          <TemplateCheckbox
            id="purchaseFundraisingEnabled"
            path={campaignContract.purchaseFundraisingEnabled}
            inputLabel="template.page.content.ticketing.fundraising-info.purchaseFundraisingEnabled.checkbox"
            onChange={this.onAllowTicketingFundraisingChange}
          />

          <TemplateRadioGroup
            id="purchaseFundraisingIsMandatoryRadioGroup"
            path={campaignContract.purchaseFundraisingIsMandatory}
            onChange={this.onChange}
            options={mandatoryOptions}
          />

          <TemplateRadioGroup
            id="ticketPurchaseFundraisingStyleRadioGroup"
            path={campaignContract.purchaseFundraisingStyle}
            onChange={this.onChange}
            options={ticketPurchaseFundraisingStyles}
          />

          <TemplateCheckbox
            id="allowTeamStep"
            className="field-padding-gap"
            path={campaignContract.allowTeamStep}
            inputLabel="template.page.content.ticketing.fundraising-info.allowTeamStep.checkbox"
            onChange={this.onChange}
          />

          <TemplateHorizontalRule path={campaignContract.enableSaleDonations} />

          <TemplateHeader
            as="h3"
            label="template.page.content.ticketing.donation-info.header"
            path={campaignContract.enableSaleDonations}
          />

          <TemplateCheckbox
            className="ticketing-donation-checkbox"
            id={campaignContract.enableSaleDonations}
            path={campaignContract.enableSaleDonations}
            inputLabel={saleDonationLabelKey}
            onChange={this.onSaleDonationsChange}
          />

          <AmountListInput 
            id={campaignContract.ticketingDefaultDonationAmounts} 
            path={campaignContract.ticketingDefaultDonationAmounts} 
            onChange={this.onSelectDefaultDonationAmounts}
            currency={currency}
          />

          <TemplateRadioGroup
            id={campaignContract.allowedSaleDonationTypes}
            path={campaignContract.allowedSaleDonationTypes}
            onChange={this.onAllowSaleRecurringGivingChange}
            options={saleDonationTypeOptions}
          />

          <br />

          <TemplateText
            label="template.page.content.ticketing.donation-info.info.recurringAndOnceOff"
            path="recurring-sale-info-recurringAndOnceOff"
            size="small"
          />
          <TemplateText
            label="template.page.content.ticketing.donation-info.info.onlyOnceOff"
            path="recurring-sale-info-onlyOnceOff"
            size="small"
          />
          <TemplateText
            label="template.page.content.ticketing.donation-info.info.onlyRecurring"
            path="recurring-sale-info-onlyRecurring"
            size="small"
          />

          {/* <TemplateDropdown id={campaignContract.saleRecurringLimit}
						path={campaignContract.saleRecurringLimit}
						defaultLabel='template.page.content.ticketing.donation-info.recurringLimit.defaultLabel'
						onChange={this.onChange}
						options={recurringLimitOptions} /> */}

          <TemplateHorizontalRule path={campaignContract.allowMerchandise} />

          <TemplateHeader
            as="h3"
            label="template.page.content.merchandise.header"
            path={campaignContract.allowMerchandise}
          />
          <TemplateCheckbox
            id={campaignContract.allowMerchandise}
            path={campaignContract.allowMerchandise}
            inputLabel="template.page.content.merchandise.checkbox"
            onChange={this.onAllowMerchandiseChange}
          />
          <TemplateText
            label="template.page.content.merchandise.description"
            size="small"
            path={campaignContract.allowMerchandise}
          />

          <TemplateHorizontalRule path={campaignContract.enableSaleDonations} />

          <TemplateHeader
            as="h3"
            label="template.page.content.ticketing.other-info.header"
            path={campaignContract.tickets}
          />

          <TemplateInput
            id="ticketButtonTextInput"
            path={campaignContract.ticketsButtonText}
            label="template.page.content.ticketing.other-info.button-text.label"
            subtext="template.page.content.ticketing.other-info.button-text.subtext"
            onChange={this.onChange}
          />

          <TemplateInput
            id="ticketingIntroductoryTitleInfo"
            path={campaignContract.ticketsTitleText}
            label="template.page.content.ticketing.other-info.title-text.label"
            subtext="template.page.content.ticketing.other-info.title-text.subtext"
            onChange={this.onChange}
          />

          <TemplateInput
            id="ticketingIntroductoryInfo"
            path={campaignContract.ticketingIntroductoryText}
            label="template.page.content.ticketing.other-info.introductory.label"
            subtext="template.page.content.ticketing.other-info.introductory.subtext"
            validation={this.required}
            onChange={this.onChange}
          />

          <TemplateHorizontalRule path={campaignContract.ticketingThankYou} />

          <TemplateHeader
            as="h3"
            label="template.page.content.ticketing.thank-you-section.label"
            path={campaignContract.ticketingThankYou}
          />

          <TemplateAbout
            id="ticketingThankYou"
            path={campaignContract.ticketingThankYou}
            label="template.page.content.ticketing.thank-you-section.thank-you-message.label"
            mediaLabel="template.page.content.ticketing.thank-you-section.thank-you-media.label"
            subtext="template.page.content.ticketing.thank-you-section.thank-you-message.subtext"
            onChange={this.onChange}
            disableCropping
            entity={entityTypes.CAMPAIGN}
          />

          <TemplateInput
            id={campaignContract.purchaseTicketUrl}
            path={campaignContract.purchaseTicketUrl}
            label="template.page.content.ticketing.ticketing.website.label"
            subtext="template.page.content.ticketing.ticketing.website.subtext"
            subtextPosition="bottom"
            validation={this.urlValidation}
            onChange={this.onChange}
          />
        </TemplatePage>

        <TemplatePage
          id="merchandise"
          path={campaignContract.merchandiseTab}
          i18nKey="template.page.merchandise"
        >
          <MerchandiseBuilder path={campaignContract.merchandise} />
        </TemplatePage>
        
        <TemplatePage
          id={'termsAndConditions'}
          path={campaignContract.termsAndConditionSetupTab}
          i18nKey="template.page.termsAndConditionSetup"
        >
          <TermsAndConditionSetupBuilder
            id={campaignContract.termsAndConditionSetup}
            path={campaignContract.termsAndConditionSetup}
            validation={this.termsAndConditionSetupBuilderValidation}
            onChange={this.onChange}
          />
        </TemplatePage>

        <TemplatePage
          id="discounts"
          path="discountsTab"
          i18nKey="template.page.discounts"
        >
          <TemplateHeader
            as="h3"
            label="template.page.content.discounts.setup.header"
          />

          <TemplateText
            path={campaignContract.couponsInformation}
            label="template.page.content.discounts.coupons.label"
            size="small"
          />

          <TemplateCheckbox
            id={campaignContract.allowCoupons}
            path={campaignContract.allowCoupons}
            inputLabel="template.page.content.discounts.setup.checkbox"
            onChange={this.onAllowCouponsChange}
          />

          <TemplateCouponBuilder
            path={campaignContract.coupons}
            entityName={this.props.campaignName}
            onChange={this.onChange}
            validation={this.couponBuilderValidation}
            couponModel={this.props.templateBuilder.couponModel}
          />
        </TemplatePage>

        <TemplatePage
          id="fitness"
          path="fitnessTab"
          i18nKey="template.page.fitness"
        >
          <TemplateCheckbox
            id={FitnessContract.fitnessEnabled}
            path={FitnessContract.fitnessEnabled}
            inputLabel="template.page.content.fitness.enabled.checkbox"
            onChange={this.onAllowFitnessChange}
          />
          <TemplateText
            label="template.page.content.fitness.enabled.subtext"
            size="small"
          />
          <FitnessTemplate 
            id={FitnessContract.fitnessTemplateView}
            path={FitnessContract.fitnessTemplateView}
          />
        </TemplatePage>

        <TemplatePage
          id="giftMatching"
          path="giftMatchingTab"
          i18nKey="template.page.giftMatching"
        >
          <TemplateHeader
            as="h3"
            label="template.page.content.giftMatching.header"
          />

          <TemplateText
            label="template.page.content.giftMatching.description"
            size="small"
          />

          <TemplateGiftMatchingBuilder
            path={campaignContract.giftMatching}
            onChange={this.onChange}
            validation={this.giftMatchingValidation}
          />
        </TemplatePage>

        {this.props.isSystemAdmin && (
          <TemplatePage id="eyes-only" path="eyes-only" i18nKey="template.page.eyes-only">
            <TemplateHeader
              as="h3"
              label="template.page.content.eyes-only.tags.header"
            />

            <TagsInput path={campaignContract.tags} onChange={this.onChange} />

            <TemplateHeader
              as="h3"
              label="template.page.content.eyes-only.linked-campaigns.header"
            />

            <TemplateInput
              id={campaignContract.linkedCampaignTag}
              path={campaignContract.linkedCampaignTag}
              label="template.page.content.eyes-only.linked-campaigns.linkedCampaignTag.label"
              placeholder="template.page.content.eyes-only.linked-campaigns.linkedCampaignTag.placeholder"
              onChange={this.onChange}
            />

            <TemplateHorizontalRule />

            <TemplateHeader
              as="h3"
              label="template.page.content.eyes-only.type.header"
            />

            <TemplateDropdown
              id={campaignContract.type}
              path={campaignContract.type}
              label="template.page.content.eyes-only.type.type-select.label"
              onChange={this.onChange}
              options={campaignTypeOptions}
            />

            <TemplateHorizontalRule />

            <TemplateHeader
              as="h3"
              label="template.page.content.eyes-only.additional-info-box-organisation.header"
            />

            <TemplateAutocomplete
              id="additionalInfoBoxOrganization"
              entity="organization"
              path={campaignContract.additionalInfoBoxOrganization}
              selected="additionalInfoBoxOrganization"
              label="template.page.content.eyes-only.additional-info-box-organisation.label"
              placeholder="template.page.content.eyes-only.additional-info-box-organisation.placeholder"
              onChange={this.onChange}
              request={this.getOrganizationRequest}
              getItem={(value: any) => this.getOrganizationItem(value, true)}
              returnField="urlPath"
            />

            <TemplateHorizontalRule path={campaignContract.forceRedirectTo} />

            <TemplateHeader
              as="h3"
              path={campaignContract.forceRedirectTo}
              label="template.page.content.eyes-only.forceRedirect.header"
            />

            <TemplateInput
              id={campaignContract.forceRedirectTo}
              path={campaignContract.forceRedirectTo}
              label="template.page.content.eyes-only.forceRedirect.label"
              subtext="template.page.content.eyes-only.forceRedirect.subtext"
              subtextPosition="bottom"
              validation={this.optionalUrlValidation}
              onChange={this.onChange}
            />

            <TemplateHorizontalRule />

            <TemplateHeader
              as="h3"
              label="template.page.content.eyes-only.recurringGivingCustomFields.header"
            />

            <Form.Group widths="equal">
              <TemplateCheckbox
                path={
                  campaignContract.recurringGivingCustomSalutationFieldEnabled
                }
                inputLabel="template.page.content.eyes-only.recurringGivingCustomFields.salutation.enabled"
                onChange={this.onChange}
              />
              <TemplateCheckbox
                path={
                  campaignContract.recurringGivingCustomSalutationFieldRequired
                }
                inputLabel="template.page.content.eyes-only.recurringGivingCustomFields.salutation.required"
                onChange={this.onChange}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <TemplateCheckbox
                path={campaignContract.recurringGivingCustomAddressFieldEnabled}
                inputLabel="template.page.content.eyes-only.recurringGivingCustomFields.address.enabled"
                onChange={this.onChange}
              />
              <TemplateCheckbox
                path={
                  campaignContract.recurringGivingCustomAddressFieldRequired
                }
                inputLabel="template.page.content.eyes-only.recurringGivingCustomFields.address.required"
                onChange={this.onChange}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <TemplateCheckbox
                path={campaignContract.recurringGivingCustomPhoneFieldEnabled}
                inputLabel="template.page.content.eyes-only.recurringGivingCustomFields.phone.enabled"
                onChange={this.onChange}
              />
              <TemplateCheckbox
                path={campaignContract.recurringGivingCustomPhoneFieldRequired}
                inputLabel="template.page.content.eyes-only.recurringGivingCustomFields.phone.required"
                onChange={this.onChange}
              />
            </Form.Group>

            <TemplateHorizontalRule />

            <TemplateHeader
              as="h3"
              label="template.page.content.eyes-only.settings.header"
            />

            <TemplateDropdown
              path={campaignContract.raisedTotalType}
              label="template.page.content.eyes-only.settings.raisedTotalType.label"
              onChange={this.onChange}
              options={raisedTotalTypeOptions}
            />

            <TemplateInput
              path={campaignContract.contentSite}
              label="template.page.content.eyes-only.settings.contentSite.label"
              validation={this.optionalUrlValidation}
              onChange={this.onChange}
            />

            <TemplateCheckbox
              path={campaignContract.displayFullAmountPrecision}
              inputLabel="template.page.content.eyes-only.settings.displayFullAmountPrecision.label"
              onChange={this.onChange}
            />

            <TemplateHorizontalRule />

            <TemplateHeader
              as="h3"
              label="template.page.content.eyes-only.donation-setup.header"
            />

            <TemplateCheckbox
              path={campaignContract.disableBankAccountPayment}
              inputLabel="template.page.content.eyes-only.donation-setup.disableBankAccountPayment"
              onChange={this.onChange}
            />

            <TemplateCheckbox
              path={campaignContract.allowPledgePayment}
              inputLabel="template.page.content.eyes-only.donation-setup.allowPledgePayment"
              onChange={this.onChange}
            />

            <TemplateInput
              path={campaignContract.returnUrl}
              label="template.page.content.eyes-only.donation-setup.return-url"
              validation={this.optionalUrlValidation}
              onChange={this.onChange}
            />

            <TemplateHorizontalRule />

            <TemplateHeader
              as="h3"
              label="template.page.content.eyes-only.newsletter.header"
            />

            <TemplateCheckbox
              path={campaignContract.newsletterHidden}
              inputLabel="template.page.content.eyes-only.newsletter.hidden"
              onChange={this.onChange}
            />

            <TemplateCheckbox
              path={campaignContract.newsletterDefaultValue}
              inputLabel="template.page.content.eyes-only.newsletter.defaultValue"
              onChange={this.onChange}
            />

            <Form.Group widths="equal">
              <TemplateCheckbox
                inputLabel="template.page.content.eyes-only.newsletter.applyForFundraising"
                value={this.getNewsletterPageTypeValue('fundraising')}
                onChange={this.onNewsletterPageTypeChange.bind(
                  this,
                  'fundraising'
                )}
              />

              <TemplateCheckbox
                inputLabel="template.page.content.eyes-only.newsletter.applyForDonation"
                value={this.getNewsletterPageTypeValue('donation')}
                onChange={this.onNewsletterPageTypeChange.bind(
                  this,
                  'donation'
                )}
              />

              <TemplateCheckbox
                inputLabel="template.page.content.eyes-only.newsletter.applyForSale"
                value={this.getNewsletterPageTypeValue('sale')}
                onChange={this.onNewsletterPageTypeChange.bind(this, 'sale')}
              />
            </Form.Group>

            <TemplateTextArea
              path={campaignContract.newsletterCheckboxText}
              label="template.page.content.eyes-only.newsletter.checkboxText"
              onChange={this.onChange}
            />

            <TemplateHorizontalRule />

            <TemplateHeader
              as="h3"
              label="template.page.content.eyes-only.customText.header"
            />

            <TemplateTextArea
              path={campaignContract.donationCustomThankyouText}
              label="template.page.content.eyes-only.customText.donateThankYouText"
              onChange={this.onChange}
            />

            {/* <TemplateHorizontalRule />

            <TemplateHeader
              as="h3"
              label="template.page.content.eyes-only.sale-custom-behaviour.header"
            />

            <TemplateCheckbox
              inputLabel="template.page.content.eyes-only.sale-custom-behaviour.one-ticket-type-per-sale.header"
              path={campaignContract.saleCustomBehaviourOneTicketTypePerSale}
              onChange={this.onChange}
            />

            <TemplateInput
              id="saleCustomBehaviourMaxTicketsToSale"
              path={campaignContract.saleCustomBehaviourMaxTicketsToSale}
              type="number"
              inputLabel="template.page.content.eyes-only.sale-custom-behaviour.max-tickets-to-sale.header"
              onChange={(e: any, v: string) => this.onChange(e, parseInt(v))}
            /> 
          */}

            {/* MVP fundraiser */}

            <TemplateHorizontalRule />
            <TemplateHeader
              as="h3"
              label="template.page.content.eyes-only.mvp.header"
            />
            <TemplateCheckbox
              inputLabel="template.page.content.eyes-only.mvp.checkbox-enable-targets"
              path={"template.value.ticketingSetup.fundraisingTargetAmountEnabled"}
              onChange={(e: any, v: any) => {
                if (v) {
                  const fundraiserTargetAmountOptions = get(this.props.templateBuilder, "template.value.ticketingSetup.fundraiserTargetAmountOptions");
                  if (!Array.isArray(fundraiserTargetAmountOptions)) this.onChange("template.value.ticketingSetup.fundraiserTargetAmountOptions", [500, 1000, 5000]);
                }
                this.onChange(e, v);
              }}
            />
            {get(this.props.templateBuilder, "template.value.ticketingSetup.fundraisingTargetAmountEnabled") ? (
              <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem"
              }}>
                <TemplateHeader
                  as="h5"
                  label="template.page.content.eyes-only.mvp.targets"
                />
                <TemplateList 
                  path='template.value.ticketingSetup.fundraiserTargetAmountOptions'
                  values={get(this.props.templateBuilder, "template.value.ticketingSetup.fundraiserTargetAmountOptions")}
                  onChange={this.onChange}
                >
                  {({ value, index, onChange }) => (
                    <TemplateInput
                      type="number"
                      path={`template.value.ticketingSetup.fundraiserTargetAmountOptions.${index}`}
                      value={value}
                      onChange={(e: any, v: string) => {
                        this.onChange(e, parseInt(v));
                      }}
                    />
                  )}
                </TemplateList>
              </div>
            ) : null}
            <TemplateCheckbox
              inputLabel="template.page.content.eyes-only.mvp.checkbox-enable"
              path={campaignContract.mvpFundraisersEnable}
              onChange={(e: any, v: string) => {
                if (v) {
                  const mvpFundraisersTarget = get(this.props.templateBuilder, campaignContract.mvpFundraisersTarget);
                  if (typeof mvpFundraisersTarget !== 'number') this.onChange(campaignContract.mvpFundraisersTarget, 1000);
  
                  const mvpFundraisersTitle = get(this.props.templateBuilder, campaignContract.mvpFundraisersTitle);
                  if (typeof mvpFundraisersTitle !== 'string') this.onChange(campaignContract.mvpFundraisersTitle, I18n.t(`${campaignContract.mvpFundraisersDefaultPath}.default-title`));
  
                  const mvpFundraisersMessage = get(this.props.templateBuilder, campaignContract.mvpFundraisersMessage);
                  if (typeof mvpFundraisersMessage !== 'string') this.onChange(campaignContract.mvpFundraisersMessage, I18n.t(`${campaignContract.mvpFundraisersDefaultPath}.default-message`));
  
                  const mvpFundraisersButton = get(this.props.templateBuilder, campaignContract.mvpFundraisersButton);
                  if (typeof mvpFundraisersButton !== 'string') this.onChange(campaignContract.mvpFundraisersButton, I18n.t(`${campaignContract.mvpFundraisersDefaultPath}.default-button-text`));
  
                  const mvpFundraisersTitleSuccess = get(this.props.templateBuilder, campaignContract.mvpFundraisersTitleSuccess);
                  if (typeof mvpFundraisersTitleSuccess !== 'string') this.onChange(campaignContract.mvpFundraisersTitleSuccess, I18n.t(`${campaignContract.mvpFundraisersDefaultPath}.default-title-success`));
  
                  const mvpFundraisersMessageSuccess = get(this.props.templateBuilder, campaignContract.mvpFundraisersMessageSuccess);
                  if (typeof mvpFundraisersMessageSuccess !== 'string') this.onChange(campaignContract.mvpFundraisersMessageSuccess, I18n.t(`${campaignContract.mvpFundraisersDefaultPath}.default-message-success`));
                }

                this.onChange(e, v);
              }}
            />
            {this.props.mvpFundraisersSettings.enabled ? (
              <>
                <TemplateInput
                  type="number"
                  label="template.page.content.eyes-only.mvp.input-target"
                  path={campaignContract.mvpFundraisersTarget}
                  onChange={(e: any, v: string) => this.onChange(e, parseInt(v))}
                />
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem"
                }}>
                  <div style={{
                    border: "1px solid lightgrey",
                    borderRadius: "0.5rem",
                    padding: "1rem"
                  }}>
                  <TemplateHeader
                      as="h3"
                      label="template.page.content.eyes-only.mvp.title-cta"
                    />
                    <TemplateText
                      label="template.page.content.eyes-only.mvp.title-cta-description"
                    />
                    <TemplateInput
                      label="template.page.content.eyes-only.mvp.input-title"
                      subtext="template.page.content.eyes-only.mvp.input-title-description"
                      path={campaignContract.mvpFundraisersTitle}
                      onChange={this.onChange}
                      />
                    <TemplateTextArea
                      label="template.page.content.eyes-only.mvp.input-message"
                      subtext="template.page.content.eyes-only.mvp.input-message-description"
                      path={campaignContract.mvpFundraisersMessage}
                      onChange={this.onChange}
                      />
                    <TemplateEditableUpload
                      id="setupMvpFundraiserImage"
                      label="template.page.content.eyes-only.mvp.input-image"
                      subtext="template.page.content.eyes-only.mvp.input-image-description"
                      path={campaignContract.mvpFundraisersImage}
                      entity={entityTypes.CAMPAIGN}
                      position="left"
                      height="450"
                      width="350"
                      format={this.photoPhormats}
                      uploadType={imageTypes.LOGO}
                      onChange={this.onChange}
                    />
                    <TemplateInput
                      label="template.page.content.eyes-only.mvp.input-button-text"
                      subtext="template.page.content.eyes-only.mvp.input-button-text-description"
                      path={campaignContract.mvpFundraisersButton}
                      onChange={this.onChange}
                    />
                  </div>
                  <div style={{
                    border: "1px solid lightgrey",
                    borderRadius: "0.5rem",
                    padding: "1rem"
                  }}>
                    <TemplateHeader
                      as="h3"
                      label="template.page.content.eyes-only.mvp.title-success"
                    />
                    <TemplateText
                      label="template.page.content.eyes-only.mvp.title-success-description"
                    />
                    <TemplateInput
                      label="template.page.content.eyes-only.mvp.input-title-success"
                      subtext="template.page.content.eyes-only.mvp.input-title-success-description"
                      path={campaignContract.mvpFundraisersTitleSuccess}
                      onChange={this.onChange}
                      />
                    <TemplateTextArea
                      label="template.page.content.eyes-only.mvp.input-message-success"
                      subtext="template.page.content.eyes-only.mvp.input-message-success-description"
                      path={campaignContract.mvpFundraisersMessageSuccess}
                      onChange={this.onChange}
                      />
                    <TemplateEditableUpload
                      id="setupMvpFundraiserImage"
                      label="template.page.content.eyes-only.mvp.input-image-success"
                      subtext="template.page.content.eyes-only.mvp.input-image-success-description"
                      path={campaignContract.mvpFundraisersImageSuccess}
                      entity={entityTypes.CAMPAIGN}
                      position="left"
                      height="450"
                      width="350"
                      format={this.photoPhormats}
                      uploadType={imageTypes.LOGO}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </>
            ) : null}

            {/* Invite friends */}

            <TemplateHorizontalRule />
            <TemplateHeader
              as="h3"
              label="template.page.content.eyes-only.invite-friends.header"
            />
            <TemplateText
              label="template.page.content.eyes-only.invite-friends.description"
            />
            <TemplateCheckbox
              inputLabel="template.page.content.eyes-only.invite-friends.checkbox-enable"
              path={campaignContract.inviteFriendsEnable}
              onChange={(e: any, v: string) => {
                if (v) {
                  const inviteFriendsCustomText = get(this.props.templateBuilder, campaignContract.inviteFriendsCustomText);
                  if (typeof inviteFriendsCustomText !== 'string') this.onChange(campaignContract.inviteFriendsCustomText, I18n.t(`${campaignContract.inviteFriendsDefaultPath}.default-title`));
  
                  const inviteFriendsCustomDescription = get(this.props.templateBuilder, campaignContract.inviteFriendsCustomDescription);
                  if (typeof inviteFriendsCustomDescription !== 'string') this.onChange(campaignContract.inviteFriendsCustomDescription, I18n.t(`${campaignContract.inviteFriendsDefaultPath}.default-description`));
  
                  const inviteFriendsCustomMessage = get(this.props.templateBuilder, campaignContract.inviteFriendsCustomMessage);
                  if (typeof inviteFriendsCustomMessage !== 'string') this.onChange(campaignContract.inviteFriendsCustomMessage, I18n.t(`${campaignContract.inviteFriendsDefaultPath}.default-message`));
                }
                this.onChange(e, v);
              }}
            />
            {get(this.props.templateBuilder, campaignContract.inviteFriendsEnable) ? (
              <>
                <TemplateInput
                  label="template.page.content.eyes-only.invite-friends.input-custom-text"
                  subtext="template.page.content.eyes-only.invite-friends.input-custom-text-subtext"
                  path={campaignContract.inviteFriendsCustomText}
                  onChange={this.onChange}
                  />
                <TemplateTextArea
                  label="template.page.content.eyes-only.invite-friends.input-custom-description"
                  subtext="template.page.content.eyes-only.invite-friends.input-custom-description-subtext"
                  path={campaignContract.inviteFriendsCustomDescription}
                  onChange={this.onChange}
                />
                <TemplateTextArea
                  label="template.page.content.eyes-only.invite-friends.input-custom-message"
                  subtext="template.page.content.eyes-only.invite-friends.input-custom-message-subtext"
                  path={campaignContract.inviteFriendsCustomMessage}
                  onChange={this.onChange}
                />
                <TemplateEditableUpload
                  id="setupInviteFriendsImage"
                  label="template.page.content.eyes-only.invite-friends.input-custom-image"
                  subtext="template.page.content.eyes-only.invite-friends.input-custom-image-subtext"
                  path={campaignContract.inviteFriendsCustomImage}
                  entity={entityTypes.CAMPAIGN}
                  position="left"
                  height="450"
                  width="350"
                  format={this.photoPhormats}
                  uploadType={imageTypes.LOGO}
                  onChange={this.onChange}
                />
              </>
            ) : null}
          </TemplatePage>
        )}
        {this.props.isSystemAdmin && (
          <TemplatePage
            id="custom-activities"
            i18nKey="template.page.custom-activities"
            path="customActivitiesTab"
          >
            <TemplateHeader
              as="h3"
              label="template.page.content.custom-activities.header"
            />

            <TemplateCheckbox
              path={campaignContract.customActivitiesEnable}
              inputLabel="template.page.content.custom-activities.enable"
              onChange={this.onEnableCustomActivitiesChange}
            />

            <TemplateInput
              path={campaignContract.customActivitiesValue1Name}
              label="template.page.content.custom-activities.value1.name"
              validation={this.customActivitiesLabelMaxLength}
              onChange={this.onChange}
            />

            <TemplateInput
              path={campaignContract.customActivitiesValue1Unit}
              label="template.page.content.custom-activities.unit"
              // validation={this.customActivitiesLabelMaxLength}
              onChange={this.onChange}
            />

            <TemplateCheckbox
              path={campaignContract.customActivitiesValue1ThermometerEnabled}
              inputLabel="template.page.content.custom-activities.enableThermometer"
              onChange={this.onEnableCustomActivitiesThermometerChange}
            />

            <TemplateCurrency
              id="setupCampaignTarget"
              className="padding-left-style"
              path={campaignContract.customActivitiesValue1CampaignTargetField}
              inputLabel={this.props.customActivitiesValue1Unit || ' '}
              label="template.page.content.custom-activities.value1.campaignTarget"
              onChange={this.onChange}
            />

            <TemplateCurrency
              id="setupFundraiserDefaultTarget"
              className="padding-left-style"
              path={
                campaignContract.customActivitiesValue1FundraiserDefaultTargetField
              }
              inputLabel={this.props.customActivitiesValue1Unit || ' '}
              label="template.page.content.custom-activities.value1.fundraiserDefaultTarget"
              onChange={this.onChange}
            />

            <TemplateCurrency
              id="setupTeamDefaultTarget"
              className="padding-left-style"
              path={
                campaignContract.customActivitiesValue1TeamDefaultTargetField
              }
              inputLabel={this.props.customActivitiesValue1Unit || ' '}
              label="template.page.content.custom-activities.value1.teamDefaultTarget"
              onChange={this.onChange}
            />

            <TemplateEditableUpload
              id="setupThermometerSvg"
              path={campaignContract.customActivitiesValue1Svg}
              entity={entityTypes.CAMPAIGN}
              position="left"
              height="150"
              width="150"
              format={this.iconFormats}
              uploadType={imageTypes.LOGO}
              disableCropping
              label="template.page.content.custom-activities.svg"
              onChange={this.onChange}
            />

            <TemplateCheckbox
              path={campaignContract.customActivitiesValue1LeaderBoardEnabled}
              inputLabel="template.page.content.custom-activities.enableLeaderboard"
              onChange={this.onChange}
            />
          </TemplatePage>
        )}
      </TemplateBuilderContainerEx>
    );
  }
}

const mapState = (state: { templateBuilderEx: { data: any } }) => {
  return {
    templateBuilder: state.templateBuilderEx.data,
    campaignName: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.name
    ),
    campaignUrlFull: templateHelper.getValue(
      state.templateBuilderEx,
      'urlFull'
    ),
    organizationLogo: templateHelper.getValue(
      state.templateBuilderEx,
      'organization.mainImagePath'
    ),
    fitnessThermometersToShow: templateHelper.getValue(
      state.templateBuilderEx,
      FitnessContract.fitnessThermometersToShow
    ),
    ticketIssuingStyle: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.ticketIssuingStyle
    ),
    simpleTicketingEnabled: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.simpleTicketingEnabled
    ),
    purchaseFundraisingEnabled: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.purchaseFundraisingEnabled
    ),
    fundraisersEnabled: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.fundraisersEnable
    ),
    enableSaleDonations: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.enableSaleDonations
    ),
    allowedSaleDonationTypes: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.allowedSaleDonationTypes
    ),
    generalSettings: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.generalSettings
    ),
    allowTicketing: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.allowTicketing
    ),
    fitnessTargets: templateHelper.getValue(
      state.templateBuilderEx,
      FitnessContract.fitnessTargets
    ),
    type: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.type
    ),
    fitnessEnabled: templateHelper.getValue(
      state.templateBuilderEx,
      FitnessContract.fitnessEnabled
    ),
    fitnessAllowedTypes: templateHelper.getValue(
      state.templateBuilderEx,
      FitnessContract.fitnessAllowedTypes
    ),
    fitnessAllowedTrackedStatistics: templateHelper.getValue(
      state.templateBuilderEx,
      FitnessContract.fitnessAllowedTrackedStatistics
    ),
    customExtraFields:
      templateHelper.getValue(
        state.templateBuilderEx,
        'template.value.customExtraFields'
      ) || [],
    newsletterPageTypes:
      templateHelper.getValue(
        state.templateBuilderEx,
        campaignContract.newsletterPageTypes
      ) || [],
    customActivitiesValue1Unit: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.customActivitiesValue1Unit
    ),
    mvpFundraisersSettings: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.mvpFundraisersSettings
    ) || {},
    orgMulticurrencySettings: templateHelper.getValue(
      state.templateBuilderEx,
      "organization.additionalCurrencies"
    ),
    isSystemAdmin: get(state, 'session.isSystemAdmin')
  };
};

const mapDispatch = (dispatch: any) => {
  // @ts-ignore
  return bindActionCreators(templateBuilderActions, dispatch);
};

const CampaignTemplate = withRouter(
  // @ts-ignore
  connect(mapState, mapDispatch)(CampaignTemplateView)
);
export default CampaignTemplate;
