// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { get } from 'lodash';

// Actions
import * as programActions from '../store/vipProgramActions';
import * as accountSettingsActions from '../../accounts/settings/reducer';
import { organizationActions } from '../../organization/store/organizationActions';

import { Record } from '../../../modules/bin/utility';

// Components
import ListsViewContainer from './listview';
import clear from '../../../components/clear';
import BasePage from '../../../components/common/basePageView';
import ContextMenu from '../../../components/common/grid/contextMenu';
import Panel from '../../../components/common/grid/managed/panel';
import Button from '../../../components/common/button';

// Constants
import { gridTabs, i18nKeys } from '../constants';
import { exportFileNames } from '../../../constants/exportFiles';
import { CONTEXT_MENU_KEYS } from '../../../constants/contextMenuEntries';

// ----------------------------------------------------------------------

/** A page to view all campaigns in a list */
class ProrgamrView extends Component {
  constructor(props) {
    super(props);

    const accountId = this.props.accountId;

    if (!this.props.isCampaignAdmin && !this.props.isOrganizationAdmin) {
      props.accountSettingsActions.getRecord(accountId);

      props.programActions.getTotalCountPrograms(
        'EventAmbassadorProgram/aggregate'
      );

      props.programActions.getTotalCountApplications(
        'eventAmbassador/aggregate'
      );
      props.programActions.getTotalCountPendingApprovalProgramsByReceivedStatus(
        'eventAmbassador/aggregate'
      );
      props.programActions.getTotalCountVIPProgramsByClaimedStatus(
        'eventAmbassador/aggregate'
      );
      props.programActions.getTotalCountVIPProgramsByAcceptedStatus(
        'eventAmbassador/aggregate'
      );
    }

    if (this.props.isOrganizationAdmin) {
      const organizationId = this.props.organizationId;

      if (Record.notStarted(this.props.organizationRecord)) {
        props.organizationActions.getOrganizationRecord(organizationId);
      }
    }

    if (this.props.isCampaignAdmin || this.props.isOrganizationAdmin) {
      props.programActions.getTotalCountPrograms(
        'CampaignAmbassadorProgram/aggregate'
      );

      props.programActions.getTotalCountApplications(
        'eventAmbassador/aggregate'
      );
      props.programActions.getTotalCountPendingApprovalProgramsByReceivedStatus(
        'eventAmbassador/aggregate'
      );
      props.programActions.getTotalCountVIPProgramsByClaimedStatus(
        'eventAmbassador/aggregate'
      );
      props.programActions.getTotalCountVIPProgramsByAcceptedStatus(
        'eventAmbassador/aggregate'
      );
    }
  }

  componentDidUpdate() {
    if (
      Record.isSuccess(this.props.accountRecord) &&
      Record.notStarted(this.props.eventOrganizerRecord)
    ) {
      const eventOrganizerId = get(
        this.props.accountRecord,
        'data.data.roleDetail.eventOrganizerId[0]'
      );

      if (this.props.isSystemAdmin) {
        this.props.programActions.getEventOrganizerRecord(1);
      }
      if (this.props.isEventOrganizerAdmin) {
        this.props.programActions.getEventOrganizerRecord(eventOrganizerId);
      }
    }
  }

  onCreateClick = () => {
    this.props.history.push('/vip-program/create/setup');
  };

  getPanel = () => {
    return (
      <Panel name="right">
        <Button id="btnCreateVipProgram" primary onClick={this.onCreateClick}>
          <Translate value={i18nKeys.ADD_VIP_PROGRAM_BUTTON} />
        </Button>
      </Panel>
    );
  };

  getProgramsContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.EDIT_EVENT_PROGRAM.key}
          label={I18n.t('programs.list.context.edit-event')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.EDIT_EVENT_PROGRAM}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.VIEW_EVENT_PROGRAMS.key}
          label={I18n.t('programs.list.context.view-organizations')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.VIEW_EVENT_PROGRAMS}
        />
      </ContextMenu>
    );
  };

  getCampaignProgramsContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.VIEW_CAMPAIGN_PROGRAMS.key}
          label={I18n.t('campaignPrograms.list.context.view-applications')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.VIEW_CAMPAIGN_PROGRAMS}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.EDIT_CAMPAIGN_PROGRAM.key}
          label={I18n.t('campaignPrograms.list.context.edit-program')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.EDIT_CAMPAIGN_PROGRAM}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.VIEW_APPLICATION_FORM.key}
          label={I18n.t('campaignPrograms.list.context.view-application-form')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.VIEW_APPLICATION_FORM}
        />
      </ContextMenu>
    );
  };

  getApplicationsContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION.key}
          label={I18n.t('applications.list.context.edit-application')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER.key}
          label={I18n.t('applications.list.context.view-fundraiser')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER}
          isHiden={(item) => !Boolean(item.fundraiser)}
        />
      </ContextMenu>
    );
  };

  getPendingApprovalProgramsContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION.key}
          label={I18n.t(
            'pendingApprovalPrograms.list.context.edit-application'
          )}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER.key}
          label={I18n.t('pendingApprovalPrograms.list.context.view-fundraiser')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER}
          isHiden={(item) => !Boolean(item.fundraiser)}
        />
      </ContextMenu>
    );
  };

  applyAggregates = () => {
    if (this.props.isCampaignAdmin || this.props.isOrganizationAdmin) {
      this.props.programActions.getTotalCountPrograms(
        'CampaignAmbassadorProgram/aggregate'
      );
    }

    if (!this.props.isCampaignAdmin && !this.props.isOrganizationAdmin) {
      this.props.programActions.getTotalCountPrograms(
        'EventAmbassadorProgram/aggregate'
      );
    }

    this.props.programActions.getTotalCountApplications(
      'eventAmbassador/aggregate'
    );
    this.props.programActions.getTotalCountPendingApprovalProgramsByReceivedStatus(
      'eventAmbassador/aggregate'
    );
    this.props.programActions.getTotalCountVIPProgramsByClaimedStatus(
      'eventAmbassador/aggregate'
    );
    this.props.programActions.getTotalCountVIPProgramsByAcceptedStatus(
      'eventAmbassador/aggregate'
    );
  };

  getVipProgramsContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION.key}
          label={I18n.t('vips.list.context.edit-application')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER.key}
          label={I18n.t('vips.list.context.view-fundraiser')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER}
          isHiden={(item) => !Boolean(item.fundraiser)}
        />
      </ContextMenu>
    );
  };

  /**
   * Renders the component
   */
  render() {
    const vipProgramsFilters = [
      { key: 'status', operator: 'InList', value: ['Accepted', 'Claimed'] }
    ];
    const pendingApprovalProgramsFilters = [
      { key: 'status', operator: 'EqualTo', value: 'Received' }
    ];

    const eventOrganizerMetrics = get(
      this.props.eventOrganizerRecord,
      'data.data.metrics'
    );
    const organizationMetrics = get(
      this.props.organizationRecord,
      'data.data.ambassadorProgramMetrics'
    );

    const isSystemAdmin = this.props.isSystemAdmin;
    const isOrganizationAdmin = this.props.isOrganizationAdmin;
    const isCampaignAdmin = this.props.isCampaignAdmin;
    const isEventOrganizerAdmin = this.props.isEventOrganizerAdmin;

    const panel = this.getPanel();

    return (
      <BasePage>
        <div className="grid-top-block">
          <h1>
            <Translate value="programs.title" />
          </h1>
          {!isCampaignAdmin && !isOrganizationAdmin && panel}
        </div>
        <ListsViewContainer
          filterPath="vip-program"
          metrics={
            organizationMetrics ? organizationMetrics : eventOrganizerMetrics
          }
          isSystemAdmin={isSystemAdmin}
          isOrganizationAdmin={isOrganizationAdmin}
          isCampaignAdmin={isCampaignAdmin}
          isEventOrganizerAdmin={isEventOrganizerAdmin}
          programsGridKey={gridTabs.PROGRAMS}
          programsExportName={exportFileNames.PROGRAMS}
          programsExportEndpoint={'EventAmbassadorProgram'}
          programsContextMenu={this.getProgramsContextMenu()}
          campaignProgramsGridKey={gridTabs.CAMPAIGN_PROGRAMS}
          campaignProgramsExportName={exportFileNames.CAMPAIGN_PROGRAMS}
          campaignProgramsExportEndpoint={'CampaignAmbassadorProgram'}
          campaignProgramsContextMenu={this.getCampaignProgramsContextMenu()}
          applyAggregates={this.applyAggregates}
          applicationsGridKey={gridTabs.APPLICATIONS}
          applicationsExportName={exportFileNames.APPLICATIONS}
          applicationsExportEndpoint={'EventAmbassador'}
          applicationsContextMenu={this.getApplicationsContextMenu()}
          pendingApprovalProgramsGridKey={gridTabs.PENDING_APPROVAL_PROGRAMS}
          pendingApprovalProgramsExportName={
            exportFileNames.PENDING_APPROVAL_PROGRAMS
          }
          pendingApprovalProgramsExportEndpoint={'EventAmbassador'}
          pendingApprovalProgramsContextMenu={this.getPendingApprovalProgramsContextMenu()}
          pendingApprovalProgramsFilters={pendingApprovalProgramsFilters}
          vipProgramsGridKey={gridTabs.VIPPROGRAMS}
          vipProgramsExportName={exportFileNames.VIPPROGRAMS}
          vipProgramsExportEndpoint={'EventAmbassador'}
          vipProgramsContextMenu={this.getVipProgramsContextMenu()}
          vipProgramsFilters={vipProgramsFilters}
        />
      </BasePage>
    );
  }
}

const mapState = ({ organization, program, session, accountSettings }) => {
  return {
    accountRecord: accountSettings.record,
    organizationRecord: organization.record,
    eventOrganizerRecord: program.eventOrganizerRecord,

    isCampaignAdmin: session.isCampaignAdmin,
    isOrganizationAdmin: session.isOrganizationAdmin,
    isEventAdmin: session.isEventAdmin,
    isEventOrganizerAdmin: session.isEventOrganizerAdmin,
    isSystemAdmin: session.isSystemAdmin,
    accountId: get(session, 'account.id'),
    organizationId: get(session, 'organization.id')
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  return {
    programActions: bindActionCreators(programActions, dispatch),
    accountSettingsActions: bindActionCreators(
      accountSettingsActions,
      dispatch
    ),
    organizationActions: bindActionCreators(organizationActions, dispatch)
  };
};

/** Connects component to Redux store */
const ProrgamViewPageContainer = clear(
  withRouter(connect(mapState, mapDispatch)(ProrgamrView))
);
export default ProrgamViewPageContainer;
